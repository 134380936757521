import React from "react";

export default class FrankBody extends React.Component {
    render() {
        return (
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 1928 490"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 2,
                }}
            >
                <g transform="matrix(1,0,0,1,-2096.1,-9972)">
                    <g>
                        <g>
                            <path
                                d="M2120.2,10460.8C2112.7,10452.9 2105.3,10444.6 2096.6,10435.6L2096.6,9996.5C2104.5,9988.8 2112.5,9981.2 2121.6,9972.5L3998.8,9972.5C4006.3,9980.6 4014.1,9988.9 4023,9998.1L4023,10436.2C4014.9,10444.3 4006.8,10452.4 3998.2,10460.8L2120.2,10460.8ZM2126.4,10444.2L3107.2,10444.2C3113.1,10439.6 3117.3,10436.1 3120.7,10433.5L3120.7,10000C3116.9,9996 3113.2,9992.1 3110,9988.7L2127,9988.7C2121.8,9993.5 2116.9,9998 2112.7,10001.8L2112.7,10430.8C2117.1,10435.2 2121.8,10439.6 2126.4,10444.2ZM3992.7,9988.8L3152.4,9988.8C3146.1,9993.4 3141.5,9997.1 3137.9,9999.9L3137.9,10432.6C3141.7,10436.4 3145.8,10440.5 3149.6,10444.5L3991.9,10444.5C3996.7,10439.9 4001.4,10435.2 4006.2,10430.8L4006.2,10003.4C4001.6,9998.1 3996.9,9993.2 3992.7,9988.8Z"
                                style={{ fill: "white", stroke: "white" }}
                            />
                            <path
                                d="M2912.2,10251.4C2904.3,10257.3 2897.5,10262.1 2890,10267.6C2890.4,10274.5 2891,10281.1 2891.4,10288.4C2899.9,10289 2906.9,10289.4 2914.4,10290L2914.4,10306.2L2846.6,10306.2L2846.6,10290.7C2854.9,10289.7 2862.8,10288.7 2871.2,10287.7L2871.2,10147.4C2865.3,10137.9 2855,10145.4 2846.2,10140.5C2846,10136.9 2845.8,10132 2845.4,10126.2L2889.6,10126.2L2889.6,10246.1C2890.4,10246.5 2891.2,10246.7 2892,10247.1C2909.8,10233.6 2927.7,10220.2 2947.7,10205.1C2939.6,10204.1 2934.2,10203.5 2927.7,10202.7L2927.7,10187L2991.7,10187L2991.7,10202.3C2963.6,10204.1 2949.3,10227.9 2926.1,10239.6C2940.8,10256.8 2954.8,10272.5 2969.1,10289.1L2991.7,10289.1L2991.7,10305.7L2947.9,10305.7C2951.5,10292.6 2942.4,10285.9 2935.8,10278.2C2928.3,10269.6 2920.6,10260.9 2912.2,10251.4Z"
                                style={{ fill: "white", stroke: "white" }}
                            />
                            <path
                                d="M2296,10186.4L2345.1,10186.4L2345.1,10202.6C2328.9,10206.8 2312,10200.6 2295.8,10206.6L2295.8,10287.2C2312,10292.7 2328.9,10287 2345.5,10290.6L2345.5,10306.1L2241.3,10306.1L2241.3,10290.2C2252.8,10289.6 2264.1,10289 2275.8,10288.4L2275.8,10207.6C2265.7,10199.5 2252.4,10207.4 2241.1,10202.1L2241.1,10186.8L2275.8,10186.8C2276.4,10181.3 2276.8,10177.5 2277,10173.7C2280,10133.1 2309.1,10118.6 2342.8,10120.6C2354.7,10121.2 2366,10124.6 2375.3,10132.9C2383.4,10140 2387.4,10148.8 2386.2,10159.8C2385,10171.1 2375.5,10177.4 2364.4,10174.5C2353.7,10171.7 2350.1,10159.8 2357.5,10151.5C2359.1,10149.9 2360.9,10148.5 2362.7,10147.1C2354.2,10136.2 2334.6,10133.4 2317.1,10140.4C2300.8,10146.7 2293.4,10161.8 2296,10186.4Z"
                                style={{ fill: "white", stroke: "white" }}
                            />
                            <path
                                d="M2574,10203.4C2578,10216.9 2576,10223.8 2567.7,10226.6C2561.2,10228.8 2555,10228.4 2550.1,10223.2C2544.9,10217.1 2544.2,10209.7 2548.5,10203.6C2552.1,10198.4 2557.6,10193.3 2563.2,10190.5C2579.8,10182.6 2597.3,10180.6 2615.3,10184.4C2634.3,10188.4 2645.8,10201.6 2646.8,10221.1C2647.8,10239.3 2647.4,10257.4 2647.6,10275.6C2647.6,10279.6 2648,10283.5 2648.4,10288.3C2655.3,10288.9 2661.3,10289.5 2668.2,10290.3L2668.2,10306.2L2630.2,10306.2C2629.8,10301 2629.4,10296.5 2629,10290.9C2624.8,10294.3 2621.3,10297.6 2617.7,10300.2C2599.9,10312.1 2580.6,10313.1 2560.8,10307.1C2548.5,10303.3 2541.8,10293.8 2540.6,10280.7C2539.4,10267.8 2544.2,10257.1 2555.5,10251C2564.4,10246.4 2574.1,10243.1 2583.6,10240.5C2598.1,10236.5 2613.1,10233.6 2627.4,10230.2C2630.8,10214.7 2623.8,10203.1 2609.2,10200.3C2597.4,10198.1 2585.9,10199.2 2574,10203.4ZM2628.1,10269L2628.1,10246.2C2609.5,10250.8 2592,10255.1 2574.6,10259.9C2571.2,10260.9 2567.7,10262.9 2565.1,10265.4C2556.6,10272.9 2558.2,10285.6 2568.5,10290.4C2572.3,10292.2 2577,10293 2581.2,10293C2600.9,10293.4 2615,10282.5 2628.1,10269Z"
                                style={{ fill: "white", stroke: "white" }}
                            />
                            <path
                                d="M2705.4,10288.8C2706.4,10285.2 2708,10281.3 2708.2,10277.3C2708.4,10256.7 2708.4,10236.3 2708.4,10215.7C2708.4,10206 2706.6,10204.2 2696.3,10204L2684.4,10204L2684.4,10187L2725,10187C2725.6,10191.8 2726,10196.1 2726.6,10201.3C2730,10198.5 2732.5,10196.3 2735.1,10194.4C2745.6,10186.3 2757.3,10181.9 2770.6,10182.9C2796,10184.7 2810.8,10200.7 2811.6,10227.5C2812.2,10243.7 2811.6,10259.8 2811.8,10276C2811.8,10279.6 2812.2,10283.3 2812.6,10288.3C2820.3,10289.1 2827.5,10289.7 2835.2,10290.5L2835.2,10306.4L2769.4,10306.4L2769.4,10290.9C2777.3,10289.9 2784.7,10289.1 2791.8,10288.3C2792.4,10284.9 2793.2,10282.8 2793.2,10281C2793.2,10263.2 2793.6,10245.3 2793,10227.5C2792.2,10208.5 2781.3,10200.2 2762.5,10200.9C2746.3,10201.5 2722.5,10221.7 2725.4,10240.3C2727.2,10252.4 2725.6,10265.1 2725.8,10277.6C2726,10281.6 2727.6,10285.5 2728.4,10289.1C2736.5,10289.5 2742.7,10289.9 2749.6,10290.3L2749.6,10306.7L2684.2,10306.7L2684.2,10290.3C2690.9,10289.6 2697.2,10289.2 2705.4,10288.8Z"
                                style={{ fill: "white", stroke: "white" }}
                            />
                            <path
                                d="M2500.7,10200.6C2472.6,10199 2456.9,10217 2444.2,10235.3C2433.7,10250.6 2441,10270 2441,10289.2L2495.9,10289.2C2496.1,10295.5 2496.5,10300.3 2496.7,10306.2L2385.9,10306.2L2385.9,10291.1C2397.6,10286.3 2410.7,10293.7 2421.4,10285.6L2421.4,10208.1C2410.9,10199.4 2397.8,10207.5 2385.9,10202.2L2385.9,10187.1L2439.2,10187.1C2439.6,10195.6 2440,10203.7 2440.6,10212.9C2448.1,10206.2 2454.3,10200.2 2461,10194.9C2473.7,10185.2 2487.9,10179.6 2504.4,10184C2517.3,10187.4 2525.6,10197.3 2526.2,10209.8C2526.6,10217.1 2524.4,10223.5 2517.1,10226.4C2510.8,10228.8 2504.4,10228.4 2499.3,10223.2C2493.8,10217.3 2494.5,10211.1 2498.7,10204.8C2499.3,10204.2 2499.5,10203.2 2500.7,10200.6Z"
                                style={{ fill: "white", stroke: "white" }}
                            />
                            <path
                                d="M3696.7,10124.9L3696.7,10287.6C3704,10288.4 3710.2,10289 3717.5,10289.6C3717.7,10294.4 3717.9,10299.3 3718.3,10305.1L3679.5,10305.1C3678.9,10300.9 3678.3,10296.4 3677.7,10291.8C3640.8,10316.8 3610.3,10315.6 3588.1,10288.8C3567.5,10263.8 3569.1,10222.8 3591.5,10199.8C3614.3,10176.2 3646.6,10176.4 3677.5,10200.8L3677.5,10142L3643.8,10142L3643.8,10124.8C3661,10124.9 3678.1,10124.9 3696.7,10124.9ZM3678.3,10246.8C3678.3,10217.9 3661.5,10200 3635.1,10200C3609.9,10200 3592.7,10218.8 3592.9,10246.4C3593.1,10273.3 3610.3,10292.2 3634.7,10292.8C3655.3,10293.2 3682.6,10274.3 3678.3,10246.8Z"
                                style={{ fill: "white", stroke: "white" }}
                            />
                            <path
                                d="M3266.9,10305.1L3266.9,10289.6C3273.6,10289 3279.6,10288.6 3286.9,10288L3286.9,10142.8C3279.8,10142.2 3273,10141.6 3264.9,10140.8C3264.7,10135.8 3264.5,10130.9 3264.1,10125.1L3305.1,10125.1L3305.1,10201.8C3307.9,10200.2 3309.1,10199.8 3309.7,10199C3336.8,10169.1 3396.5,10176.8 3408.8,10229.5C3413.8,10251.1 3410.4,10271.7 3395.7,10289.5C3375.1,10314.1 3338,10317.4 3313.5,10296.6C3311.5,10294.8 3309.5,10293 3306.2,10290.1C3305.6,10295.6 3305.2,10300 3304.8,10305.4C3292.5,10305.1 3280.2,10305.1 3266.9,10305.1ZM3305.5,10245C3301.5,10273.5 3328.5,10292.8 3349.9,10292.2C3374.3,10291.6 3391.3,10272.6 3391.3,10246C3391.3,10218.5 3374.3,10199.8 3349.3,10199.4C3325.6,10198.9 3301.4,10221.2 3305.5,10245Z"
                                style={{ fill: "white", stroke: "white" }}
                            />
                            <path
                                d="M3864.1,10203.6C3845.9,10238.7 3828.2,10273.9 3809.8,10308.6C3802.5,10322.1 3794.5,10335.5 3785.6,10348C3776.9,10359.9 3764.2,10363.7 3749.7,10360.1C3737,10356.9 3728.1,10346.4 3727.5,10334.5C3727.1,10325.2 3732.3,10317.3 3740.4,10315.1C3747.5,10313.1 3755.5,10315.9 3759,10322C3762.8,10328.7 3762,10334.9 3756,10340.2C3755,10341 3753.6,10341.2 3751,10342.4C3762.9,10346 3768.6,10344.8 3774.4,10336.3C3781.5,10326.2 3787.3,10315.1 3794,10304.2C3776.4,10270.1 3759.1,10236.8 3741.9,10203.7C3736.4,10203.3 3731.8,10202.9 3726.2,10202.5L3726.2,10186.3L3788,10186.3L3788,10202.5C3780.3,10202.9 3773.3,10203.3 3763.4,10203.9C3776.9,10231.2 3789.8,10256.8 3803.8,10284.7C3817.7,10256.8 3830.7,10231.4 3844.4,10203.9C3835.3,10203.3 3828,10202.9 3820.2,10202.3L3820.2,10186.6L3880.4,10186.6C3880.6,10191.2 3880.8,10196.1 3881.2,10201.9C3874.8,10202.4 3869.9,10203 3864.1,10203.6Z"
                                style={{ fill: "white", stroke: "white" }}
                            />
                            <path
                                d="M3428.8,10245.8C3428.8,10209.1 3456.3,10181.8 3493.4,10181.8C3531,10181.8 3559.2,10209.7 3559,10246.4C3558.8,10282.5 3530.3,10310.2 3493.4,10310C3456.3,10309.8 3428.8,10282.5 3428.8,10245.8ZM3448,10245.4C3447.8,10271.8 3466.8,10291.8 3493,10292.4C3518.6,10293 3538.8,10273 3539.4,10246.8C3539.8,10219.7 3521,10200 3494,10199.4C3468.4,10198.9 3448.2,10218.9 3448,10245.4Z"
                                style={{ fill: "white", stroke: "white" }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
