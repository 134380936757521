import React from "react";

export default class FrankBody extends React.Component {
    render() {
        return (
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 1082 651"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 2,
                }}
            >
                <g transform="matrix(1,0,0,1,-6695.9,-9887.8)">
                    <g>
                        <g>
                            <path
                                d="M7777.4,10140.1C7777.2,10140.6 7776.7,10141.2 7776.7,10141.7C7774.9,10155.9 7761.8,10167.8 7745.9,10167.4C7732.9,10167.1 7720.9,10157.4 7717.5,10144.8C7713.3,10128.9 7722.9,10110.8 7741.1,10107.4C7759.8,10103.9 7774.9,10116.9 7776.8,10132.8C7776.9,10133.4 7777.3,10133.9 7777.5,10134.4C7777.4,10136.4 7777.4,10138.3 7777.4,10140.1Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7777.4,9920.7C7777.1,9921.6 7776.8,9922.4 7776.7,9923.3C7774.9,9936.5 7762.3,9947.5 7749.1,9948.2C7732.7,9949.1 7720,9938 7717.1,9923.4C7714.5,9909.8 7722.4,9894.7 7736.6,9889.6C7738,9889.1 7739.4,9888.5 7740.8,9887.9L7752.1,9887.9C7752.5,9888.1 7752.9,9888.5 7753.3,9888.6C7766.6,9892 7774.5,9900.4 7776.8,9914C7776.9,9914.4 7777.2,9914.8 7777.5,9915.2C7777.4,9916.9 7777.4,9918.8 7777.4,9920.7Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M6874.2,10538.8C6869.7,10537.2 6865.1,10535.9 6861.1,10533C6853.1,10527.2 6849.1,10519.4 6848.6,10509.6C6848.3,10502.8 6850,10496.7 6853.9,10491.1C6858.6,10484.3 6865,10480.2 6873,10478.7C6892.7,10475 6910.1,10490.6 6909,10510.2C6908.3,10523.2 6899.2,10535 6885.2,10538C6884.3,10538.2 6883.5,10538.5 6882.6,10538.8L6874.2,10538.8Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7777.4,10213.3C7775.8,10217.5 7774.8,10221.9 7772.6,10225.8C7764.1,10240.8 7743.6,10245.8 7728.4,10234.3C7721.1,10228.7 7716.9,10221.1 7716.5,10211.9C7715.7,10195.6 7728.1,10180.8 7744.8,10180.2C7752.4,10179.9 7759.3,10181.5 7765.4,10186.3C7771.8,10191.4 7775.8,10197.8 7776.8,10206C7776.9,10206.6 7777.2,10207.1 7777.5,10207.7C7777.4,10209.5 7777.4,10211.4 7777.4,10213.3Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7777.4,9993.8C7777.1,9994.7 7776.8,9995.5 7776.6,9996.4C7773.7,10011.9 7760.6,10020.8 7747.4,10021.3C7732.7,10021.8 7719.2,10010.2 7716.9,9995.7C7714.1,9977.5 7728.3,9961.4 7745.9,9960.7C7760.5,9960.2 7774.7,9971.4 7776.7,9986.5C7776.8,9987.1 7777.2,9987.6 7777.4,9988.1L7777.4,9993.8Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7116.2,9887.8C7120.1,9889.6 7124.5,9890.8 7128,9893.2C7146.6,9905.8 7145.3,9934.6 7124.2,9945C7113.3,9950.3 7102.6,9949.4 7092.8,9942.2C7083.4,9935.4 7079.4,9925.7 7080.8,9914.4C7082.3,9901.9 7089.8,9892.7 7103,9888.7C7103.7,9888.5 7104.4,9888.1 7105.1,9887.8L7116.2,9887.8Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7777.4,10067C7776.8,10069.1 7776.3,10071.1 7775.7,10073.2C7769.2,10093.4 7745,10101.1 7728.3,10088C7718,10079.9 7714.4,10069 7717.8,10056.3C7721,10044.2 7729.3,10036.5 7741.5,10034.4C7759,10031.4 7774,10042.7 7776.8,10059.3C7776.9,10060 7777.3,10060.7 7777.5,10061.4C7777.4,10063.2 7777.4,10065.1 7777.4,10067Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M6696,10208.6C6697.5,10204.9 6698.6,10201 6700.5,10197.5C6710.6,10179.6 6737.6,10177.3 6750.9,10195.8C6762.4,10211.8 6755.4,10237 6733.2,10242.6C6719.1,10246.2 6700.9,10238.1 6696.7,10220.1C6696.5,10219.4 6696.2,10218.7 6695.9,10218C6696,10214.9 6696,10211.7 6696,10208.6Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7042.1,10289.9C7041.1,10306.1 7030,10319.8 7011.7,10319.8C6993.8,10319.8 6981.2,10306.8 6981.5,10289C6981.8,10271.4 6995,10259.2 7011.9,10259.3C7029.4,10259.4 7041.6,10273.1 7042.1,10289.9Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7010.5,10244.3C6993,10243.5 6980.4,10231.1 6980.4,10214.1C6980.4,10196.1 6993,10183.7 7010.5,10183.6C7029,10183.6 7040.9,10197.5 7040.9,10213.9C7041,10231 7027.8,10243.8 7010.5,10244.3Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7324.3,10141.3C7324.5,10125.9 7336.3,10111.1 7355,10111.3C7371.4,10111.5 7385,10123.1 7384.9,10141.5C7384.8,10161 7370.4,10171.8 7354.6,10171.8C7335.6,10171.7 7324.3,10156.4 7324.3,10141.3Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7756.8,10282C7756.4,10299.6 7743.7,10312.7 7726,10312.4C7708.9,10312.2 7696.2,10300.2 7696.2,10282.1C7696.2,10262.9 7711.2,10251.8 7726.6,10251.8C7744.2,10251.9 7756.5,10266.1 7756.8,10282Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7271.1,10071.3C7271.6,10086.9 7258.9,10101.5 7241.3,10101.5C7223,10101.6 7210.7,10086.8 7210.7,10070.9C7210.7,10056.1 7222.9,10040.8 7241,10040.9C7259,10040.9 7271.7,10055.7 7271.1,10071.3Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7565.5,10087.8C7564.9,10103.8 7552.6,10117.5 7535.3,10117.5C7518.9,10117.5 7504.8,10105.9 7504.9,10087.3C7504.9,10068.6 7518.5,10056.7 7535.6,10057C7552.9,10057.2 7565.3,10070.4 7565.5,10087.8Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7632.1,10353.4C7631.5,10371.1 7618.8,10383.5 7602.1,10383.6C7584.5,10383.7 7571.7,10371.4 7571.5,10353.6C7571.3,10335.6 7585,10323.1 7602.1,10323.1C7618.9,10323.1 7631.9,10337 7632.1,10353.4Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7680.4,10124.1C7662.3,10123.5 7649.9,10110.3 7649.9,10093.8C7649.9,10076.3 7662.8,10063.5 7680.2,10063.5C7697.8,10063.5 7710.6,10076.4 7710.4,10093.9C7710.3,10112 7696.9,10123.3 7680.4,10124.1Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M6746.6,10312.9C6732,10313.5 6716.4,10301.2 6716.4,10282.6C6716.5,10264.2 6732,10252.5 6746.4,10252.5C6762.6,10252.4 6776.9,10266.4 6776.9,10282.7C6777,10298.4 6763.4,10313.4 6746.6,10312.9Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7609,10041.6C7626.8,10042.5 7639.3,10054.9 7639.3,10072.1C7639.3,10089.4 7626.4,10102.4 7608.9,10102.3C7591.5,10102.2 7578.9,10089.4 7578.8,10072.2C7578.7,10055 7591.3,10042.6 7609,10041.6Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7034.7,10436.3C7034.6,10451.8 7022.6,10466.4 7004,10466.3C6986.3,10466.3 6973.9,10451.4 6974.2,10435.5C6974.4,10420.6 6986.3,10405.7 7004.6,10405.7C7022.1,10405.7 7035,10420.5 7034.7,10436.3Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M6939.4,10058.4C6956.2,10058.1 6969.8,10071.5 6969.8,10088.6C6969.8,10105.6 6956.4,10118.4 6939.9,10118.8C6926.5,10119.1 6909.5,10109.1 6909.2,10088.8C6909,10069.4 6925.1,10057.9 6939.4,10058.4Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7480.7,10168.3C7463.7,10167.5 7450.4,10155.8 7450.3,10137.9C7450.2,10120.8 7463,10107.5 7480.6,10107.6C7497.5,10107.7 7510.6,10120 7510.7,10137.6C7510.9,10155 7498.3,10167.3 7480.7,10168.3Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M6837.7,10072.3C6838.3,10055 6850.7,10041.8 6867.9,10041.8C6885.4,10041.8 6898.2,10054.5 6898.3,10072C6898.3,10089.2 6885.8,10102.3 6868,10102.3C6850,10102.3 6838.5,10088.8 6837.7,10072.3Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7673.2,10304.1C7688.7,10303.3 7703.6,10317.2 7703.6,10334.2C7703.6,10350.6 7689.4,10364.7 7673.3,10364.5C7654.4,10364.2 7643,10348.6 7643.1,10334.1C7643.3,10317.2 7657.2,10303.7 7673.2,10304.1Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7560,10332.8C7560.5,10348.8 7548,10363.1 7529.9,10363.2C7512.8,10363.3 7499.7,10349.6 7499.7,10332.7C7499.7,10317.3 7511.3,10302.8 7529.8,10302.6C7548.5,10302.4 7560.6,10318 7560,10332.8Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7140.8,10354.5C7141.3,10369.5 7129.6,10384.7 7111,10384.7C7092.2,10384.7 7080.5,10370.2 7080.5,10354.5C7080.5,10338.4 7092.7,10323.9 7111.2,10324.1C7128.7,10324.3 7141.3,10338.5 7140.8,10354.5Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7174,10063.9C7189.7,10063.1 7204.4,10076.2 7204.3,10094.1C7204.2,10112.3 7189.2,10124.4 7173.7,10124.2C7157.1,10123.9 7143.6,10111.4 7143.7,10093.5C7143.7,10076 7158.1,10063.2 7174,10063.9Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M6950.6,10456.9C6964.9,10456.2 6980.7,10467.9 6980.7,10486.8C6980.7,10505.9 6965.7,10517.3 6950.5,10517.2C6933.9,10517.1 6920.2,10504.2 6920.2,10486.9C6920.1,10470.6 6933.4,10456.4 6950.6,10456.9Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7110.5,10093.5C7097.2,10094.4 7080.4,10082.9 7080.5,10063.6C7080.6,10046.1 7094.1,10033.4 7110.8,10033.3C7129.1,10033.1 7140.9,10048.9 7141,10063.7C7141,10079.4 7126.8,10094.6 7110.5,10093.5Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7043.2,10365.4C7043.2,10382.1 7029.9,10395.4 7013.2,10395.3C6996.1,10395.2 6982.9,10381.8 6983,10364.9C6983,10346.3 6999,10334.8 7013.5,10334.8C7029.3,10334.8 7044.4,10349 7043.2,10365.4Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M6901.6,10353.9C6902.3,10368.6 6889.8,10384 6871.9,10384.2C6854.8,10384.3 6841.2,10370.8 6841.3,10353.7C6841.4,10335.3 6857.1,10323.6 6871.4,10323.6C6888.4,10323.7 6902.2,10338 6901.6,10353.9Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7110.7,10106.2C7126.9,10105.1 7141.2,10120.3 7141,10136.3C7140.8,10151.3 7128.8,10166.7 7110.6,10166.5C7094.2,10166.3 7080.8,10154 7080.5,10136.4C7080.1,10116.8 7097.7,10104.9 7110.7,10106.2Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7140.8,10209.4C7141.5,10222.9 7130.1,10239.2 7110.6,10239.1C7092.5,10239 7080.4,10224.7 7080.5,10209.1C7080.6,10191.5 7094,10178.5 7111.1,10178.6C7127.9,10178.7 7141.7,10192.9 7140.8,10209.4Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7140.8,10281.5C7141.8,10294.4 7130.7,10311.8 7110.7,10311.8C7093.9,10311.8 7080.6,10298.4 7080.5,10281.9C7080.3,10263 7096.3,10251.5 7110.5,10251.3C7127.1,10251.1 7141.9,10266.4 7140.8,10281.5Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7140.8,9990.7C7141.5,10006.9 7127.7,10021.2 7110.7,10021C7093.7,10020.9 7080.6,10007.9 7080.5,9990.5C7080.4,9974.9 7092.4,9960.5 7110.6,9960.5C7130,9960.4 7141.7,9976.7 7140.8,9990.7Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7366.8,10251.5C7383.7,10251 7397.1,10266.1 7396.9,10281.9C7396.7,10298.8 7382.6,10311.8 7366.7,10311.9C7351.3,10312 7336.1,10299.1 7336.4,10281.1C7336.6,10265.7 7349.7,10250.9 7366.8,10251.5Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M6836.1,10485.6C6837.1,10500.8 6825.8,10515.6 6808.7,10517.1C6793.3,10518.5 6777.6,10508 6775.8,10489.5C6774.3,10474.5 6784.8,10459 6802.1,10457C6822.3,10454.6 6836,10470.6 6836.1,10485.6Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7448,10280.7C7446.9,10264.7 7462,10250.2 7478.1,10250.5C7494.3,10250.7 7508.2,10263.9 7508.3,10280.5C7508.3,10297.5 7495.3,10310.7 7478.2,10311C7461.2,10311.2 7448,10297.7 7448,10280.7Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M6992.8,10110.8C7009.1,10110.4 7023.2,10123.4 7023.3,10140.9C7023.4,10158.5 7009,10171.2 6992.9,10171.2C6978.5,10171.2 6962.9,10160.6 6962.7,10141.2C6962.5,10122 6978,10110.5 6992.8,10110.8Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M6827.5,10092.5C6828.5,10108.6 6813.5,10122.7 6797.8,10122.7C6780.9,10122.6 6767.1,10109 6767.3,10092.2C6767.5,10074.8 6781.7,10062.3 6797.5,10062.2C6814.6,10062.1 6828.6,10076.9 6827.5,10092.5Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M6745.1,10174.1C6731.1,10175.1 6714.8,10161.2 6715.3,10143.5C6715.6,10130.3 6726.3,10114 6745.3,10113.8C6762,10113.7 6775.8,10126.8 6775.8,10144.4C6775.7,10162.4 6760.1,10175.1 6745.1,10174.1Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7336.4,10354.5C7336.2,10337.6 7349.6,10324.1 7366.5,10324.1C7383.4,10324.1 7396.6,10337.5 7396.8,10354.5C7396.9,10368.7 7385.8,10384.9 7366.4,10384.7C7346.4,10384.4 7335.9,10367.7 7336.4,10354.5Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M6946.4,10307.8C6962.8,10306.9 6976.9,10322 6976.6,10338C6976.2,10354.5 6963.6,10367.9 6946,10368.1C6931.2,10368.2 6916.2,10355.9 6916.1,10337.9C6916,10320.2 6931.8,10306.7 6946.4,10307.8Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7339.6,10085.8C7340.7,10100.4 7327.3,10116 7309.8,10115.9C7293.4,10115.9 7279.4,10103.4 7279.4,10085.8C7279.3,10068.3 7293.5,10055.3 7310.1,10055.4C7325.9,10055.5 7340.8,10069.8 7339.6,10085.8Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7366.7,10239.8C7351.4,10241 7336.1,10226.6 7336.3,10209.8C7336.5,10193.2 7349.7,10179.4 7366.7,10179.6C7384,10179.8 7396.8,10193.6 7396.8,10210.1C7396.9,10226.9 7381.6,10240.9 7366.7,10239.8Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7428.3,10209.4C7428.2,10193.2 7441.3,10179.5 7458.5,10179.5C7476,10179.5 7488.7,10193.6 7488.7,10209.9C7488.7,10225 7476.8,10240.2 7458.2,10240C7438.6,10239.9 7427.6,10223.2 7428.3,10209.4Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M6829.4,10335.5C6830.3,10351.2 6815.7,10365.6 6799.4,10365.4C6783,10365.2 6769.2,10352.6 6769.1,10335C6769,10318.1 6783.2,10304.6 6800,10304.8C6816.5,10305.2 6830.6,10320.2 6829.4,10335.5Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7133.7,10501.2C7130.2,10506.2 7125.6,10509.4 7119.7,10510.9C7107.8,10514 7093.8,10508.1 7089,10496.8C7083.4,10483.4 7083.4,10470 7091.7,10457.5C7096.2,10450.7 7102.9,10447.4 7111,10446.7C7120.3,10445.8 7127.9,10448.7 7133.9,10457.5L7133.9,10451.4C7133.9,10449.5 7134.6,10448.5 7136.7,10448.5C7143.5,10448.5 7142.5,10448.5 7142.5,10453.8L7142.5,10509.1C7142.5,10515.3 7141.3,10521.2 7138.1,10526.6C7134.3,10532.9 7128.2,10535.7 7121.5,10536.7C7115,10537.7 7108.4,10537.5 7102.1,10535.3C7095.7,10533 7091.1,10529 7088.7,10522.5C7087,10518 7087.6,10517.3 7092.2,10517.1C7094.8,10517 7096.5,10517.5 7097.4,10520.4C7099,10525.6 7103.3,10528.2 7108.3,10528.9C7112.6,10529.5 7117.1,10529.5 7121.3,10528.8C7127.7,10527.7 7131,10523 7132.8,10517.1C7134.4,10512 7134,10506.7 7133.7,10501.2ZM7094.4,10478.9C7094.5,10481.1 7094.5,10483.3 7094.7,10485.4C7094.8,10486.8 7095.2,10488.1 7095.6,10489.5C7097.9,10497.4 7104.5,10503.2 7111.8,10503.8C7121.7,10504.6 7127.8,10500.2 7131.1,10492.8C7135.5,10483.1 7135.1,10473 7130.3,10463.4C7128.1,10459.1 7124.6,10455.8 7119.9,10454.6C7110.1,10452 7101.6,10456.1 7097.3,10464.8C7095.1,10469.3 7094.4,10474 7094.4,10478.9Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7637.5,10458L7637.5,10426.7C7637.5,10424.9 7638.4,10424.1 7640.1,10424.2L7641.5,10424.2C7646.1,10424.1 7646.2,10424.2 7646.2,10428.8L7646.2,10495.8C7646.2,10500.2 7646.3,10504.6 7646.1,10508.9C7646.1,10510 7645.5,10511.9 7644.9,10512C7643,10512.3 7640.9,10512 7639,10511.7C7638.7,10511.6 7638.4,10510.4 7638.3,10509.6C7638.2,10507.1 7638.3,10504.6 7638.3,10501.3C7634.5,10507.6 7629.2,10511.1 7622.9,10512.6C7607.1,10516.3 7594.3,10509.5 7589.4,10494.9C7585.7,10484 7585.9,10473.1 7590.5,10462.5C7595,10452.3 7602.9,10446.9 7614.2,10446.4C7623.7,10446.2 7631.4,10449.4 7637.5,10458ZM7638,10480.5C7637.5,10475.6 7636.5,10470.5 7634.8,10466.3C7631.5,10458.5 7624.4,10453.5 7615.8,10453.7C7607.7,10454 7601.9,10458.2 7598.8,10465.6C7594.6,10475.8 7594.5,10486.2 7599.6,10496.3C7601.7,10500.5 7604.9,10503.8 7609.4,10505.3C7620.1,10508.9 7631.2,10504.1 7635.3,10493.2C7636.7,10489.2 7637.1,10484.8 7638,10480.5Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7331.7,10458L7331.7,10429.3C7331.7,10424.1 7332,10423.9 7337.4,10424.2C7340.1,10424.4 7340.3,10424.6 7340.4,10427.2C7340.5,10428.1 7340.4,10429.1 7340.4,10430L7340.4,10506.9C7340.4,10507.2 7340.5,10507.5 7340.4,10507.8C7340.1,10509.2 7340.1,10511.6 7339.3,10511.9C7337.7,10512.6 7335.6,10512.3 7333.8,10512C7333.3,10511.9 7332.7,10510.6 7332.6,10509.8C7332.4,10508.4 7332.5,10507 7332.5,10505.6L7332.5,10501.2C7328.7,10507.7 7323.6,10511.1 7317.3,10512.6C7301.3,10516.5 7288.3,10509.4 7283.5,10494.7C7279.7,10483.1 7280.1,10471.6 7285.7,10460.6C7290.4,10451.3 7298.2,10446.8 7308.7,10446.4C7318.3,10446.2 7325.8,10449.6 7331.7,10458ZM7332.3,10480.1C7331.4,10475.7 7331,10471.3 7329.6,10467.3C7326.5,10458.6 7319.8,10454.4 7310.7,10453.7C7304,10453.2 7296.6,10457.8 7293.4,10465.1C7288.8,10475.5 7288.9,10486 7293.9,10496.2C7296,10500.5 7299.4,10504 7304,10505.4C7315.3,10508.8 7325.5,10504 7329.7,10492.9C7331.1,10488.9 7331.4,10484.4 7332.3,10480.1Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7705,10502.4C7701.3,10507.4 7697,10510.7 7691.6,10512.3C7676.5,10516.9 7661.7,10510.9 7656.5,10495.4C7652.8,10484.4 7652.9,10473.1 7657.6,10462.5C7662,10452.6 7669.8,10447 7681,10446.6C7690.2,10446.3 7697.8,10449.1 7704.2,10457.4C7704.2,10454.4 7704.2,10452.3 7704.3,10450.3C7704.4,10447.5 7704.9,10447.1 7707.7,10446.9C7708.8,10446.8 7709.9,10446.9 7711,10446.9C7712.7,10447 7713.5,10448 7713.6,10449.6L7713.6,10508.2C7713.5,10511.9 7713.1,10512.3 7709.6,10512.4C7705.4,10512.5 7705.1,10512.3 7705.1,10508C7705,10506.4 7705,10504.7 7705,10502.4ZM7704.9,10479.8C7704,10475.4 7703.6,10470.8 7702,10466.7C7698.9,10458.4 7692.5,10454 7683.2,10453.8C7675.5,10453.7 7669.3,10457.2 7665.6,10466C7662,10474.4 7662.2,10483 7664.5,10491.6C7666.2,10497.9 7670.1,10503 7676.3,10505.3C7686.9,10509.2 7698.1,10503.7 7702.1,10493.7C7703.8,10489.3 7704.4,10484.7 7704.9,10479.8Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7490.2,10501.3C7487.2,10506.7 7483.1,10509.7 7478.2,10511.7C7468.3,10515.7 7454.9,10514.5 7446.9,10505.2C7443.5,10501.2 7441.5,10496.6 7440.2,10491.5C7437.8,10481.6 7438.3,10472 7442.4,10462.7C7446.9,10452.5 7454.8,10447.1 7466.1,10446.6C7475.7,10446.2 7483.4,10449.6 7489.4,10458L7489.4,10449.4C7489.4,10447.9 7490.1,10447.1 7491.6,10447C7492.5,10447 7493.5,10446.9 7494.4,10447C7497.9,10447.1 7498.1,10447.2 7498.1,10450.8L7498.1,10498.1C7498.1,10501.8 7498.2,10505.6 7498.1,10509.3C7498,10512.4 7497.8,10512.5 7494.4,10512.5C7490.7,10512.5 7490.4,10512.3 7490.3,10509.1C7490.1,10506.8 7490.2,10504.5 7490.2,10501.3ZM7489.8,10480.4C7489.3,10475.5 7488.3,10470.4 7486.5,10466.2C7483.1,10458.2 7475.2,10453.1 7466.9,10453.8C7460.2,10454.3 7454.8,10457.4 7451.8,10463.3C7446,10474.5 7445.9,10486 7451.9,10497.2C7453.9,10501 7457.1,10504 7461.2,10505.3C7472.1,10508.8 7483,10503.9 7487,10493C7488.5,10489 7488.9,10484.6 7489.8,10480.4Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7387.4,10457.8C7392.1,10450 7398.5,10446.7 7406.6,10446.7C7410.2,10446.7 7413.8,10446.8 7417.2,10447.7C7423.9,10449.5 7428.3,10453.9 7429.8,10460.8C7430.5,10464.3 7431.2,10467.9 7431.2,10471.4C7431.4,10483.4 7431.3,10495.5 7431.2,10507.5C7431.2,10509 7430.9,10511.4 7430.2,10511.7C7428.3,10512.4 7426,10512.3 7423.8,10512.1C7423.3,10512.1 7422.6,10510.7 7422.6,10509.9C7422.4,10507.1 7422.5,10504.3 7422.5,10501.5C7422.5,10493.2 7422.6,10484.9 7422.5,10476.7C7422.4,10472.8 7422.1,10468.9 7421.5,10465.1C7420.5,10458.4 7414.9,10454 7407.4,10453.8C7398.6,10453.5 7390.3,10459.6 7388.4,10468.1C7387.7,10471.3 7387.3,10474.6 7387.3,10477.8C7387.2,10488 7387.3,10498.1 7387.2,10508.3C7387.2,10509.6 7386.8,10511.9 7386.2,10512C7384.1,10512.4 7381.9,10512.2 7379.8,10512C7379.4,10512 7378.9,10510.8 7378.7,10510.1C7378.5,10508.9 7378.6,10507.6 7378.6,10506.4L7378.6,10430C7378.6,10424.3 7377.7,10424.2 7384.6,10424.3C7386.7,10424.3 7387.2,10425.4 7387.2,10427.3L7387.2,10443.7C7387.4,10448.1 7387.4,10452.6 7387.4,10457.8Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7245.9,10513.6C7232.7,10514.5 7220.6,10505.4 7217.1,10491.1C7214.1,10478.7 7215.5,10467.2 7223.2,10456.9C7227.9,10450.6 7234.5,10447 7242.4,10446.7C7246.7,10446.5 7251.3,10446.6 7255.4,10447.8C7263,10450 7268.9,10454.8 7272.3,10462.2C7278.2,10475 7278,10487.6 7271.3,10499.9C7266.2,10509.2 7258,10513.5 7247.4,10513.6C7246.9,10513.7 7246.4,10513.6 7245.9,10513.6ZM7249.3,10453.9C7238,10453.9 7232.2,10457.9 7228.5,10464.6C7223.4,10473.7 7223.1,10483.4 7227.1,10492.9C7230.5,10501.2 7236.8,10506.4 7246,10506.5C7254.8,10506.6 7261,10501.7 7264.6,10493.8C7269.2,10483.7 7268.8,10473.5 7263.3,10463.8C7259.5,10457.3 7253.4,10454 7249.3,10453.9Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7211.1,10478.8C7211.1,10489.3 7208.9,10497.7 7202.7,10504.7C7197.9,10510 7192,10513.3 7184.8,10513.6C7179.5,10513.8 7174.2,10514 7169.1,10511.8C7161.1,10508.4 7155.9,10502.4 7153.1,10494.5C7149.4,10484.3 7149.6,10473.9 7154,10464C7159,10452.7 7167.8,10447 7180.4,10446.6C7193.5,10446.2 7204,10453.5 7208.7,10465.6C7210.5,10470.2 7211.3,10475.1 7211.1,10478.8ZM7202.4,10480.6C7202.6,10475.3 7201.5,10470.3 7199.1,10465.7C7195.5,10458.5 7189.6,10453.9 7181.4,10453.9C7174,10453.8 7167.9,10457 7163.8,10463.6C7157.3,10473.9 7157.9,10489.3 7165.2,10498.9C7172.1,10508 7186,10509.1 7194.2,10501.4C7200.2,10495.6 7202.1,10488.4 7202.4,10480.6Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7720,10448.5L7727.4,10448.5C7729.2,10448.5 7729.6,10449.9 7730.1,10451.2C7733.1,10459.1 7736.2,10466.9 7739.2,10474.8C7742.4,10483.1 7745.6,10491.4 7749.1,10500.5C7750,10498.5 7750.6,10497.2 7751.1,10495.8C7756.3,10481.6 7761.5,10467.4 7766.6,10453.2C7768.4,10448.1 7767.7,10448.5 7773.6,10448.5C7774.5,10448.5 7775.4,10448.7 7776.8,10448.9C7775.1,10453.7 7773.5,10458.2 7771.8,10462.7C7764.1,10483 7756.5,10503.2 7748.7,10523.5C7747.8,10525.9 7746.3,10528.2 7744.9,10530.4C7743.1,10533.2 7740.3,10534.6 7737.1,10535C7734.5,10535.3 7731.8,10535.3 7729.1,10535.1C7726.4,10534.9 7725.7,10533.7 7725.9,10530C7726.1,10527.6 7726.3,10527.4 7729.2,10527.4C7730.4,10527.4 7731.7,10527.5 7732.9,10527.5C7736.2,10527.5 7738.7,10525.9 7740,10523.1C7741.5,10519.9 7743.1,10516.6 7743.9,10513.1C7744.3,10511.3 7743.7,10509.1 7743,10507.3C7738.5,10495.8 7733.9,10484.4 7729.3,10473C7726.6,10466.3 7723.9,10459.7 7721.3,10453C7720.9,10451.8 7720.6,10450.4 7720,10448.5Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7541.2,10461C7544.1,10455.8 7547.3,10452.5 7551.4,10450.2C7554.9,10448.2 7558.6,10447.5 7562.6,10447.6C7564.6,10447.7 7565.4,10448.6 7565.3,10450.5C7565.3,10451.6 7565.3,10452.7 7565.2,10453.8C7565.1,10455.5 7564.2,10456.2 7562.5,10456.4C7560.2,10456.7 7557.8,10456.9 7555.7,10457.6C7547.6,10460.3 7542.2,10467.3 7542,10475.8C7541.7,10486.1 7541.9,10496.4 7541.8,10506.7C7541.8,10512.4 7540.8,10513.3 7535.3,10512.6C7533.7,10512.4 7532.7,10511.7 7532.6,10510C7532.5,10508.9 7532.6,10507.8 7532.6,10506.7L7532.6,10454.3C7532.6,10448.7 7531.8,10448.5 7538.6,10448.6C7540.4,10448.6 7541.1,10449.5 7541.1,10451.2C7541.1,10454 7541.2,10457 7541.2,10461Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7519.7,10452.9L7519.7,10508.7C7519.7,10512.2 7519.5,10512.3 7515.9,10512.4C7511.7,10512.5 7511,10512 7511,10508.7C7510.9,10490.3 7511,10471.8 7511,10453.4L7511,10451.5C7511.2,10448.8 7511.3,10448.6 7514,10448.4L7514.9,10448.4C7519.6,10448.3 7519.7,10448.3 7519.7,10452.9Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M7519.7,10430.7C7519.7,10431.3 7519.8,10432 7519.7,10432.6C7519.5,10434.2 7520.6,10436.5 7518.6,10437.2C7516.6,10437.8 7514.3,10437.4 7512.2,10437.2C7511.8,10437.2 7511.2,10436 7511.1,10435.4C7511,10432.3 7511,10429.2 7511.1,10426.1C7511.1,10425.5 7512.2,10424.9 7512.8,10424.3C7512.9,10424.2 7513.1,10424.3 7513.3,10424.3C7519.2,10423.4 7519.7,10423.9 7519.7,10430.7Z"
                                style={{ fill: "white" }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
