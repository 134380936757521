import React, { useEffect, useRef } from "react";
import { Link, graphql } from "gatsby";
import gsap, { TweenMax, Back } from "gsap";
import SEO from "../components/seo";
import ThreeBackground from "../components/threeBackground";
import useBackgroundColor from "../zustand/useBackgroundColor";
import Background from "../components/background";
import Scrollbar from "smooth-scrollbar";
import Typed from "typed.js";
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useThreeColor from "../zustand/useThreeColor";
import ReelLauncher from "../components/reelLauncher";
import useReelState from "../zustand/useReelState";
import useContactForm from "../zustand/useContactForm";
import useSmoothScroll from "../zustand/useSmoothScroll";
import {
    SevenEleven,
    ALHGroup,
    BMW,
    Carat,
    Chevron,
    Converse,
    Crown,
    DestroyAll,
    Disney,
    EMI,
    FallsFestival,
    FringeWorld,
    GageRoads,
    HowardPark,
    Isobar,
    JackDaniels,
    Laneway,
    LiquorBarons,
    Marketforce,
    Mellen,
    NitroCircus,
    OMD,
    Origin,
    Oztix,
    TourismWA,
    Universal,
    UWA,
    Warner,
    FrankBody,
    Bonfire,
    CLG,
    GHD,
    Murdoch,
    Ableton,
    Bluesfest,
    PoppyLissiman,
    Movember,
    OldSynagogue,
    Denada,
} from "../components/clients";
import Layout from "../layouts";
import AniLink from "gatsby-plugin-transition-link/AniLink";

gsap.registerPlugin(SplitText);

function init() {
    new SmoothScroll(document, 60, 20);
}

function SmoothScroll(target, speed, smooth) {
    if (target === document)
        target =
            document.scrollingElement ||
            document.documentElement ||
            document.body.parentNode ||
            document.body; // cross browser support for document scrolling

    window.scrollTo(0, 0);
    target.scrollTop = 0;
    var moving = false;
    var unmount = false;
    var timeouts = [];
    var pos = target.scrollTop;
    var frame =
        target === document.body && document.documentElement
            ? document.documentElement
            : target; // safari is the new IE

    target.addEventListener("mousewheel", scrolled, { passive: false });
    target.addEventListener("DOMMouseScroll", scrolled, { passive: false });

    function scrolled(e) {
        if (unmount) {
            return;
        }

        if (document.querySelector("body").classList.contains("noscroll")) {
            return;
        }
        e.preventDefault(); // disable default scrolling
        var delta = normalizeWheelDelta(e);

        pos += -delta * speed;
        pos = Math.max(
            0,
            Math.min(pos, target.scrollHeight - frame.clientHeight)
        ); // limit scrolling

        if (!moving) update();
    }

    this.updatePos = (position) => {
        target.scrollTop = position;
        pos = position;
    };

    function normalizeWheelDelta(e) {
        if (e.detail) {
            if (e.wheelDelta)
                return (e.wheelDelta / e.detail / 40) * (e.detail > 0 ? 1 : -1);
            // Opera
            else return -e.detail / 3; // Firefox
        } else return e.wheelDelta / 120; // IE,Safari,Chrome
    }

    function update() {
        if (unmount) {
            return;
        }

        moving = true;
        if (!document.querySelector("body").classList.contains("noscroll")) {
            var delta = (pos - target.scrollTop) / smooth;
            target.scrollTop += delta;
        } else {
            moving = false;
        }

        if (Math.abs(delta) > 0.5) requestFrame(update);
        else moving = false;
    }

    this.unmount = () => {
        unmount = true;
        timeouts.forEach((t) => {
            window.clearTimeout(t);
        });
        target.removeEventListener("mousewheel", scrolled, { passive: false });
        target.removeEventListener("DOMMouseScroll", scrolled, {
            passive: false,
        });
    };

    var requestFrame = (function () {
        // requestAnimationFrame cross browser
        if (unmount) {
            return;
        }
        return (
            window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.oRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            function (func) {
                timeouts.push(window.setTimeout(func, 1000 / 50));
            }
        );
    })();
}

/* Home Page
============================================================================= */

const IndexPage = ({ data, location }) => {
    // Config
    const { setIsDark } = useBackgroundColor();
    const { setSmooth } = useSmoothScroll();
    const {
        setReelOpen,
        setCanOpenReel,
        setShowPlayButton,
        setShowScroll,
    } = useReelState();

    useEffect(() => {
        setIsDark(false);
        setReelOpen(false);
        setCanOpenReel(false);
        setShowPlayButton(false);
        setShowScroll(true);
        setTimeout(() => {
            setSmooth(new SmoothScroll(document, 60, 20));
            setTimeout(() => {
                ScrollTrigger.getAll().forEach((el) => {
                    el.scroll(0);
                    el.refresh();
                    el.enable();
                });
                setCanOpenReel(true);
                setReelOpen(false);
                setShowScroll(true);
                setShowPlayButton(false);
            }, 500);
        }, 1000);
        return () => {
            setCanOpenReel(false);
        };
    }, []);

    // Render
    return (
        <Layout location={location}>
            <style>
                {`section > #menu-hamburger {
                display: none !important;
            }`}
            </style>
            <SEO title="MITP Agency | Digital & Creative Marketing" />
            <ThreeBackground />
            <ReelLauncher />
            <Sections />
        </Layout>
    );
};

/* Sections
============================================================================= */

const Sections = () => {
    // Config
    const { setColor, setBgColor } = useThreeColor();
    const {
        setReelOpen,
        setShowPlayButton,
        setShowScroll,
        setCanOpenReel,
        canOpenReel,
    } = useReelState();
    useEffect(() => {
        const homeSectionOne = gsap.to("#home-section-one", {
            scrollTrigger: {
                trigger: "#home-section-one",
                start: "top center",
                end: "bottom center",
                id: "home-section-one",
                onEnter: () => {
                    setBgColor("rgb(27, 178, 149)");
                    setColor("rgb(198, 154, 244)");
                    setReelOpen(false);
                    gsap.to(".section-one-link", {
                        duration: 1,
                        stagger: 0.75,
                        delay: 1,
                        transform: "translateY(0px)",
                        opacity: 1,
                    });
                },
                onEnterBack: () => {
                    setReelOpen(false);
                },
                onLeave: () => {
                    setReelOpen(true);
                },
            },
            opacity: 1,
        });
        let splitText = new SplitText(".section-two-split-text", {
            type: "words",
        });
        splitText.words.forEach((w, i) => {
            if (i <= 1) {
                return;
            }
            TweenMax.set(w, { opacity: 0, top: "75px", right: "10px" });
        });
        let splitTextMobile = new SplitText(".section-two-split-text-mobile", {
            type: "words",
        });
        splitTextMobile.words.forEach((w, i) => {
            if (i <= 1) {
                return;
            }
            TweenMax.set(w, { opacity: 0, top: "75px", right: "10px" });
        });
        const homeSectionTwo = gsap.to("#home-section-two", {
            scrollTrigger: {
                trigger: "#home-section-two",
                start: "top center",
                end: "bottom center",
                id: "home-section-two",
                onLeaveBack: () => {
                    setBgColor("rgb(27, 178, 149)");
                    setColor("rgb(198, 154, 244)");
                    setReelOpen(false);
                },
                onEnter: () => {
                    setBgColor("rgb(255, 91, 34)");
                    setColor("rgb(201, 79, 111)");
                    setShowScroll(false);
                    setShowPlayButton(true);
                    TweenMax.staggerTo(
                        splitText.words,
                        0.375,
                        {
                            css: {
                                top: "0px",
                                right: "0px",
                            },
                            ease: Back.easeOut.config(1),
                        },
                        0.075
                    );
                    TweenMax.staggerTo(
                        splitText.words,
                        1.5,
                        { opacity: 1 },
                        0.075
                    );
                    TweenMax.staggerTo(
                        splitTextMobile.words,
                        0.375,
                        {
                            css: {
                                top: "0px",
                                right: "0px",
                            },
                            ease: Back.easeOut.config(1),
                        },
                        0.075
                    );
                    TweenMax.staggerTo(
                        splitTextMobile.words,
                        1.5,
                        { opacity: 1 },
                        0.075
                    );
                },
            },
            opacity: 1,
        });
        let splitTextTwo = new SplitText(".section-three-split-text", {
            type: "words",
        });
        splitTextTwo.words.forEach((w, i) => {
            if (i <= 1) {
                return;
            }
            TweenMax.set(w, { opacity: 0, top: "75px", right: "10px" });
        });
        let splitTextTwoMobile = new SplitText(
            ".section-three-split-text-mobile",
            {
                type: "words",
            }
        );
        splitTextTwoMobile.words.forEach((w, i) => {
            if (i <= 1) {
                return;
            }
            TweenMax.set(w, { opacity: 0, top: "75px", right: "10px" });
        });
        const homeSectionThree = gsap.to("#home-section-three", {
            scrollTrigger: {
                trigger: "#home-section-three",
                start: "top center",
                end: "bottom center",
                id: "home-section-three",
                onLeaveBack: () => {
                    setBgColor("rgb(255, 91, 34)");
                    setColor("rgb(201, 79, 111)");
                },
                onToggle: () => {
                    setBgColor("rgb(27, 178, 149)");
                    setColor("rgb(63, 209, 94)");
                    TweenMax.staggerTo(
                        splitTextTwo.words,
                        0.375,
                        {
                            css: {
                                top: "0px",
                                right: "0px",
                            },
                            ease: Back.easeOut.config(1),
                        },
                        0.075
                    );
                    TweenMax.staggerTo(
                        splitTextTwo.words,
                        1.5,
                        { opacity: 1 },
                        0.075
                    );
                    TweenMax.staggerTo(
                        splitTextTwoMobile.words,
                        0.375,
                        {
                            css: {
                                top: "0px",
                                right: "0px",
                            },
                            ease: Back.easeOut.config(1),
                        },
                        0.075
                    );
                    TweenMax.staggerTo(
                        splitTextTwoMobile.words,
                        1.5,
                        { opacity: 1 },
                        0.075
                    );
                },
            },
            opacity: 1,
        });
        let splitTextFour = new SplitText(".section-four-split-text", {
            type: "words",
        });
        splitTextFour.words.forEach((w, i) => {
            if (i <= 1) {
                return;
            }
            TweenMax.set(w, { opacity: 0, top: "75px", right: "10px" });
        });
        let splitTextFourMobile = new SplitText(
            ".section-four-split-text-mobile",
            {
                type: "words",
            }
        );
        splitTextFourMobile.words.forEach((w, i) => {
            if (i <= 1) {
                return;
            }
            TweenMax.set(w, { opacity: 0, top: "75px", right: "10px" });
        });
        const homeSectionFour = gsap.to("#home-section-four", {
            scrollTrigger: {
                trigger: "#home-section-four",
                start: "top center",
                end: "bottom center",
                id: "home-section-four",
                onLeaveBack: () => {
                    setBgColor("rgb(27, 178, 149)");
                    setColor("rgb(63, 209, 94)");
                },
                onEnterBack: () => {
                    setReelOpen(true);
                },
                onEnter: () => {
                    setBgColor("rgb(244, 150, 8)");
                    setColor("rgb(198, 154, 244)");
                    setTimeout(() => {
                        gsap.to("#three-typeout", { opacity: 1 });
                        gsap.to("#three-typeout-mobile", { opacity: 1 });
                    }, 350);
                    TweenMax.staggerTo(
                        splitTextFour.words,
                        0.375,
                        {
                            css: {
                                top: "0px",
                                right: "0px",
                            },
                            ease: Back.easeOut.config(1),
                        },
                        0.075
                    );
                    TweenMax.staggerTo(
                        splitTextFour.words,
                        1.5,
                        { opacity: 1 },
                        0.075
                    );
                    TweenMax.staggerTo(
                        splitTextFourMobile.words,
                        0.375,
                        {
                            css: {
                                top: "0px",
                                right: "0px",
                            },
                            ease: Back.easeOut.config(1),
                        },
                        0.075
                    );
                    TweenMax.staggerTo(
                        splitTextFourMobile.words,
                        1.5,
                        { opacity: 1 },
                        0.075
                    );
                },
            },
            opacity: 1,
        });
        const homeSectionFive = gsap.to("#home-section-five", {
            scrollTrigger: {
                trigger: "#home-section-five",
                start: "top center",
                end: "bottom center",
                id: "home-section-five",
                onLeaveBack: () => {
                    setBgColor("rgb(244, 150, 8)");
                    setColor("rgb(198, 154, 244)");
                },
                onEnter: () => {
                    setReelOpen(false);
                    setBgColor("rgb(216, 108, 191)");
                    setColor("rgb(79, 127, 196)");
                    gsap.to(".clients-hide-span", {
                        opacity: 1,
                        duration: 0,
                    });
                    gsap.to("#clients-span", {
                        transform: "scale(1)",
                        color: "white",
                    });
                },
            },
        });
        const homeSectionSix = gsap.to("#home-section-six", {
            scrollTrigger: {
                trigger: "#home-section-six",
                start: "top 57.5%",
                end: "bottom 45%",
                id: "home-section-six",
                pin: "#home-section-five",
                pinSpacing: false,
                onLeaveBack: () => {
                    gsap.to(".clients-hide-span", {
                        opacity: 1,
                    });
                    gsap.to("#clients-span", {
                        transform: "scale(1)",
                        color: "white",
                    });
                },
                onEnter: () => {
                    gsap.to(".clients-hide-span", {
                        opacity: 0,
                    });
                    gsap.to("#clients-span", {
                        transform: "scale(1.4)",
                        color: "#d3c846",
                    });
                    gsap.to(".clients-grid-row > svg", {
                        duration: 0.25,
                        stagger: 0.1,
                        transform: "translateY(5vh)",
                        opacity: 1,
                    });
                    gsap.to(".clients-grid-row-mobile > svg", {
                        duration: 0.25,
                        stagger: 0.1,
                        transform: "translateY(5vh)",
                        opacity: 1,
                    });
                },
            },
        });
        let splitTextSeven = new SplitText(".section-seven-split-text", {
            type: "words",
        });
        splitTextSeven.words.forEach((w, i) => {
            if (i === 0) {
                return;
            }
            TweenMax.set(w, { opacity: 0, top: "75px", right: "10px" });
        });
        const homeSectionSeven = gsap.to("#home-section-seven", {
            scrollTrigger: {
                trigger: "#home-section-seven",
                start: "top 25%",
                end: "bottom center",
                id: "home-section-seven",
                onLeaveBack: () => {
                    setBgColor("rgb(216, 108, 191)");
                    setColor("rgb(79, 127, 196)");
                    setShowPlayButton(true);
                },
                onEnter: () => {
                    setShowPlayButton(false);
                },
                onToggle: () => {
                    setBgColor("rgb(255, 90, 35)");
                    setColor("rgb(72, 176, 204)");
                    setTimeout(() => {
                        gsap.to("#seven-typeout", { opacity: 1 });
                    }, 350);
                    TweenMax.staggerTo(
                        splitTextSeven.words,
                        0.375,
                        {
                            css: {
                                top: "0px",
                                right: "0px",
                            },
                            ease: Back.easeOut.config(1),
                        },
                        0.075
                    );
                    TweenMax.staggerTo(
                        splitTextSeven.words,
                        1.5,
                        { opacity: 1 },
                        0.075
                    );
                },
            },
            opacity: 1,
        });
        setReelOpen(false);
        return () => {
            ScrollTrigger.getById("home-section-one").kill(true);
            ScrollTrigger.getById("home-section-two").kill(true);
            ScrollTrigger.getById("home-section-three").kill(true);
            ScrollTrigger.getById("home-section-four").kill(true);
            ScrollTrigger.getById("home-section-five").kill(true);
            ScrollTrigger.getById("home-section-six").kill(true);
            ScrollTrigger.getById("home-section-seven").kill(true);
            setBgColor("rgb(27, 178, 149)");
            setColor("rgb(198, 154, 244)");
        };
    }, []);

    // Render
    return (
        <div id="smooth-scroll" className="relative z-10 w-full">
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <SectionFour />
            <SectionFive />
            <SectionSix />
            <SectionSeven />
        </div>
    );
};

/* Section One
============================================================================= */

const SectionOne = () => {
    // Config
    const { smooth } = useSmoothScroll();

    // Render
    return (
        <div
            id="home-section-one"
            className={`homepage-section w-full h-screen flex flex-col items-center justify-center md:pb-0 pb-20`}
        >
            <h1
                className={`section-one-link md:text-6xl text-4xl uppercase cursor-pointer`}
                onClick={(e) => {
                    document
                        .getElementById("home-section-two")
                        .scrollIntoView({ behavior: "smooth" });
                    setTimeout(() => {
                        let target =
                            document.scrollingElement ||
                            document.documentElement ||
                            document.body.parentNode ||
                            document.body; // cross browser support for document scrolling
                        smooth.updatePos(target.scrollTop);
                    }, 750);
                }}
                style={{
                    transform: "translateY(20px)",
                    opacity: 0,
                    fontFamily: "Kritik-Bold",
                }}
            >
                AGENCY
            </h1>

            <AniLink
                to="/thoughts/"
                cover
                bg="#d3c846"
                duration={1}
                onClick={() => {
                    setTimeout(() => {
                        smooth.unmount();
                    }, 500);
                }}
            >
                <h1
                    className={`section-one-link md:text-6xl text-3xl uppercase`}
                    style={{
                        transform: "translateY(20px)",
                        opacity: 0,
                        fontFamily: "Kritik-Bold",
                    }}
                >
                    THOUGHTS
                </h1>
            </AniLink>
        </div>
    );
};

/* Section Two
            <AniLink
                to="/services"
                cover
                bg="#d3c846"
                duration={1}
                className="hidden"
            >
                <h1
                    className={`section-one-link md:text-5xl text-3xl uppercase`}
                    style={{
                        transform: "translateY(20px)",
                        opacity: 0,
                    }}
                >
                    SERVICES
                </h1>
            </AniLink>
============================================================================= */

const SectionTwo = () => {
    // Config
    const { setShowVideo, showVideo, reelOpen } = useReelState();

    // Render
    return (
        <div
            id="home-section-two"
            style={{ opacity: 0 }}
            className={`homepage-section w-full h-screen flex flex-col items-center justify-center opacity-0`}
        >
            <div className="flex-row items-center justify-between hidden w-full pl-5 pr-10 mx-auto md:flex xl:px-48 md:px-32">
                <div>
                    <p className="section-two-split-text">MITP.</p>
                    <p className="section-two-split-text">
                        A multi-award winning <br />
                        full service agency <br />
                        specialising in content <br />
                        production, branding & <br /> social media marketing.
                    </p>
                    <p className="section-two-split-text">
                        We love making our <br />
                        clients look good. <br />
                        See for yourself...{" "}
                    </p>
                </div>
                <div />
            </div>
            <div className="flex flex-col items-start justify-start w-full pl-3 pr-10 mx-auto md:hidden xl:px-48 md:px-32">
                <div>
                    <p className="section-two-split-text-mobile">MITP.</p>
                    <p className="section-two-split-text-mobile">
                        A multi-award
                        <br />
                        winning full
                        <br />
                        service agency
                        <br />
                        specialising in
                        <br />
                        content production,
                        <br />
                        branding & social
                        <br />
                        media marketing.
                    </p>
                    <p className="section-two-split-text-mobile">
                        We love making our <br />
                        partners look good. <br />
                        See for yourself...{" "}
                    </p>
                </div>
                <div className="mobile-reel-thumb">
                    <div
                        id="play-reel-circle-mobile"
                        onClick={() => setShowVideo(true)}
                        className={`absolute flex items-center justify-center h-32 w-32 ${
                            reelOpen ? "play-reel-circle-open-mobile" : ""
                        }`}
                    >
                        <h2
                            className="text-sm text-center"
                            style={{
                                fontFamily: "Kritik-Bold",
                            }}
                        >
                            PLAY REEL
                        </h2>
                        <svg
                            className={`progress-ring absolute top-0 w-full h-full ${
                                reelOpen ? "progress-ring-active" : ""
                            }`}
                            width="100%"
                            height="100%"
                        >
                            <circle
                                className="progress-ring__circle"
                                stroke="white"
                                stroke-width="3"
                                fill="transparent"
                                r="62"
                                cx="65"
                                cy="65"
                            />
                        </svg>
                    </div>
                    <video loop muted autoPlay id="reel-video-preview">
                        <source src="reel.mp4" />
                    </video>
                </div>
            </div>
        </div>
    );
};

/* Section Three
============================================================================= */

const SectionThree = () => {
    // Config
    const { isDark } = useBackgroundColor();

    // Render
    return (
        <div
            id="home-section-three"
            className={`homepage-section w-full h-screen flex flex-col items-center justify-center opacity-0`}
        >
            <div className="flex-row items-center justify-between hidden w-full pl-5 pr-10 mx-auto md:flex xl:px-48 md:px-32">
                <div>
                    <p className="section-three-split-text">
                        Agency + production <br />
                        meet under one roof, <br />
                        with creative + analytics <br />
                        joining forces to deliver <br />
                        powerful results.
                    </p>
                    <p className="section-three-split-text">
                        Continual campaign <br />
                        optimisation ensures <br />
                        peak performance, <br />
                        from beginning to end.
                    </p>
                </div>
                <div />
            </div>
            <div className="flex flex-row items-center justify-between w-full pl-3 pr-10 mx-auto md:hidden xl:px-48 md:px-32">
                <div>
                    <p className="section-three-split-text-mobile">
                        Agency + production <br />
                        meet under one
                        <br />
                        roof, with creative +<br />
                        analytics joining <br />
                        forces to deliver <br />
                        powerful results.
                    </p>
                    <p className="section-three-split-text-mobile">
                        Continual campaign <br />
                        optimisation ensures <br />
                        peak performance, <br />
                        from beginning
                        <br /> to end.
                    </p>
                </div>
                <div />
            </div>
        </div>
    );
};

/* Section Four
============================================================================= */

const SectionFour = () => {
    // Config
    const { isDark } = useBackgroundColor();
    useEffect(() => {
        const typed = new Typed("#three-typeout", {
            strings: [
                "videos",
                "social media",
                "augmented reality",
                "brands",
                "content",
                "websites",
                "impressions",
            ],
            smartBackspace: true,
            typeSpeed: 100,
            backSpeed: 50,
            loop: true,
            loopCount: Infinity,
        });
        const typedMobile = new Typed("#three-typeout-mobile", {
            strings: [
                "videos",
                "social media",
                "augmented reality",
                "brands",
                "content",
                "websites",
                "impressions",
            ],
            smartBackspace: true,
            typeSpeed: 100,
            backSpeed: 50,
            loop: true,
            loopCount: Infinity,
        });
    }, []);

    // Render
    return (
        <div
            id="home-section-four"
            className={`homepage-section w-full h-screen flex flex-col items-center justify-center`}
        >
            <div className="flex-row items-center justify-between hidden w-full pl-5 pr-10 mx-auto md:flex xl:px-48 md:px-32">
                <div>
                    <p className="section-four-split-text">
                        Industry leaders in <br />
                        creating{" "}
                        <span
                            id="three-typeout"
                            style={{ color: "#D3C846", opacity: 0 }}
                        ></span>
                        <br />
                        designed to reward <br />
                        your audience.
                    </p>
                    <p className="section-four-split-text">
                        Expand your audience.
                    </p>
                    <p className="section-four-split-text">
                        Create customers
                        <br /> from your audience. <br />
                    </p>
                </div>
                <div />
            </div>
            <div className="flex flex-row items-center justify-between w-full pl-3 pr-0 mx-auto md:hidden xl:px-48 md:px-32">
                <div>
                    <p className="section-four-split-text-mobile">
                        Industry leaders in
                        <br />
                        creating{" "}
                        <span
                            id="three-typeout-mobile"
                            style={{ color: "#D3C846", opacity: 0 }}
                        ></span>
                        <br />
                        designed to reward <br />
                        your audience.
                    </p>
                    <p className="section-four-split-text-mobile">
                        Expand your <br />
                        audience.
                    </p>
                    <p className="section-four-split-text-mobile">
                        Create customers
                        <br />
                        from your audience. <br />
                    </p>
                </div>
                <div />
            </div>
        </div>
    );
};

/* Section Five
============================================================================= */

const SectionFive = () => {
    // Config
    const { isDark } = useBackgroundColor();

    // Render
    return (
        <>
            <div
                id="home-section-five"
                className={`homepage-section w-full h-screen hidden md:flex flex-col items-center justify-center`}
            >
                <div className="flex-row items-center justify-between hidden w-full pl-0 pr-5 mx-auto xl:px-48 md:px-32 md:flex">
                    <div className="hidden md:block">
                        <h2>
                            <span className="clients-hide-span">
                                But don't take our
                            </span>{" "}
                            <br />
                            <span className="clients-hide-span">
                                word for it, ask our
                            </span>{" "}
                            <br />
                            <span id="clients-span">partners.</span> <br />
                        </h2>
                    </div>
                    <div />
                </div>
            </div>
            <div
                className={`homepage-section w-full h-screen md:hidden flex flex-col items-center justify-center`}
            >
                <div className="container flex flex-row items-center justify-between pl-5 pr-5 mx-auto md:hidden md:px-32 md:pl-0 md:pr-0 ">
                    <div>
                        <h2 className="leading-tight">
                            <span className="leading-tight clients-hide-span">
                                But don't <br />
                                take our
                                <br />
                            </span>{" "}
                            <span className="leading-tight clients-hide-span">
                                word for it, <br />
                                ask our
                            </span>{" "}
                            <br />
                            <span className="leading-tight clients-hide-span">
                                partners.
                            </span>{" "}
                            <br />
                        </h2>
                    </div>
                    <div />
                </div>
            </div>
        </>
    );
};

/* Section Six
============================================================================= */

const SectionSix = () => {
    // Config
    const { isDark } = useBackgroundColor();

    // Render
    return (
        <div
            id="home-section-six"
            className={`homepage-section w-full md:h-screen flex flex-col items-end justify-end`}
        >
            <div
                className="hidden md:block"
                style={{
                    height: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    paddingLeft: "15vw",
                    paddingRight: "5vw",
                    position: "relative",
                }}
            >
                <div id="clientsInfo">
                    <div className="clients-grid-desktop">
                        <div id="logo-row-one" className="clients-grid-row">
                            <SevenEleven />
                            <ALHGroup />
                            <BMW />
                            <Carat />
                            <Chevron />
                            <Converse />
                            <Crown />
                            <CLG />
                        </div>
                        <div id="logo-row-two" className="clients-grid-row">
                            <DestroyAll />
                            <Disney />
                            <EMI />
                            <FallsFestival />
                            <FringeWorld />
                            <Denada />
                            <Bonfire />
                        </div>
                        <div id="logo-row-three" className="clients-grid-row">
                            <GageRoads />
                            <Isobar />
                            <HowardPark />
                            <JackDaniels />
                            <Laneway />
                            <Bluesfest />
                            <GHD />
                        </div>
                        <div id="logo-row-four" className="clients-grid-row">
                            <LiquorBarons />
                            <Marketforce />
                            <Mellen />
                            <NitroCircus />
                            <OMD />
                            <Murdoch />
                        </div>
                        <div id="logo-row-five" className="clients-grid-row">
                            <Origin />
                            <Oztix />
                            <TourismWA />
                            <Universal />
                            <UWA />
                            <Movember />
                        </div>
                        <div id="logo-row-six" className="clients-grid-row">
                            <FrankBody />
                            <Ableton />
                            <PoppyLissiman />
                            <OldSynagogue />
                            <Warner />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="flex py-12 md:hidden"
                style={{
                    height: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    position: "relative",
                }}
            >
                <div id="clientsInfoMobile" className="visible-black">
                    <p
                        style={{
                            fontFamily: "Montserrat-Bold",
                            color: "#d3c846",
                            fontSize: "10vw",
                            paddingLeft: "9vw",
                            marginBottom: 0,
                        }}
                    >
                        partners.
                    </p>
                    <div className="clients-grid-mobile">
                        <div
                            id="mobile-grid-one-row-one"
                            className="clients-grid-row-mobile"
                        >
                            <SevenEleven />
                            <ALHGroup />
                            <BMW />
                            <Carat />
                        </div>
                        <div
                            id="mobile-grid-one-row-two"
                            className="clients-grid-row-mobile"
                        >
                            <GageRoads />
                            <Disney />
                            <EMI />
                        </div>
                        <div
                            id="mobile-grid-one-row-three"
                            className="clients-grid-row-mobile"
                        >
                            <Mellen />
                            <Warner />
                            <Isobar />
                        </div>
                        <div
                            id="mobile-grid-one-row-four"
                            className="clients-grid-row-mobile"
                        >
                            <OMD />
                            <Laneway />
                            <HowardPark />
                        </div>
                    </div>
                    <div className="clients-grid-mobile">
                        <div
                            id="mobile-grid-two-row-one"
                            className="clients-grid-row-mobile"
                        >
                            <Chevron />
                            <FringeWorld />
                            <Oztix />
                        </div>
                        <div
                            id="mobile-grid-two-row-two"
                            className="clients-grid-row-mobile"
                        >
                            <FallsFestival />
                            <Crown />
                            <NitroCircus />
                        </div>
                        <div
                            id="mobile-grid-two-row-three"
                            className="clients-grid-row-mobile"
                        >
                            <TourismWA />
                            <Origin />
                            <LiquorBarons />
                        </div>
                        <div
                            id="mobile-grid-two-row-four"
                            className="clients-grid-row-mobile"
                        >
                            <UWA />
                            <Denada />
                            <JackDaniels />
                            <Universal />
                        </div>
                        <div
                            id="mobile-grid-two-row-five"
                            className="clients-grid-row-mobile"
                        >
                            <FrankBody />
                            <Bonfire />
                            <CLG />
                            <Movember />
                        </div>
                        <div
                            id="mobile-grid-two-row-six"
                            className="clients-grid-row-mobile"
                        >
                            <GHD />
                            <Murdoch />
                            <Ableton />
                        </div>
                        <div
                            id="mobile-grid-two-row-seven"
                            className="clients-grid-row-mobile"
                        >
                            <PoppyLissiman />
                            <Bluesfest />
                            <OldSynagogue />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

/* Section Seven
============================================================================= */

const SectionSeven = () => {
    // Config
    const { isDark } = useBackgroundColor();
    useEffect(() => {
        const typed = new Typed("#seven-typeout", {
            strings: ["noise", "waves", "leaps", "moves", "progress", "change"],
            smartBackspace: true,
            typeSpeed: 100,
            backSpeed: 50,
            loop: true,
            loopCount: Infinity,
        });
    }, []);

    // Render
    return (
        <div
            id="home-section-seven"
            className={`homepage-section w-full h-screen flex flex-col items-center justify-center relative overflow-hidden`}
        >
            <div className="flex flex-row items-center justify-between w-full pl-5 pr-10 mx-auto pointer-events-none xl:px-48 md:px-32">
                <div>
                    <h1 className="section-seven-split-text">
                        Let's <br />
                        make <br />
                        some <br />
                        <span
                            id="seven-typeout"
                            style={{ color: "#D3C846", opacity: 0 }}
                        ></span>
                        <br />
                        together. <br />
                    </h1>
                </div>
                <div />
            </div>
            <GetInTouchWheel />
        </div>
    );
};

/* Get In Touch Wheel
============================================================================= */

const GetInTouchWheel = () => {
    // Config
    const { setContactOpen } = useContactForm();

    // Render
    return (
        <div id="getInTouchWheelContainer" onClick={() => setContactOpen(true)}>
            <PaperPlane />
            <div id="get-in-touch-wheel">
                <svg
                    id="getInTouchWheel"
                    width="100%"
                    height="100%"
                    viewBox="0 0 253 253"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        fillRule: "evenodd",
                        clipRule: "evenodd",
                        strokeLinejoin: "round",
                        strokeMiterlimit: 1.41421,
                        fill: "white",
                        stroke: "white",
                        strokeWidth: "2px",
                    }}
                >
                    <g transform="matrix(1,0,0,1,-1.66804,-1.6513)">
                        <g transform="matrix(0.246819,0,0,0.246819,96.4072,96.4072)">
                            <g transform="matrix(1,0,0,1,179.109,-380.167)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,46.899C-3.458,48.455 -7.041,49.513 -10.738,50.073C-14.434,50.633 -18.183,50.819 -21.984,50.591C-26.023,50.363 -29.71,49.513 -33.043,48.04C-36.378,46.567 -39.221,44.597 -41.559,42.087C-43.907,39.577 -45.672,36.611 -46.865,33.188C-48.06,29.745 -48.526,25.949 -48.288,21.821C-48.049,17.694 -47.136,13.981 -45.547,10.703C-43.968,7.447 -41.861,4.688 -39.243,2.489C-36.616,0.27 -33.562,-1.369 -30.085,-2.427C-26.605,-3.505 -22.835,-3.92 -18.796,-3.671C-14.664,-3.443 -10.882,-2.717 -7.456,-1.514C-4.03,-0.311 -1.08,1.577 1.412,4.17L-7.135,12.446C-8.598,10.745 -10.281,9.438 -12.182,8.484C-14.081,7.551 -16.491,6.991 -19.409,6.825C-21.85,6.679 -24.07,6.97 -26.097,7.675C-28.122,8.401 -29.876,9.438 -31.372,10.849C-32.878,12.238 -34.062,13.939 -34.934,15.951C-35.806,17.942 -36.314,20.141 -36.461,22.527C-36.595,24.953 -36.356,27.194 -35.713,29.247C-35.078,31.321 -34.102,33.126 -32.773,34.702C-31.444,36.258 -29.826,37.523 -27.903,38.457C-25.982,39.411 -23.8,39.95 -21.361,40.095C-18.879,40.241 -16.719,40.075 -14.891,39.618C-13.054,39.162 -11.589,38.685 -10.478,38.166L-9.989,29.766L-19.264,29.226L-18.651,18.731L1.589,19.913L0,46.899Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,212.391,-318.984)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-57.988L34.498,-51.03L32.42,-40.708L9.117,-45.402L7.208,-35.931L29.224,-31.507L27.146,-21.164L5.129,-25.609L3.043,-15.287L27.644,-10.323L25.567,0L-10.217,-7.207L0,-57.988Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,281.895,-320.562)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-28.89L-14.02,-33.584L-10.81,-43.158L28.039,-30.157L24.83,-20.582L10.821,-25.276L-2.431,14.268L-13.249,10.654L0,-28.89Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(0.878043,0.478582,0.478582,-0.878043,378.796,-317.575)">
                                <rect
                                    className="getInTouchWheelElement"
                                    x="-13.098"
                                    y="-51.376"
                                    width="11.419"
                                    height="51.806"
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,403.488,-233.187)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-70.636L12.659,-61.664L9.802,-19.565L9.928,-19.482L30.738,-48.849L40.053,-42.245L10.104,0L-2.078,-8.619L0.8,-51.778L0.685,-51.861L-20.645,-21.787L-29.949,-28.371L0,-70.636Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,494.572,-195.428)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-18.838L-9.959,-29.763L-2.503,-36.554L25.122,-6.314L17.665,0.498L7.695,-10.406L-23.095,17.716L-30.792,9.283L0,-18.838Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,513.274,-122.904)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-46.106C3.426,-48.453 6.916,-50.011 10.479,-50.758C14.039,-51.506 17.497,-51.527 20.864,-50.82C24.217,-50.094 27.384,-48.681 30.353,-46.584C33.335,-44.486 35.962,-41.765 38.246,-38.422C40.532,-35.078 42.12,-31.651 43.003,-28.12C43.886,-24.569 44.051,-21.121 43.501,-17.736C42.95,-14.33 41.685,-11.111 39.69,-8.078C37.697,-5.025 34.996,-2.325 31.57,0.022C28.152,2.348 24.664,3.905 21.101,4.653C17.529,5.401 14.071,5.421 10.717,4.715C7.363,3.988 4.196,2.576 1.214,0.478C-1.754,-1.619 -4.383,-4.34 -6.667,-7.684C-8.962,-11.028 -10.54,-14.454 -11.423,-17.985C-12.305,-21.537 -12.471,-24.984 -11.923,-28.369C-11.382,-31.755 -10.104,-34.974 -8.119,-38.027C-6.127,-41.08 -3.418,-43.78 0,-46.106M6.697,-36.324C4.683,-34.953 3.052,-33.375 1.816,-31.609C0.57,-29.844 -0.27,-27.954 -0.695,-25.96C-1.121,-23.946 -1.121,-21.89 -0.685,-19.792C-0.26,-17.694 0.644,-15.638 2.025,-13.624C3.396,-11.609 4.985,-10.031 6.791,-8.867C8.588,-7.705 10.497,-6.957 12.524,-6.645C14.549,-6.313 16.614,-6.396 18.714,-6.915C20.811,-7.434 22.867,-8.39 24.881,-9.761C26.854,-11.111 28.465,-12.689 29.73,-14.475C30.998,-16.24 31.84,-18.13 32.276,-20.145C32.701,-22.16 32.701,-24.216 32.265,-26.313C31.84,-28.411 30.936,-30.467 29.553,-32.482C28.173,-34.476 26.584,-36.075 24.787,-37.238C22.99,-38.401 21.07,-39.148 19.045,-39.46C17.02,-39.792 14.954,-39.688 12.835,-39.148C10.717,-38.629 8.671,-37.674 6.697,-36.324"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,570.204,-110.067)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,50.427C-2.763,51.756 -5.515,52.566 -8.245,52.857C-10.978,53.148 -13.605,52.857 -16.108,52.047C-18.609,51.216 -20.936,49.804 -23.086,47.81C-25.236,45.816 -27.084,43.199 -28.622,40.001C-30.189,36.74 -31.072,33.646 -31.289,30.717C-31.508,27.789 -31.145,25.089 -30.23,22.617C-29.307,20.146 -27.873,17.944 -25.943,15.992C-24.02,14.039 -21.664,12.399 -18.901,11.069L9.791,-2.721L14.734,7.58L-13.562,21.163C-15.017,21.849 -16.242,22.721 -17.25,23.78C-18.267,24.839 -19.015,26.002 -19.513,27.269C-20.024,28.536 -20.241,29.865 -20.19,31.278C-20.136,32.69 -19.783,34.061 -19.13,35.432C-18.475,36.802 -17.625,37.924 -16.566,38.838C-15.507,39.731 -14.333,40.375 -13.025,40.79C-11.725,41.185 -10.345,41.33 -8.901,41.205C-7.446,41.06 -5.993,40.645 -4.539,39.959L23.758,26.376L28.692,36.657L0,50.427Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,607.286,-22.9193)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,14.081C0.966,12.606 1.549,10.986 1.754,9.2C1.963,7.414 1.733,5.503 1.091,3.468C0.447,1.412 -0.551,-0.354 -1.891,-1.849C-3.231,-3.344 -4.819,-4.507 -6.636,-5.338C-8.462,-6.169 -10.468,-6.626 -12.648,-6.73C-14.83,-6.834 -17.053,-6.522 -19.337,-5.816C-21.664,-5.068 -23.677,-4.03 -25.381,-2.7C-27.071,-1.371 -28.443,0.145 -29.462,1.848C-30.479,3.551 -31.123,5.379 -31.372,7.331C-31.631,9.263 -31.444,11.215 -30.832,13.167C-30.126,15.41 -29.035,17.238 -27.561,18.65C-26.087,20.083 -24.33,21.101 -22.305,21.683L-26.211,32.898C-29.794,31.714 -32.794,29.762 -35.204,26.979C-37.614,24.216 -39.358,21.143 -40.428,17.736C-41.653,13.873 -42.172,10.135 -41.995,6.5C-41.808,2.866 -40.968,-0.499 -39.462,-3.594C-37.956,-6.667 -35.806,-9.388 -33.022,-11.714C-30.229,-14.061 -26.866,-15.868 -22.907,-17.114C-18.952,-18.36 -15.161,-18.817 -11.538,-18.527C-7.903,-18.215 -4.59,-17.218 -1.589,-15.557C1.423,-13.895 4.038,-11.631 6.283,-8.765C8.515,-5.899 10.249,-2.534 11.465,1.329C11.912,2.72 12.243,4.216 12.461,5.815C12.68,7.414 12.752,9.034 12.648,10.675C12.554,12.316 12.254,13.956 11.755,15.597C11.246,17.238 10.5,18.796 9.481,20.291L0,14.081Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,629.166,75.2556)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-52.774L1.848,-41.517L-17.208,-38.402L-13.625,-16.511L5.442,-19.647L7.29,-8.39L-43.833,0L-45.67,-11.256L-23.584,-14.891L-27.176,-36.761L-49.264,-33.126L-51.113,-44.404L0,-52.774Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,589.051,134.365)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,46.772C-1.548,43.304 -2.596,39.711 -3.137,36.014C-3.688,32.317 -3.853,28.557 -3.613,24.777C-3.354,20.728 -2.492,17.051 -1.007,13.708C0.469,10.384 2.461,7.56 4.986,5.213C7.497,2.887 10.479,1.121 13.926,-0.042C17.374,-1.225 21.164,-1.682 25.296,-1.433C29.441,-1.163 33.137,-0.229 36.408,1.371C39.69,2.949 42.421,5.068 44.633,7.705C46.834,10.322 48.465,13.396 49.514,16.885C50.573,20.354 50.968,24.134 50.717,28.163C50.458,32.296 49.722,36.076 48.505,39.503C47.291,42.909 45.381,45.858 42.784,48.35L34.518,39.773C36.221,38.298 37.551,36.637 38.505,34.726C39.452,32.836 40.022,30.427 40.199,27.519C40.353,25.068 40.075,22.846 39.369,20.831C38.671,18.796 37.623,17.031 36.221,15.535C34.831,14.019 33.127,12.835 31.132,11.942C29.139,11.07 26.938,10.551 24.56,10.405C22.118,10.26 19.877,10.488 17.81,11.111C15.743,11.755 13.916,12.711 12.346,14.04C10.768,15.348 9.511,16.968 8.556,18.9C7.612,20.811 7.061,22.991 6.906,25.421C6.751,27.914 6.895,30.074 7.342,31.901C7.788,33.75 8.275,35.204 8.786,36.325L17.186,36.844L17.767,27.581L28.278,28.225L27.01,48.454L0,46.772Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,632.677,238.252)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-24.092L-7.063,10.385L-17.384,8.266L-12.619,-15.016L-22.078,-16.947L-26.576,5.047L-36.898,2.929L-32.401,-19.066L-42.723,-21.184L-47.75,3.406L-58.072,1.288L-50.751,-34.477L0,-24.092Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,604.721,298.358)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-14.04L4.755,-28.038L14.309,-24.798L1.121,13.998L-8.432,10.738L-3.677,-3.24L-43.158,-16.678L-39.481,-27.478L0,-14.04Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(-0.482558,0.875864,0.875864,0.482558,534.773,380.911)">
                                <rect
                                    className="getInTouchWheelElement"
                                    x="-31.149"
                                    y="-18.395"
                                    width="11.418"
                                    height="51.806"
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,558.955,414.853)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-9.866L-9.002,2.762L-51.101,-0.229L-51.184,-0.104L-21.88,20.81L-28.504,30.094L-70.676,0.02L-62.005,-12.13L-18.858,-9.118L-18.775,-9.243L-48.796,-30.656L-42.171,-39.96L0,-9.866Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,468.827,490.203)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,5.836L10.957,-4.092L17.738,3.406L-12.626,30.904L-19.406,23.427L-8.452,13.5L-36.459,-17.405L-27.997,-25.068L0,5.836Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,423.842,545.822)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-31.423C2.337,-27.996 3.872,-24.507 4.611,-20.935C5.359,-17.383 5.359,-13.915 4.632,-10.571C3.905,-7.206 2.492,-4.049 0.384,-1.079C-1.734,1.891 -4.456,4.508 -7.81,6.792C-11.153,9.056 -14.601,10.634 -18.131,11.507C-21.672,12.379 -25.13,12.545 -28.516,11.985C-31.912,11.424 -35.122,10.136 -38.163,8.142C-41.206,6.128 -43.894,3.428 -46.221,0.001C-48.548,-3.426 -50.085,-6.936 -50.832,-10.488C-51.57,-14.06 -51.581,-17.528 -50.853,-20.872C-50.126,-24.237 -48.714,-27.394 -46.596,-30.364C-44.487,-33.334 -41.757,-35.951 -38.412,-38.235C-35.069,-40.499 -31.621,-42.078 -28.09,-42.95C-24.549,-43.822 -21.091,-43.968 -17.706,-43.407C-14.309,-42.867 -11.102,-41.579 -8.058,-39.585C-5.015,-37.571 -2.327,-34.871 0,-31.423M-9.802,-24.777C-11.174,-26.791 -12.742,-28.411 -14.496,-29.658C-16.262,-30.925 -18.152,-31.755 -20.156,-32.191C-22.161,-32.628 -24.218,-32.628 -26.315,-32.212C-28.412,-31.776 -30.468,-30.883 -32.493,-29.512C-34.507,-28.141 -36.098,-26.563 -37.259,-24.756C-38.433,-22.97 -39.182,-21.059 -39.513,-19.024C-39.845,-17.009 -39.763,-14.932 -39.243,-12.835C-38.735,-10.737 -37.789,-8.681 -36.419,-6.666C-35.079,-4.693 -33.512,-3.073 -31.736,-1.806C-29.96,-0.539 -28.07,0.312 -26.066,0.748C-24.06,1.185 -22.006,1.185 -19.907,0.769C-17.81,0.354 -15.743,-0.56 -13.728,-1.931C-11.714,-3.302 -10.126,-4.88 -8.951,-6.666C-7.788,-8.473 -7.04,-10.384 -6.708,-12.398C-6.376,-14.434 -6.47,-16.511 -6.999,-18.629C-7.518,-20.748 -8.452,-22.783 -9.802,-24.777"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,314.265,568.295)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,2.679C-1.329,-0.083 -2.118,-2.846 -2.399,-5.566C-2.669,-8.308 -2.388,-10.925 -1.559,-13.417C-0.728,-15.93 0.695,-18.256 2.699,-20.396C4.704,-22.535 7.309,-24.383 10.529,-25.899C13.79,-27.457 16.874,-28.329 19.802,-28.537C22.731,-28.745 25.432,-28.392 27.903,-27.457C30.364,-26.522 32.576,-25.089 34.528,-23.158C36.47,-21.226 38.111,-18.859 39.43,-16.096L53.116,12.648L42.805,17.55L29.315,-10.779C28.62,-12.233 27.748,-13.479 26.697,-14.476C25.649,-15.494 24.496,-16.262 23.231,-16.761C21.963,-17.259 20.623,-17.488 19.211,-17.446C17.81,-17.405 16.416,-17.052 15.058,-16.387C13.686,-15.743 12.554,-14.892 11.65,-13.832C10.746,-12.794 10.093,-11.61 9.688,-10.322C9.283,-9.014 9.138,-7.643 9.262,-6.189C9.388,-4.736 9.791,-3.282 10.487,-1.828L23.988,26.522L13.675,31.424L0,2.679Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,262.58,578.741)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,29.368C1.474,30.344 3.105,30.926 4.881,31.154C6.657,31.362 8.578,31.154 10.623,30.51C12.669,29.866 14.445,28.89 15.951,27.561C17.446,26.211 18.62,24.633 19.449,22.826C20.291,20.998 20.77,19.004 20.883,16.823C20.998,14.643 20.696,12.42 19.99,10.136C19.265,7.81 18.246,5.774 16.917,4.071C15.598,2.368 14.081,0.997 12.389,-0.041C10.685,-1.059 8.868,-1.703 6.916,-1.973C4.974,-2.243 3.022,-2.056 1.07,-1.453C-1.174,-0.747 -3.001,0.333 -4.434,1.787C-5.867,3.261 -6.884,5.006 -7.489,7.02L-18.671,3.074C-17.488,-0.519 -15.504,-3.51 -12.731,-5.898C-9.949,-8.286 -6.865,-10.031 -3.458,-11.07C0.404,-12.274 4.154,-12.773 7.788,-12.586C11.433,-12.399 14.788,-11.526 17.861,-10.01C20.946,-8.494 23.646,-6.334 25.972,-3.53C28.299,-0.726 30.074,2.659 31.31,6.605C32.535,10.572 32.992,14.373 32.659,17.987C32.338,21.621 31.33,24.923 29.658,27.935C27.986,30.926 25.713,33.543 22.825,35.765C19.938,37.987 16.574,39.69 12.701,40.895C11.308,41.331 9.813,41.663 8.214,41.871C6.625,42.079 5.004,42.141 3.365,42.037C1.725,41.933 0.083,41.622 -1.556,41.123C-3.198,40.604 -4.766,39.836 -6.25,38.818L0,29.368Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,231.53,585.762)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,44.051L-11.268,45.858L-14.32,26.771L-36.21,30.281L-33.156,49.347L-44.424,51.154L-52.608,0L-41.34,-1.787L-37.799,20.312L-15.909,16.802L-19.45,-5.297L-8.182,-7.103L0,44.051Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,72.9877,636.317)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-47.236C3.469,-48.772 7.063,-49.789 10.76,-50.328C14.466,-50.868 18.216,-51.013 22.006,-50.764C26.047,-50.494 29.732,-49.602 33.046,-48.108C36.368,-46.634 39.193,-44.621 41.519,-42.089C43.846,-39.578 45.59,-36.589 46.753,-33.144C47.916,-29.699 48.363,-25.901 48.082,-21.771C47.812,-17.641 46.868,-13.926 45.258,-10.668C43.648,-7.409 41.519,-4.67 38.882,-2.491C36.242,-0.291 33.18,1.328 29.69,2.366C26.191,3.403 22.431,3.777 18.392,3.507C14.261,3.237 10.479,2.49 7.063,1.266C3.656,0.021 0.717,-1.889 -1.754,-4.504L6.866,-12.722C8.31,-11 9.981,-9.672 11.872,-8.717C13.761,-7.762 16.17,-7.181 19.088,-6.994C21.53,-6.828 23.75,-7.098 25.776,-7.783C27.801,-8.489 29.577,-9.526 31.083,-10.917C32.599,-12.307 33.793,-13.988 34.686,-15.981C35.579,-17.973 36.098,-20.173 36.253,-22.56C36.419,-24.988 36.192,-27.229 35.568,-29.305C34.956,-31.38 33.99,-33.186 32.672,-34.763C31.364,-36.34 29.743,-37.606 27.831,-38.582C25.911,-39.536 23.742,-40.097 21.31,-40.263C18.829,-40.408 16.668,-40.283 14.83,-39.848C12.992,-39.412 11.516,-38.934 10.407,-38.416L9.845,-30.031L19.12,-29.408L18.414,-18.907L-1.806,-20.256L0,-47.236Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,39.8317,574.38)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,58.154L-34.447,50.968L-32.297,40.666L-9.015,45.506L-7.041,36.056L-29.037,31.466L-26.876,21.164L-4.892,25.754L-2.741,15.432L-27.312,10.302L-25.153,0L10.581,7.456L0,58.154Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-29.7878,575.668)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,28.807L13.98,33.605L10.698,43.158L-28.058,29.845L-24.777,20.292L-10.799,25.089L2.752,-14.351L13.541,-10.634L0,28.807Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(-0.874095,-0.485754,-0.485754,0.874095,-88.5854,534.615)">
                                <rect
                                    className="getInTouchWheelElement"
                                    x="1.867"
                                    y="-0.502"
                                    width="11.434"
                                    height="51.808"
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-150.954,487.191)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,70.74L-12.586,61.664L-9.419,19.586L-9.534,19.502L-30.572,48.704L-39.836,42.037L-9.564,0L2.555,8.723L-0.655,51.861L-0.529,51.944L21.017,22.036L30.27,28.703L0,70.74Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-241.113,449.287)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,18.651L9.885,29.617L2.388,36.388L-25.036,5.94L-17.529,-0.81L-7.644,10.156L23.345,-17.737L30.987,-9.263L0,18.651Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-259.319,376.596)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,46.315C-3.426,48.641 -6.935,50.157 -10.497,50.884C-14.07,51.632 -17.529,51.611 -20.882,50.884C-24.226,50.137 -27.384,48.724 -30.353,46.606C-33.313,44.487 -35.929,41.746 -38.194,38.381C-40.457,35.037 -42.024,31.59 -42.877,28.038C-43.738,24.507 -43.883,21.039 -43.324,17.654C-42.752,14.268 -41.465,11.07 -39.449,8.037C-37.445,4.984 -34.715,2.305 -31.286,0C-27.849,-2.326 -24.351,-3.842 -20.778,-4.59C-17.216,-5.317 -13.758,-5.317 -10.404,-4.569C-7.05,-3.842 -3.894,-2.409 -0.933,-0.291C2.035,1.828 4.643,4.569 6.908,7.913C9.181,11.277 10.738,14.725 11.602,18.256C12.463,21.787 12.608,25.255 12.038,28.641C11.476,32.026 10.187,35.245 8.173,38.277C6.159,41.31 3.439,43.989 0,46.315M-6.635,36.491C-4.611,35.12 -2.969,33.563 -1.723,31.797C-0.465,30.053 0.386,28.163 0.821,26.148C1.268,24.154 1.279,22.098 0.864,20.001C0.439,17.903 -0.455,15.847 -1.816,13.811C-3.188,11.797 -4.766,10.198 -6.552,9.034C-8.347,7.851 -10.259,7.103 -12.284,6.75C-14.309,6.417 -16.365,6.501 -18.473,6.999C-20.57,7.518 -22.626,8.453 -24.651,9.824C-26.625,11.153 -28.256,12.711 -29.532,14.476C-30.81,16.262 -31.661,18.152 -32.107,20.146C-32.543,22.161 -32.554,24.196 -32.139,26.314C-31.725,28.412 -30.831,30.468 -29.46,32.483C-28.098,34.518 -26.52,36.117 -24.726,37.28C-22.928,38.444 -21.027,39.212 -19.002,39.544C-16.977,39.897 -14.9,39.794 -12.781,39.274C-10.663,38.755 -8.607,37.82 -6.635,36.491"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-315.582,363.578)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-50.36C2.784,-51.666 5.536,-52.454 8.277,-52.724C11.008,-52.993 13.626,-52.703 16.127,-51.853C18.62,-51.023 20.946,-49.593 23.086,-47.582C25.214,-45.592 27.052,-42.979 28.569,-39.766C30.117,-36.511 30.978,-33.401 31.176,-30.498C31.372,-27.575 31,-24.88 30.064,-22.412C29.13,-19.945 27.686,-17.748 25.744,-15.819C23.803,-13.87 21.444,-12.253 18.662,-10.926L-10.113,2.633L-14.986,-7.671L13.396,-21.044C14.851,-21.728 16.086,-22.599 17.103,-23.656C18.111,-24.693 18.879,-25.854 19.389,-27.098C19.897,-28.363 20.125,-29.71 20.085,-31.12C20.032,-32.509 19.69,-33.898 19.045,-35.267C18.403,-36.635 17.55,-37.775 16.502,-38.667C15.453,-39.579 14.279,-40.242 12.981,-40.636C11.685,-41.051 10.302,-41.196 8.85,-41.072C7.394,-40.968 5.94,-40.553 4.487,-39.869L-23.894,-26.476L-28.765,-36.78L0,-50.36Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-352.56,275.762)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-14.456C-0.965,-12.981 -1.569,-11.341 -1.787,-9.575C-2.005,-7.789 -1.797,-5.878 -1.174,-3.822C-0.54,-1.787 0.447,-0.001 1.776,1.495C3.105,3.011 4.672,4.174 6.488,5.025C8.307,5.877 10.3,6.355 12.482,6.479C14.662,6.604 16.895,6.313 19.179,5.607C21.516,4.88 23.541,3.862 25.245,2.554C26.956,1.225 28.339,-0.271 29.366,-1.974C30.407,-3.677 31.06,-5.484 31.319,-7.436C31.589,-9.388 31.423,-11.341 30.821,-13.293C30.136,-15.536 29.066,-17.364 27.601,-18.797C26.138,-20.23 24.392,-21.268 22.367,-21.871L26.355,-33.044C29.938,-31.84 32.918,-29.846 35.306,-27.063C37.695,-24.28 39.419,-21.185 40.468,-17.779C41.653,-13.916 42.14,-10.157 41.931,-6.522C41.725,-2.888 40.864,0.456 39.326,3.53C37.799,6.604 35.63,9.304 32.824,11.63C30.011,13.935 26.625,15.701 22.669,16.926C18.703,18.152 14.911,18.588 11.287,18.255C7.663,17.902 4.352,16.905 1.359,15.223C-1.631,13.541 -4.239,11.256 -6.451,8.349C-8.67,5.462 -10.374,2.097 -11.559,-1.787C-11.995,-3.178 -12.316,-4.674 -12.524,-6.273C-12.733,-7.872 -12.784,-9.492 -12.669,-11.112C-12.567,-12.753 -12.254,-14.394 -11.735,-16.034C-11.217,-17.675 -10.446,-19.233 -9.43,-20.728L0,-14.456Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-374.223,177.316)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,52.442L-1.755,41.164L17.333,38.194L13.915,16.283L-5.173,19.253L-6.927,7.975L44.249,0L46.004,11.278L23.885,14.725L27.301,36.637L49.419,33.168L51.187,44.446L0,52.442Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-332.903,118.081)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-46.359C1.517,-42.876 2.544,-39.289 3.063,-35.599C3.582,-31.908 3.718,-28.156 3.448,-24.361C3.167,-20.339 2.274,-16.67 0.779,-13.352C-0.727,-10.035 -2.741,-7.236 -5.276,-4.914C-7.809,-2.613 -10.799,-0.871 -14.257,0.269C-17.715,1.43 -21.505,1.866 -25.639,1.575C-29.773,1.285 -33.479,0.331 -36.742,-1.286C-40.002,-2.903 -42.722,-5.038 -44.902,-7.671C-47.095,-10.325 -48.705,-13.373 -49.732,-16.877C-50.759,-20.36 -51.134,-24.113 -50.842,-28.156C-50.564,-32.281 -49.793,-36.034 -48.558,-39.455C-47.311,-42.855 -45.391,-45.778 -42.773,-48.225L-34.57,-39.6C-36.284,-38.17 -37.624,-36.511 -38.59,-34.624C-39.555,-32.738 -40.136,-30.333 -40.334,-27.409C-40.51,-24.983 -40.251,-22.765 -39.555,-20.733C-38.871,-18.722 -37.83,-16.939 -36.45,-15.426C-35.06,-13.912 -33.377,-12.71 -31.382,-11.818C-29.39,-10.927 -27.197,-10.387 -24.819,-10.222C-22.39,-10.056 -20.136,-10.284 -18.058,-10.906C-15.993,-11.507 -14.164,-12.461 -12.575,-13.767C-10.997,-15.073 -9.719,-16.69 -8.754,-18.598C-7.799,-20.505 -7.228,-22.682 -7.06,-25.108C-6.884,-27.575 -7.02,-29.731 -7.446,-31.576C-7.881,-33.401 -8.36,-34.873 -8.868,-35.993L-17.258,-36.573L-17.903,-27.326L-28.413,-28.052L-26.999,-48.225L0,-46.359Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-376.226,14.4234)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,23.635L7.32,-10.779L17.622,-8.599L12.68,14.663L22.119,16.677L26.801,-5.296L37.103,-3.095L32.431,18.879L42.733,21.06L47.957,-3.49L58.256,-1.288L50.655,34.414L0,23.635Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-347.991,-45.1425)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,14.102L-4.85,28.059L-14.384,24.757L-0.955,-13.957L8.587,-10.634L3.738,3.323L43.137,16.989L39.4,27.768L0,14.102Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(0.487494,-0.873127,-0.873127,-0.487494,-315.502,-90.2064)">
                                <rect
                                    className="getInTouchWheelElement"
                                    x="19.668"
                                    y="-33.554"
                                    width="11.445"
                                    height="51.8"
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-301.105,-161.388)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,9.346L9.096,-3.219L51.165,0.083L51.26,-0.042L22.11,-21.164L28.807,-30.406L70.751,0L61.987,12.088L18.869,8.744L18.786,8.868L48.641,30.51L41.944,39.752L0,9.346Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-210.862,-235.845)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-6.086L-11.008,3.779L-17.748,-3.739L12.782,-31.071L19.511,-23.553L8.505,-13.708L36.325,17.362L27.82,24.985L0,-6.086Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-165.743,-291.112)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,31.154C-2.305,27.706 -3.82,24.196 -4.547,20.624C-5.264,17.072 -5.253,13.604 -4.506,10.26C-3.758,6.896 -2.324,3.759 -0.195,0.789C1.933,-2.16 4.675,-4.756 8.04,-7.02C11.404,-9.284 14.851,-10.82 18.392,-11.672C21.934,-12.523 25.402,-12.669 28.778,-12.087C32.164,-11.506 35.37,-10.218 38.403,-8.183C41.436,-6.168 44.105,-3.447 46.411,0C48.726,3.448 50.243,6.958 50.957,10.509C51.685,14.082 51.663,17.55 50.917,20.894C50.169,24.259 48.737,27.395 46.609,30.365C44.48,33.314 41.736,35.91 38.371,38.174C35.006,40.438 31.559,41.975 28.018,42.826C24.477,43.678 21.019,43.823 17.633,43.242C14.25,42.66 11.04,41.372 8.008,39.337C4.985,37.322 2.316,34.602 0,31.154M9.845,24.549C11.206,26.564 12.755,28.205 14.52,29.472C16.274,30.739 18.154,31.59 20.158,32.026C22.161,32.483 24.207,32.504 26.317,32.089C28.414,31.673 30.481,30.78 32.506,29.43C34.53,28.059 36.13,26.502 37.304,24.695C38.487,22.909 39.245,21.019 39.588,18.983C39.941,16.969 39.858,14.892 39.358,12.794C38.86,10.696 37.927,8.64 36.576,6.605C35.236,4.632 33.688,2.991 31.923,1.703C30.147,0.436 28.267,-0.436 26.264,-0.872C24.26,-1.329 22.203,-1.35 20.107,-0.934C17.997,-0.519 15.932,0.374 13.908,1.724C11.881,3.095 10.281,4.653 9.109,6.439C7.925,8.246 7.167,10.136 6.823,12.171C6.47,14.186 6.564,16.263 7.074,18.381C7.582,20.499 8.505,22.556 9.845,24.549"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-55.8005,-313.418)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-2.492C1.308,0.291 2.087,3.053 2.346,5.774C2.606,8.516 2.306,11.133 1.464,13.625C0.613,16.138 -0.82,18.443 -2.834,20.583C-4.859,22.701 -7.477,24.529 -10.706,26.045C-13.969,27.561 -17.074,28.433 -19.999,28.62C-22.928,28.807 -25.619,28.433 -28.079,27.478C-30.55,26.543 -32.754,25.09 -34.685,23.137C-36.616,21.185 -38.237,18.838 -39.544,16.055L-53.053,-12.773L-42.722,-17.612L-29.397,10.8C-28.713,12.254 -27.851,13.5 -26.803,14.518C-25.763,15.536 -24.61,16.304 -23.345,16.823C-22.088,17.322 -20.748,17.571 -19.336,17.529C-17.935,17.488 -16.544,17.135 -15.172,16.491C-13.8,15.868 -12.669,15.016 -11.757,13.978C-10.842,12.919 -10.187,11.756 -9.772,10.447C-9.357,9.159 -9.2,7.768 -9.315,6.314C-9.44,4.86 -9.834,3.406 -10.518,1.953L-23.843,-26.46L-13.511,-31.299L0,-2.492Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,-3.84514,-323.491)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-29.271C-1.476,-30.267 -3.097,-30.868 -4.873,-31.076C-6.657,-31.304 -8.569,-31.117 -10.625,-30.474C-12.672,-29.852 -14.456,-28.877 -15.962,-27.57C-17.467,-26.242 -18.652,-24.666 -19.503,-22.861C-20.356,-21.056 -20.842,-19.044 -20.968,-16.886C-21.091,-14.708 -20.813,-12.468 -20.115,-10.186C-19.401,-7.862 -18.392,-5.829 -17.085,-4.107C-15.766,-2.406 -14.268,-1.016 -12.578,0.021C-10.885,1.058 -9.067,1.701 -7.114,1.992C-5.173,2.262 -3.221,2.096 -1.257,1.494C0.976,0.83 2.824,-0.249 4.266,-1.701C5.7,-3.153 6.729,-4.896 7.341,-6.908L18.505,-2.904C17.299,0.664 15.295,3.651 12.5,6.016C9.708,8.402 6.614,10.103 3.207,11.14C-0.666,12.323 -4.426,12.8 -8.061,12.572C-11.696,12.364 -15.049,11.493 -18.112,9.937C-21.185,8.402 -23.865,6.224 -26.181,3.423C-28.486,0.602 -30.252,-2.78 -31.457,-6.742C-32.661,-10.704 -33.087,-14.5 -32.733,-18.11C-32.391,-21.741 -31.364,-25.039 -29.671,-28.026C-27.988,-30.993 -25.694,-33.586 -22.795,-35.806C-19.898,-38.005 -16.513,-39.685 -12.639,-40.868C-11.238,-41.303 -9.743,-41.614 -8.143,-41.822C-6.555,-42.029 -4.934,-42.071 -3.293,-41.946C-1.652,-41.843 -0.011,-41.531 1.618,-41.013C3.26,-40.473 4.817,-39.706 6.301,-38.689L0,-29.271Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                            <g transform="matrix(1,0,0,1,27.7863,-329.909)">
                                <path
                                    className="getInTouchWheelElement"
                                    d="M0,-44.425L11.287,-46.149L14.194,-27.041L36.116,-30.406L33.198,-49.493L44.477,-51.217L52.295,0L41.018,1.724L37.632,-20.416L15.721,-17.072L19.106,5.068L7.818,6.792L0,-44.425Z"
                                    style={{ fill: "white" }}
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    );
};

/* Paper Plane
============================================================================= */

const PaperPlane = () => {
    // Config

    // Render
    return (
        <div id="paperPlaneContainer">
            <svg
                id="paper-plane"
                width="100%"
                height="100%"
                viewBox="0 0 343 290"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeMiterlimit: 10,
                    fill: "rgb(249,245,237)",
                }}
            >
                <g transform="matrix(1,0,0,1,-145983,-79818.9)">
                    <g transform="matrix(2,0,0,2,142785,78105.6)">
                        <g transform="matrix(1,0,0,1,1770.44,1001.21)">
                            <path
                                d="M0,-144.578L-43.623,-7.477L-73.535,-23.681L-93.478,0L-133.361,-51.1L-171.375,-55.463L0,-144.578Z"
                                style={{
                                    fill: "rgb(249,245,237)",
                                    fillRule: "nonzero",
                                }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};

/* Page Query
============================================================================= */

export default IndexPage;
