import React from "react";

export default class Bonfire extends React.Component {
    render() {
        return (
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 907 666"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 2,
                }}
            >
                <g transform="matrix(1,0,0,1,-4309,-9793.2)">
                    <g>
                        <path
                            d="M4399.7,10246C4399.7,10046 4562,9883.8 4762.1,9883.8C4962.3,9883.8 5124.6,10046 5124.6,10246L5215.2,10246C5215.2,9995.9 5012.3,9793.2 4762.1,9793.2C4511.9,9793.2 4309,9995.9 4309,10246L4399.7,10246Z"
                            style={{ fill: "white" }}
                        />
                        <path
                            d="M4580.9,10246C4580.9,10146 4662,10064.9 4762.1,10064.9C4862.2,10064.9 4943.4,10146 4943.4,10246L5034,10246C5034,10096 4912.3,9974.3 4762.1,9974.3C4612,9974.3 4490.3,10095.9 4490.3,10246L4580.9,10246Z"
                            style={{ fill: "white" }}
                        />
                        <path
                            d="M4671.5,10246C4671.5,10196 4712.1,10155.4 4762.1,10155.4C4812.1,10155.4 4852.7,10195.9 4852.7,10246L4671.5,10246Z"
                            style={{ fill: "white" }}
                        />
                        <g>
                            <path
                                d="M4309.8,10317.5L4381,10317.5C4398.4,10317.5 4410.7,10321.9 4418.9,10330C4424.4,10335.5 4427.8,10342.9 4427.8,10352.4L4427.8,10352.8C4427.8,10368.8 4418.7,10378.4 4406.4,10383.7C4423.2,10389.2 4433.9,10398.8 4433.9,10417.6L4433.9,10418C4433.9,10441.8 4414.5,10456.2 4381.4,10456.2L4309.8,10456.2L4309.8,10317.5ZM4371.4,10372.8C4383.1,10372.8 4389.6,10368.8 4389.6,10360.7L4389.6,10360.3C4389.6,10352.8 4383.7,10348.4 4372.2,10348.4L4347.2,10348.4L4347.2,10372.8L4371.4,10372.8ZM4376.9,10425.3C4388.6,10425.3 4395.3,10420.7 4395.3,10412.6L4395.3,10412.2C4395.3,10404.7 4389.4,10399.7 4376.5,10399.7L4347.2,10399.7L4347.2,10425.3L4376.9,10425.3Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M4446.7,10387.2L4446.7,10386.8C4446.7,10347 4478.8,10314.7 4521.6,10314.7C4564.4,10314.7 4596.1,10346.6 4596.1,10386.4L4596.1,10386.8C4596.1,10426.6 4564,10458.9 4521.2,10458.9C4478.4,10458.9 4446.7,10427.1 4446.7,10387.2ZM4556.9,10387.2L4556.9,10386.8C4556.9,10366.8 4542.4,10349.4 4521.2,10349.4C4500.2,10349.4 4486.1,10366.4 4486.1,10386.4L4486.1,10386.8C4486.1,10406.8 4500.6,10424.2 4521.6,10424.2C4542.8,10424.3 4556.9,10407.2 4556.9,10387.2Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M4616.2,10317.5L4652.1,10317.5L4709.2,10390.8L4709.2,10317.5L4747.3,10317.5L4747.3,10456.2L4713.6,10456.2L4654.3,10380.1L4654.3,10456.2L4616.2,10456.2L4616.2,10317.5Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M4769.8,10317.5L4880.8,10317.5L4880.8,10351.2L4808.3,10351.2L4808.3,10374.8L4873.9,10374.8L4873.9,10406.7L4808.3,10406.7L4808.3,10456.2L4769.8,10456.2L4769.8,10317.5Z"
                                style={{ fill: "white" }}
                            />
                            <rect
                                x="4899"
                                y="10317.5"
                                width="38.6"
                                height="138.7"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M4958.1,10317.5L5023.7,10317.5C5044.9,10317.5 5059.6,10323 5068.9,10332.6C5077,10340.5 5081.2,10351.2 5081.2,10364.9L5081.2,10365.3C5081.2,10386.5 5069.9,10400.6 5052.7,10407.9L5085.8,10456.2L5041.4,10456.2L5013.5,10414.2L4996.6,10414.2L4996.6,10456.2L4958.1,10456.2L4958.1,10317.5ZM5021.9,10384.1C5035,10384.1 5042.5,10377.8 5042.5,10367.7L5042.5,10367.3C5042.5,10356.4 5034.6,10350.9 5021.7,10350.9L4996.5,10350.9L4996.5,10384.2L5021.9,10384.2L5021.9,10384.1Z"
                                style={{ fill: "white" }}
                            />
                            <path
                                d="M5100.6,10317.5L5212.2,10317.5L5212.2,10350.2L5138.7,10350.2L5138.7,10371.2L5205.3,10371.2L5205.3,10401.5L5138.7,10401.5L5138.7,10423.5L5213.2,10423.5L5213.2,10456.2L5100.6,10456.2L5100.6,10317.5Z"
                                style={{ fill: "white" }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
