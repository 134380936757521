import React from "react";

export default class Isobar extends React.Component {
    render() {
        return (
            <svg
                id="l15"
                width="100%"
                height="100%"
                viewBox="0 0 763 222"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                space="preserve"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 1.41421,
                }}
            >
                <g transform="matrix(1,0,0,1,-13546.9,-20358.2)">
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1256.52,580.69)">
                            <path
                                d="M0,37.549L0,0.724L8.4,0.777L8.4,4.559L8.364,5.642C8.83,3.706 11.938,0.409 17.659,0L17.659,8.847C13.47,9.109 8.877,10.346 8.729,16.702L8.714,16.702L8.714,37.549L0,37.549Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1157.78,580.812)">
                            <path
                                d="M0,38.195C-10.53,38.195 -19.098,29.628 -19.098,19.097C-19.098,8.566 -10.53,0 0,0C10.53,0 19.098,8.566 19.098,19.097C19.098,29.628 10.531,38.195 0,38.195M0.295,8.373C-5.619,8.373 -10.43,13.183 -10.43,19.097C-10.43,25.011 -5.619,29.822 0.295,29.822C6.208,29.822 11.018,25.011 11.018,19.097C11.018,13.183 6.208,8.373 0.295,8.373"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1198.5,565.972)">
                            <path
                                d="M0,53.046C-4.412,52.973 -7.406,52.028 -10.472,49.102L-10.403,49.059L-10.614,52.296L-18.642,52.296L-18.642,8.688L-9.929,0L-9.956,18.686L-9.939,18.635C-7.667,16.446 -3.992,14.847 0.189,14.851L0.281,14.851C9.921,14.851 18.064,23.597 18.064,33.948C18.064,38.94 16.079,43.73 12.475,47.436C9.034,50.974 4.554,53.019 0.182,53.046L0,53.046ZM-1.033,23.224C-4.539,23.224 -7.832,24.946 -9.84,27.832L-9.928,27.958L-9.928,39.938L-9.84,40.065C-7.832,42.95 -4.539,44.673 -1.033,44.673C4.88,44.673 9.691,39.862 9.691,33.948C9.691,28.034 4.881,23.224 -1.033,23.224"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1231.41,580.825)">
                            <path
                                d="M0,37.912C-7.905,37.912 -13.426,33.218 -13.426,26.497C-13.426,18.91 -7.519,14.899 3.656,14.899L11.711,14.899L11.711,13.517C11.711,10.321 8.805,7.016 3.94,7.016C0.454,7.019 -2.57,8.059 -4.297,9.82L-9.014,5.102C-8.469,4.527 -7.865,3.991 -7.215,3.505C-4.149,1.212 -0.168,0 4.296,0C13.951,0 20.19,5.306 20.19,13.516L20.19,37.455L11.867,37.455L11.86,33.929L11.026,34.765C8.103,37.693 4.763,37.912 0,37.912M3.879,21.181C-1.671,21.181 -4.486,22.89 -4.486,26.262C-4.486,29.188 -1.968,31.078 1.927,31.078C7.871,31.078 11.711,27.969 11.711,23.157L11.711,21.181L3.879,21.181Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,0,119.731)">
                            <rect
                                x="1091.8"
                                y="461.723"
                                width="8.543"
                                height="36.823"
                                style={{ fill: "white" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1096.03,567.009)">
                            <path
                                d="M0,9.543C-2.631,9.543 -4.771,7.403 -4.771,4.772C-4.771,2.141 -2.631,0 0,0C2.631,0 4.772,2.14 4.772,4.772C4.772,7.403 2.631,9.543 0,9.543"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1108.6,591.958)">
                            <path
                                d="M0,15.946C3.491,18.562 7.059,19.725 12.096,19.725C17.819,19.725 20.407,18.021 20.407,15.501C20.407,12.844 17.887,11.686 10.938,11.073C1.809,10.255 -4.255,7.667 -4.255,-0.1C-4.255,-6.776 2.218,-11.136 11.142,-11.136C17.137,-11.136 22.724,-9.093 27.084,-5.482C27.137,-5.437 27.184,-5.391 27.225,-5.347L22.18,-0.177C19.154,-2.495 15.297,-3.779 10.734,-3.779C6.101,-3.779 3.581,-2.416 3.581,-0.372C3.581,1.808 5.624,2.898 11.824,3.511C21.362,4.464 28.242,7.53 28.242,15.365C28.242,22.791 21.293,27.082 11.96,27.082C5.215,27.082 -0.166,25.447 -5.072,21.36C-5.12,21.32 -5.162,21.277 -5.199,21.232L0,15.946Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
