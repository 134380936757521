import React from "react";

export default class FringeWorld extends React.Component {
    render() {
        return (
            <svg
                id="l12"
                width="100%"
                height="100%"
                viewBox="0 0 640 258"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                space="preserve"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 1.41421,
                }}
            >
                <g transform="matrix(1,0,0,1,-15610.4,-19713.3)">
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1599.8,446.379)">
                            <path
                                d="M0,26.723C-3.333,18.85 -6.665,10.977 -10.105,2.851C-7.639,1.946 -5.476,2.296 -3.204,2.179C-1.273,7.026 0.596,11.719 2.466,16.413C4.765,11.451 5.585,6.301 7.467,1.442C9.512,1.138 11.431,1.008 13.541,1.224C15.542,5.941 17.522,10.611 19.503,15.281C19.722,15.296 19.942,15.312 20.162,15.327C21.43,10.493 22.699,5.659 24.001,0.697C26.126,0 28.198,0.227 30.844,0.429C28.835,8.841 26.16,16.823 24.012,24.985C21.705,25.169 19.693,25.33 17.428,25.511C15.393,20.656 13.401,15.905 11.409,11.153C11.223,11.15 11.037,11.148 10.851,11.146C9.016,15.884 8.232,20.965 6.611,25.692C4.913,26.548 3.043,25.747 1.535,26.723L0,26.723Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1663.76,436.517)">
                            <path
                                d="M0,-25.328C3.393,-20.681 6.785,-16.035 10.178,-11.388C11.304,-15.833 10.504,-20.237 11.304,-24.617C13.671,-25.089 15.83,-24.975 18.151,-24.565L18.151,-0.668C15.579,0 13.297,-0.437 10.808,-0.355C7.391,-4.764 3.934,-9.225 0.477,-13.685C0.279,-13.614 0.081,-13.544 -0.117,-13.473L-0.117,-0.708C-2.642,-0.167 -4.867,-0.311 -6.914,-0.525C-7.707,-1.372 -7.52,-2.176 -7.524,-2.919C-7.56,-9.477 -7.568,-16.034 -7.602,-22.591C-7.607,-23.535 -7.553,-24.455 -7.163,-25.328L0,-25.328Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1702.65,439.283)">
                            <path
                                d="M0,-28.094C1.692,-27.165 3.685,-27.568 5.428,-26.871C6.442,-26.465 7.597,-26.385 8.522,-25.486C8.684,-23.64 7.589,-22.217 6.784,-20.754C5.643,-20.393 4.882,-21.104 4.025,-21.392C0.859,-22.455 -2.197,-22.335 -5.02,-20.446C-9.531,-17.426 -9.248,-11.259 -4.463,-8.709C-1.625,-7.197 1.364,-7.038 4.432,-8.167C4.972,-9.069 4.829,-9.962 4.725,-10.875C3.198,-11.677 1.561,-10.989 0.016,-11.633C-0.576,-13.237 -0.615,-14.827 0.401,-16.631C3.981,-16.435 7.585,-16.237 11.404,-16.027C11.887,-12.01 10.869,-8.263 11.081,-4.604C3.544,0 -7.61,-0.535 -13.25,-7.648C-16.657,-11.943 -16.708,-17.436 -13.354,-21.79C-11.023,-24.815 -7.893,-26.597 -4.204,-27.431C-3.464,-27.599 -2.665,-27.542 -2.047,-28.094L0,-28.094Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1740,435.386)">
                            <path
                                d="M0,-19.081C-0.841,-18.087 -0.315,-16.74 -1.07,-15.288C-4.813,-15.504 -8.739,-15.73 -12.571,-15.95C-13.427,-14.829 -13.427,-13.771 -13.127,-12.531C-10.421,-11.166 -7.248,-12.412 -4.461,-11.279C-4.021,-9.766 -4.13,-8.364 -4.524,-6.732C-7.433,-5.917 -10.391,-7.535 -13.358,-6.706C-14.057,-5.676 -13.995,-4.548 -13.679,-3.332C-9.652,-2.188 -5.465,-2.985 -1.406,-1.87C-0.663,-0.265 -1.324,1.398 -1.164,3.483C-8.057,3.406 -14.672,2.752 -21.777,2.223C-21.33,-5.843 -20.892,-13.76 -20.454,-21.66C-18.317,-22.564 -16.331,-22.116 -14.447,-21.96C-9.629,-21.559 -4.751,-21.736 0,-20.616L0,-19.081Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1586.5,437.338)">
                            <path
                                d="M0,-22.568C1.619,-23.2 3.353,-23.102 5.024,-23.204C10.003,-23.509 14.971,-24.244 20.101,-23.945C20.559,-22.101 20.761,-20.393 20.621,-18.35C16.457,-18.01 12.45,-17.684 8.466,-17.359C7.856,-15.405 8.429,-13.805 8.777,-12.011C11.702,-11.972 14.56,-12.468 17.448,-12.149C18.062,-10.641 17.918,-9.23 17.847,-7.738C15.028,-6.7 12.072,-7.196 9.276,-6.511C8.666,-3.995 9.553,-1.512 8.92,1.192C6.802,1.35 4.665,1.51 2.438,1.676C1.432,-2.324 1.63,-6.171 1.196,-9.942C0.847,-12.977 0.751,-16.043 0.553,-19.095C0.508,-19.785 0.384,-20.444 0,-21.033L0,-22.568Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1621.09,417.318)">
                            <path
                                d="M0,13.949C-0.789,15.835 0.221,17.691 -0.623,19.636C-2.659,19.987 -4.812,20.046 -7.395,19.803C-8.214,11.862 -8.2,3.924 -8.855,-4.38C-2.913,-4.911 2.812,-6.097 8.409,-3.915C14.308,-1.614 15.736,4.445 11.759,9.413C11.443,9.807 11.118,10.193 10.764,10.623C11.505,13.519 13.788,15.719 14.508,18.9C11.863,19.734 9.349,19.16 6.996,19.417C5.379,17.656 5.124,15.362 3.648,13.775C2.454,13.607 1.281,13.604 0,13.949M-0.881,1.186C-0.789,3.405 -1.098,5.509 -0.286,7.584C1.58,8.113 3.285,7.701 4.81,6.713C7.003,5.292 6.864,2.589 4.525,1.415C2.914,0.606 1.173,0.299 -0.881,1.186"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1685.08,446.114)">
                            <path
                                d="M0,24.57C-2.579,24.933 -4.835,24.856 -7.055,24.626C-8.595,22.916 -8.676,20.593 -9.997,18.848C-11.136,18.455 -12.29,18.597 -13.483,18.816C-14.377,20.474 -13.354,22.413 -14.378,24.181C-16.446,24.802 -18.601,24.477 -20.972,24.307C-20.972,16.254 -20.972,8.38 -20.971,0.507C-20.639,0.041 -20.141,-0.07 -19.622,-0.081C-16.046,-0.156 -12.463,-0.363 -8.897,0.003C-4.164,0.489 -0.626,3.4 0.209,7.305C0.747,9.816 0.024,12.013 -1.401,14.033C-1.886,14.721 -2.485,15.329 -3.031,15.971C-2.914,17.61 -1.911,18.851 -1.394,20.272C-0.913,21.589 0.032,22.761 0,24.57M-13.628,12.594C-11.144,12.818 -8.995,12.717 -7.493,10.695C-6.635,9.541 -6.73,8.191 -7.744,7.191C-9.358,5.6 -11.338,5.183 -13.587,5.768C-13.919,8.025 -13.858,10.117 -13.628,12.594"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1711.84,448.47)">
                            <path
                                d="M0,23.22C0.427,15.072 0.839,7.187 1.251,-0.672C1.69,-0.899 1.996,-1.201 2.28,-1.182C7.01,-0.864 11.82,-1.201 16.361,0.652C21.876,2.903 25.102,7.111 24.88,11.896C24.621,17.468 21.338,21.305 15.291,23.022C10.364,24.421 5.41,24.284 0,23.22M7.631,17.766C11.469,18.611 14.756,17.623 16.368,15.281C17.853,13.124 17.889,10.043 16.453,8.053C14.956,5.98 11.187,4.762 8.16,5.563C8.346,9.538 7.398,13.547 7.631,17.766"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1645.82,445.531)">
                            <path
                                d="M0,25.93C-6.789,25.936 -12.73,19.839 -12.715,12.884C-12.699,5.968 -6.908,0.031 -0.161,0.013C7.118,-0.006 13.54,5.905 13.635,12.712C13.73,19.559 7.165,25.924 0,25.93M0.405,6.922C-3.133,6.916 -5.764,9.513 -5.744,12.991C-5.724,16.432 -3.012,19.052 0.484,19.011C3.776,18.972 6.513,16.288 6.581,13.031C6.652,9.678 3.871,6.928 0.405,6.922"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1697.58,452.53)">
                            <path
                                d="M0,12.839C3.69,13.236 7.048,12.743 10.344,13.683C10.93,15.369 10.543,17.006 10.436,18.9C4.485,19.102 -1.249,18.841 -6.93,18.341C-7.517,16.764 -7.079,-2.694 -6.389,-5.788C-4.299,-6.263 -2.144,-5.989 0.292,-5.744C0.704,0.393 0.063,6.46 0,12.839"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(0.956489,-0.291767,-0.291767,-0.956489,1652.35,434.465)">
                            <path
                                d="M-3.67,-0.551C-5.863,-0.06 -7.949,0.277 -10.118,1.581C-8.372,9.273 -5.725,16.709 -3.67,24.551C-1.14,24.306 0.975,23.586 3.389,22.588L-3.67,-0.551Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
