import React from "react";

export default class FallsFestival extends React.Component {
    render() {
        return (
            <svg
                id="l11"
                width="100%"
                height="100%"
                viewBox="0 0 697 141"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                space="preserve"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 1.41421,
                }}
            >
                <g transform="matrix(1,0,0,1,-14830.8,-19772.3)">
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1423.76,425.714)">
                            <path
                                d="M0,32.772C-1.406,33.021 -2.726,32.903 -4.162,32.863C-4.487,30.934 -4.803,29.059 -5.126,27.146C-6.646,26.887 -8.055,26.946 -9.536,27.087C-10.12,28.962 -10.073,30.914 -10.565,32.79C-11.954,33.033 -13.28,32.866 -14.879,32.905C-13.231,21.87 -11.602,10.958 -9.979,0.09C-9.081,-0.261 -8.283,-0.099 -7.504,-0.111C-6.718,-0.122 -5.924,-0.205 -4.902,0.028C-3.278,10.875 -1.644,21.793 0,32.772M-5.835,22.38C-6.336,19.213 -6.814,16.195 -7.292,13.177C-7.408,13.184 -7.525,13.191 -7.641,13.198C-7.859,14.706 -8.097,16.211 -8.288,17.723C-8.484,19.261 -8.947,20.78 -8.804,22.416C-7.81,22.523 -6.939,22.544 -5.835,22.38"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1555.51,425.697)">
                            <path
                                d="M0,32.792C-1.509,32.99 -2.769,32.996 -4.083,32.812C-4.685,30.988 -4.616,29.073 -5.198,27.148C-6.602,26.881 -8.048,26.989 -9.567,27.083C-10.098,28.98 -10.038,30.889 -10.612,32.833C-11.961,32.995 -13.356,32.977 -14.871,32.831C-13.224,21.816 -11.599,10.942 -9.963,0.003C-8.26,-0.204 -6.652,-0.066 -4.92,-0.09C-3.277,10.889 -1.651,21.759 0,32.792M-7.27,13.144C-7.398,13.145 -7.526,13.146 -7.653,13.147C-8.088,16.204 -8.523,19.26 -8.975,22.439C-7.875,22.505 -6.966,22.615 -5.863,22.352C-6.338,19.247 -6.804,16.195 -7.27,13.144"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1536.7,425.697)">
                            <path
                                d="M0,32.817C-1.535,33.007 -2.856,32.928 -4.377,32.878C-6.413,21.919 -8.44,11.007 -10.484,0.001C-8.892,-0.19 -7.424,-0.09 -5.793,-0.076C-4.649,6.517 -3.514,13.062 -2.378,19.607C-2.261,19.61 -2.143,19.613 -2.026,19.615C-0.915,13.087 0.196,6.559 1.314,-0.014C2.904,-0.165 4.373,-0.113 6.096,-0.053C4.059,10.932 2.037,21.832 0,32.817"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1492.54,434.887)">
                            <path
                                d="M0,14.592C1.477,14.379 2.797,14.479 4.136,14.503C4.553,15.294 4.322,16.096 4.377,16.863C4.416,17.403 4.314,17.955 4.49,18.483C4.691,19.086 5.031,19.56 5.735,19.567C6.448,19.573 6.918,19.105 6.943,18.485C7.052,15.77 7.713,13.007 5.688,10.551C4.058,8.574 2.608,6.438 1.231,4.264C-0.875,0.937 -0.637,-2.618 0.336,-6.175C0.794,-7.848 2.09,-8.857 3.81,-9.301C5.35,-9.698 6.854,-9.603 8.333,-9.099C10.447,-8.378 11.262,-6.691 11.402,-4.632C11.499,-3.21 11.421,-1.776 11.421,-0.273C9.964,0.065 8.549,-0.192 7.122,-0.105C6.722,-1.141 6.952,-2.133 6.908,-3.1C6.868,-3.958 6.714,-4.713 5.691,-4.828C4.906,-4.917 4.297,-4.22 4.199,-3.14C4.004,-0.972 4.214,1.026 5.757,2.821C7.363,4.691 8.698,6.791 10.172,8.775C11.244,10.22 11.771,11.83 11.717,13.622C11.659,15.547 11.891,17.48 11.532,19.396C11.148,21.438 10.162,23.05 8.088,23.636C5.776,24.29 3.465,24.289 1.486,22.642C0.434,21.766 0.086,20.489 0.018,19.184C-0.059,17.707 0,16.224 0,14.592"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1457.98,449.878)">
                            <path
                                d="M0,-15.175L-4.164,-15.175C-4.576,-15.744 -4.347,-16.406 -4.395,-17.026C-4.437,-17.567 -4.375,-18.123 -4.474,-18.652C-4.602,-19.334 -5,-19.853 -5.78,-19.822C-6.526,-19.792 -7.046,-19.304 -7.081,-18.622C-7.19,-16.521 -7.481,-14.424 -5.907,-12.559C-4.252,-10.599 -2.84,-8.433 -1.306,-6.368C-0.207,-4.89 0.392,-3.272 0.327,-1.403C0.274,0.129 0.368,1.667 0.299,3.198C0.201,5.377 -0.435,7.371 -2.575,8.355C-4.799,9.377 -7.093,9.315 -9.275,8.113C-10.282,7.558 -10.878,6.619 -11.126,5.528C-11.568,3.587 -11.454,1.617 -11.349,-0.402C-9.889,-0.611 -8.528,-0.541 -6.999,-0.444C-6.999,0.435 -7.022,1.262 -6.991,2.087C-6.97,2.628 -6.938,3.185 -6.789,3.699C-6.626,4.262 -6.187,4.603 -5.557,4.568C-4.924,4.532 -4.436,4.116 -4.445,3.563C-4.491,0.817 -3.626,-2.02 -5.74,-4.527C-7.422,-6.523 -8.884,-8.718 -10.275,-10.944C-11.109,-12.277 -11.623,-13.685 -11.586,-15.274C-11.555,-16.609 -11.601,-17.949 -11.5,-19.279C-11.257,-22.479 -9.164,-24.436 -5.978,-24.53C-5.232,-24.552 -4.502,-24.475 -3.773,-24.299C-1.461,-23.743 -0.152,-22.304 -0.017,-19.947C0.071,-18.422 0,-16.887 0,-15.175"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1485.24,430.359)">
                            <path
                                d="M0,23.49C1.92,23.877 3.811,23.446 5.728,23.77C5.949,25.206 5.813,26.621 5.817,28.152L-4.679,28.152L-4.679,-4.662L5.752,-4.662L5.752,-0.121L-0.01,-0.121C-0.174,2.872 -0.158,5.709 -0.023,8.71L5.323,8.71L5.323,13.198C3.621,13.657 1.82,13.114 0,13.547L0,23.49Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1467.72,458.759)">
                            <path
                                d="M0,-33.057L11.221,-33.057C11.396,-31.556 11.358,-30.104 11.245,-28.504L4.717,-28.504L4.717,-19.809C6.583,-19.46 8.424,-19.882 10.284,-19.532C10.464,-18.066 10.384,-16.656 10.335,-15.173C8.483,-14.772 6.64,-15.242 4.651,-14.841L4.651,-0.182C2.987,0 1.534,-0.077 0,-0.095L0,-33.057Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1399.39,458.68)">
                            <path
                                d="M0,-33.016L11.175,-33.016C11.365,-31.542 11.247,-30.125 11.259,-28.576C9.07,-28.2 6.894,-28.64 4.666,-28.299L4.666,-19.779C6.455,-19.375 8.294,-19.836 10.192,-19.495C10.25,-17.971 9.87,-16.555 9.635,-15.11C7.988,-14.764 6.391,-15.153 4.655,-14.821L4.655,-0.22C3.032,0 1.575,-0.11 0,-0.125L0,-33.016Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1509.57,454.136)">
                            <path
                                d="M0,-23.861L-4.303,-23.861C-4.519,-25.444 -4.381,-26.9 -4.394,-28.442L8.619,-28.442C8.667,-26.933 8.708,-25.522 8.584,-24.059C7.346,-23.66 6.085,-24.071 4.699,-23.735L4.699,4.363C3.067,4.581 1.612,4.487 0,4.442L0,-23.861Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1429.77,430.207)">
                            <path
                                d="M0,23.554C1.712,24.064 3.274,23.576 4.881,23.954L4.881,28.292L-4.538,28.292L-4.538,-4.48C-3.065,-4.738 -1.647,-4.573 0,-4.598L0,23.554Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1440.75,430.161)">
                            <path
                                d="M0,23.685C1.812,24.096 3.364,23.666 4.919,24.048L4.919,28.346L-4.526,28.346L-4.526,-4.446C-3.048,-4.661 -1.631,-4.522 0,-4.537L0,23.685Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1557.25,458.49)">
                            <path
                                d="M0,-32.818L4.517,-32.818L4.517,-4.74C6.152,-4.155 7.779,-4.784 9.353,-4.284L9.353,0L0,0L0,-32.818Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1524.51,458.526)">
                            <path
                                d="M0,-32.835L0,-0.012C-1.479,0.194 -2.931,0.093 -4.499,0.054L-4.499,-32.805C-3.052,-33.029 -1.603,-32.964 0,-32.835"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
