import React from "react";

export default class Disney extends React.Component {
    render() {
        return (
            <svg
                id="l9"
                width="100%"
                height="100%"
                viewBox="0 0 884 369"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                space="preserve"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 1.41421,
                }}
            >
                <g transform="matrix(1,0,0,1,-13248.3,-19658)">
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1053.14,428.032)">
                            <path
                                d="M0,10.39C-7.905,10.979 -18.013,12.446 -17.931,15.215C-17.845,18.111 -10.395,23.674 -0.612,27.298C-0.157,21.372 -0.044,15.09 0,10.39M-28.934,-19.453C-31.537,-19.375 -33.501,-20.512 -33.548,-22.102C-33.583,-23.264 -31.039,-26.891 -29.488,-27.479C-28.428,-27.879 -23.264,-29.841 -14.656,-30.097C-11.466,-30.191 -6.048,-29.8 -1.475,-29.122C25.152,-25.175 53.103,-4.872 53.638,13.135C54.002,25.387 43.418,36.881 22.755,37.495C17.313,37.657 12.249,36.986 7.642,35.777C7.542,38.784 7.766,40.377 3.5,40.504C0.892,40.581 -0.125,36.355 -0.509,32.917C-12.983,27.382 -20.498,18.392 -20.604,14.844C-20.761,9.559 -11.153,5.152 0.084,3.408C0.243,-0.967 0.983,-12.106 3.292,-12.174C4.22,-12.202 6.142,-10.049 6.868,-6.756C7.528,-3.764 7.597,1.692 7.604,2.665L8.452,2.634C24.219,2.166 33.669,7.095 33.842,12.934C33.977,17.46 29.97,19.778 27.868,19.84C25.766,19.903 23.302,18.707 23.283,18.057C23.246,16.811 27.769,16.518 27.709,14.505C27.619,11.466 15.083,10.134 7.359,10.087C7.348,12.704 7.314,15.865 7.377,17.989C7.438,20.029 7.565,25.3 7.627,29.698C11.131,30.44 14.742,30.841 18.28,30.735C26.607,30.488 45.628,27.452 45.203,13.163C44.61,-6.799 5.139,-25.139 -16.003,-24.511C-24.33,-24.263 -29.327,-23.37 -29.303,-22.553C-29.287,-22.008 -25.248,-21.798 -25.216,-20.72C-25.184,-19.643 -27.51,-19.495 -28.934,-19.453"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1112.81,460.945)">
                            <path
                                d="M0,-30.55C1.36,-30.59 3.31,-27.326 3.371,-25.286C3.432,-23.245 3.92,-12.582 4.063,-9.509C4.205,-6.437 4.104,-3.494 3.905,-2.132C3.704,-0.771 3.21,-0.098 0.872,-0.029C-1.465,0.04 -2.553,-1.352 -2.752,-3.41C-2.95,-5.469 -3.124,-11.309 -3.022,-14.235C-2.92,-17.16 -2.782,-22.759 -2.401,-26.264C-2.019,-29.769 -1.08,-30.518 0,-30.55"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1126.34,425.117)">
                            <path
                                d="M0,-18.678C-0.388,-19.647 -1.689,-21.604 -6.334,-21.466C-16.452,-21.166 -24.237,-13.671 -24.061,-7.765C-24.004,-5.846 -23.078,-4.165 -21.717,-2.787C-22.072,-1.822 -22.108,-1.176 -22.097,-0.808C-22.066,0.218 -20.522,2.926 -19.337,2.891C-18.334,2.861 -17.65,1.922 -16.684,0.401C-15.034,1.038 -13.39,1.362 -12.086,1.324C-2.572,1.041 6.06,-7.875 5.891,-13.579C5.813,-16.204 4.111,-18.743 0,-18.678M-14.361,-4.508C-13.19,-6.162 -7.117,-14.294 -0.857,-14.48C0.98,-14.534 1.464,-13.398 1.502,-12.142C1.56,-10.187 -1.983,-4.471 -9.96,-4.235C-11.709,-4.183 -13.182,-4.314 -14.361,-4.508M-19.311,-7.207C-19.455,-7.446 -19.592,-7.826 -19.609,-8.419C-19.648,-9.726 -17.392,-14.733 -11.716,-15.144C-13.162,-14.152 -14.625,-12.882 -16.072,-11.256C-17.433,-9.727 -18.516,-8.381 -19.311,-7.207"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1137.49,460.228)">
                            <path
                                d="M0,-30.38C5.57,-30.545 12.549,-29.824 12.602,-28.042C12.632,-27 12.087,-24.825 9.115,-24.524C5.609,-24.168 -12.074,-23.417 -12.009,-21.234C-11.975,-20.08 -6.661,-19.824 -1.787,-19.529C3.219,-19.226 11.262,-19.038 11.502,-10.934C11.692,-4.536 9.271,-3.183 8.182,-2.674C6.798,-2.027 2.15,-0.26 -1.413,-0.154C-12.152,0.165 -16.58,-6.006 -16.673,-9.143C-16.756,-11.928 -13.891,-14.398 -9.073,-14.541C-4.256,-14.684 3.205,-12.947 3.264,-10.965C3.311,-9.359 0.021,-8.534 -1.434,-8.49C-8.009,-8.295 -7.686,-10.967 -11.475,-10.854C-13.633,-10.79 -16.661,-6.205 -5.847,-6.526C3.789,-6.812 6.708,-9.988 6.657,-11.694C6.59,-13.952 -0.323,-15.053 -4.323,-15.26C-8.322,-15.468 -14.324,-15.44 -16.371,-16.736C-18.419,-18.03 -18.132,-20.961 -17.423,-23.384C-15.941,-28.45 -5.571,-30.215 0,-30.38"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1156.52,453.564)">
                            <path
                                d="M0,-21.619C3.764,-21.731 11.655,-4.663 12.985,-4.703C14.315,-4.742 13.326,-8.69 12.486,-13.05C10.585,-22.913 7.621,-27.998 10.407,-28.08C12.464,-28.142 19.213,-21.612 19.589,-8.967C19.763,-3.096 18.937,4.587 13.617,4.745C8.573,4.895 2.826,-11.03 1.521,-10.992C0.819,-10.971 0.76,-1.126 0.572,1.843C0.384,4.812 -0.649,6.4 -2.305,6.449C-4.814,6.523 -5.916,4.12 -6.057,-0.622C-6.198,-5.364 -4.642,-21.481 0,-21.619"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1194.79,450.369)">
                            <path
                                d="M0,-9.19C1.782,-9.243 3.355,-9.541 3.198,-7.226C3.04,-4.911 2.943,-3.979 1.086,-3.924L-8.439,-3.641C-8.754,-2.581 -9.569,0.148 -9.935,1.3C-10.394,2.745 -8.947,2.426 -7.079,1.894C-5.212,1.361 -0.143,0.407 1.94,0.345C4.023,0.283 5.579,3.602 4.382,4.742C3.186,5.883 -3.258,11.323 -9.983,11.522C-12.868,11.608 -16.786,9.088 -16.92,4.572C-16.991,2.181 -16.031,-1.028 -15.111,-3.519C-15.336,-3.66 -15.506,-3.989 -15.638,-4.708C-15.938,-6.331 -16.669,-8.117 -13.966,-8.424C-13.749,-8.448 -13.477,-8.474 -13.16,-8.5L-10.927,-13.098C-11.833,-13.231 -12.616,-13.431 -13.219,-13.719C-14.86,-14.503 -16.14,-16.52 -16.167,-17.424C-16.226,-19.405 -1.695,-20.591 1.668,-20.69C5.281,-20.798 6.307,-20.1 7.46,-18.452C8.614,-16.803 9.051,-15.636 9.064,-15.185C9.078,-14.733 8.659,-13.616 4.293,-13.486C2.059,-13.419 -1.552,-13.043 -5.017,-12.915L-6.525,-8.894C-3.155,-9.061 0,-9.19 0,-9.19"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1204.63,460.051)">
                            <path
                                d="M0,-5.134C-3.902,2.563 -6.528,10.719 -6.345,16.858C-6.14,23.775 -1.892,26.356 -0.601,26.317C0.69,26.279 0.754,24.232 0.986,22.706C2.3,14.068 4.564,6.413 7.146,-0.019C8.17,0.239 9.183,0.358 10.12,0.33C18.032,0.095 27.201,-9.358 26.902,-19.422C26.744,-24.736 24.713,-28.505 21.307,-28.403C16.326,-28.256 9.164,-20.725 3.413,-11.273C2.899,-15.109 5.923,-21.014 8.502,-25.143C10.314,-28.043 9.996,-31.49 9.244,-31.468C8.19,-31.436 -2.016,-22.796 -1.664,-10.945C-1.595,-8.643 -0.995,-6.71 0,-5.134M10.556,-7.529C15.319,-16.843 20.232,-22.153 21.959,-22.204C24.954,-22.293 22.419,-8.746 10.556,-7.529"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
