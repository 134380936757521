import React from "react";

export default class OMD extends React.Component {
    render() {
        return (
            <svg
                id="l22"
                width="100%"
                height="100%"
                viewBox="0 0 503 295"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                space="preserve"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 1.41421,
                }}
            >
                <g transform="matrix(1,0,0,1,-15747.6,-20943)">
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1739.96,731.439)">
                            <path
                                d="M0,20.536C0,8.118 -9.622,-3.145 -28.233,-3.145L-46.721,-3.145L-46.721,-25.111L-62.484,-25.111L-68.823,-13.452L-75.456,-25.111L-91.218,-25.111L-91.218,0.774C-94.393,-2.232 -98.669,-3.889 -103.293,-3.889C-108.003,-3.889 -112.37,-2.16 -115.588,0.982C-117.152,2.507 -118.376,4.295 -119.228,6.293C-120.102,8.343 -120.545,10.529 -120.545,12.791L-120.545,12.872C-120.545,17.367 -118.799,21.547 -115.629,24.641C-112.437,27.756 -108.037,29.47 -103.326,29.47C-98.616,29.47 -94.298,27.741 -91.08,24.6C-89.516,23.073 -88.291,21.286 -87.438,19.288C-86.71,17.578 -86.285,15.772 -86.163,13.908L-77.424,13.908L-77.424,-2.102L-68.823,10.175L-60.516,-2.102L-60.516,13.908L-46.96,13.908L-46.96,45.647L-28.789,45.647C-10.047,45.647 0.035,35.253 0.035,21.239L0,20.536ZM-97.839,12.872C-97.839,15.786 -99.712,18.511 -103.293,18.511C-106.916,18.511 -108.869,15.794 -108.869,12.791L-108.869,12.709L-108.87,12.709C-108.87,9.795 -106.935,6.99 -103.326,6.99C-99.731,6.99 -97.839,9.751 -97.839,12.791L-97.839,12.872ZM-16.954,21.073C-16.954,27.061 -21.626,29.67 -27.162,29.67L-30.355,29.67L-30.355,12.353L-27.209,12.353C-21.626,12.353 -16.954,14.967 -16.954,20.957L-16.954,21.073Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
