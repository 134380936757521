import React from "react";

export default class Marketforce extends React.Component {
    render() {
        return (
            <svg
                id="l19"
                width="100%"
                height="100%"
                viewBox="0 0 907 83"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                space="preserve"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 1.41421,
                }}
            >
                <g transform="matrix(1,0,0,1,-13053.3,-21052.7)">
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,972.781,752.144)">
                            <path
                                d="M0,-19.061L5.652,-19.061C7.096,-16.731 8.553,-14.381 10.097,-11.891C11.615,-14.343 13.05,-16.661 14.507,-19.015L20.032,-19.015L20.032,-0.296L14.83,-0.296L14.83,-10.471C14.762,-10.494 14.694,-10.517 14.626,-10.54C13.132,-8.267 11.639,-5.993 10.045,-3.566C8.485,-5.941 7.012,-8.183 5.54,-10.426C5.47,-10.415 5.401,-10.405 5.331,-10.394C5.174,-7.058 5.359,-3.711 5.228,-0.254C3.437,-0.316 1.715,0 0,-0.421L0,-19.061Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1123.4,752.366)">
                            <path
                                d="M0,-19.711C0.475,-19.587 0.948,-19.453 1.425,-19.341C5.704,-18.338 9.235,-14.386 8.816,-8.994C8.469,-4.532 4.819,-0.756 0.172,-0.194C-0.103,-0.161 -0.371,-0.066 -0.643,0L-2.143,0C-2.651,-0.116 -3.159,-0.235 -3.667,-0.349C-8.455,-1.429 -11.496,-5.273 -11.403,-10.127C-11.313,-14.789 -7.971,-18.631 -3.259,-19.499C-3.024,-19.542 -2.8,-19.639 -2.571,-19.711L0,-19.711ZM-1.327,-4.906C1.222,-4.728 3.434,-6.927 3.443,-9.834C3.453,-12.707 1.46,-14.842 -1.253,-14.866C-4.008,-14.89 -6.011,-12.818 -6.021,-9.936C-6.03,-7.066 -4.028,-4.92 -1.327,-4.906"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1163.46,732.655)">
                            <path
                                d="M0,19.711C-0.51,19.594 -1.021,19.481 -1.531,19.359C-6.175,18.243 -9.102,14.354 -8.976,9.47C-8.856,4.842 -5.595,1.056 -1.001,0.204C-0.804,0.168 -0.619,0.069 -0.429,0L2.357,0C2.586,0.072 2.811,0.168 3.045,0.212C5.462,0.668 7.411,1.849 8.921,3.973C7.613,4.995 6.336,5.994 4.849,7.157C4.583,6.795 4.377,6.399 4.067,6.116C2.753,4.917 1.209,4.516 -0.488,5.083C-2.205,5.658 -3.081,6.988 -3.444,8.706C-3.656,9.709 -3.578,10.693 -3.255,11.655C-2.212,14.761 1.271,15.798 3.841,13.764C4.284,13.414 4.674,12.999 5.158,12.549C6.431,13.451 7.701,14.351 9.038,15.297C8.098,16.835 6.846,17.969 5.263,18.657C4.299,19.076 3.225,19.243 2.201,19.522C1.968,19.586 1.734,19.648 1.5,19.711L0,19.711Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1031.36,739.483)">
                            <path
                                d="M0,5.918C1.456,8.053 2.89,10.155 4.41,12.384L-1.657,12.384C-2.886,10.552 -4.151,8.665 -5.412,6.785L-7.691,6.785L-7.691,12.381L-12.888,12.381L-12.888,-6.314C-12.687,-6.342 -12.453,-6.404 -12.22,-6.404C-9.331,-6.411 -6.441,-6.466 -3.555,-6.38C-2.399,-6.346 -1.204,-6.136 -0.111,-5.762C2.19,-4.974 3.491,-3.263 3.737,-0.842C3.993,1.678 3.262,3.811 1.052,5.27C0.874,5.387 0.683,5.486 0.501,5.597C0.352,5.688 0.206,5.785 0,5.918M-7.626,2.583C-6.291,2.583 -5.086,2.597 -3.881,2.573C-3.568,2.567 -3.248,2.471 -2.948,2.366C-1.99,2.03 -1.505,1.333 -1.511,0.35C-1.518,-0.684 -2.029,-1.429 -3.063,-1.676C-4.558,-2.034 -6.073,-1.81 -7.626,-1.835L-7.626,2.583Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1072.82,744.673)">
                            <path
                                d="M0,-4.39L0,-0.196L-8.863,-0.196L-8.863,2.68L1.067,2.68L1.067,7.152L-14.051,7.152L-14.051,-11.542L0.905,-11.542L0.905,-7.127L-8.872,-7.127L-8.872,-4.39L0,-4.39Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1049.27,743.797)">
                            <path
                                d="M0,-2.646C2.439,0.92 4.829,4.415 7.306,8.036L0.994,8.036C-0.502,5.851 -2.066,3.568 -3.691,1.195C-4.362,1.926 -4.958,2.574 -5.572,3.243L-5.572,8.035L-10.77,8.035L-10.77,-10.682L-5.599,-10.682L-5.599,-3.457C-5.534,-3.433 -5.469,-3.41 -5.404,-3.386C-3.329,-5.812 -1.254,-8.239 0.824,-10.669L7.069,-10.669C4.661,-7.936 2.36,-5.324 0,-2.646"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1008.22,751.852)">
                            <path
                                d="M0,-18.825C2.638,-12.584 5.266,-6.365 7.946,-0.023L2.305,-0.023C1.878,-1.059 1.421,-2.167 0.956,-3.296L-6.213,-3.296C-6.654,-2.221 -7.108,-1.115 -7.564,0L-13.079,0C-10.413,-6.334 -7.795,-12.552 -5.154,-18.825L0,-18.825ZM-2.618,-12.566C-3.336,-10.77 -3.973,-9.177 -4.629,-7.536L-0.722,-7.536C-0.764,-7.754 -0.77,-7.896 -0.82,-8.021C-1.387,-9.465 -1.96,-10.907 -2.618,-12.566"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1109.11,744.205)">
                            <path
                                d="M0,-3.444L0,1.009L-8.769,1.009L-8.769,7.621L-13.975,7.621L-13.975,-11.065L0.952,-11.065L0.952,-6.498L-8.727,-6.498L-8.727,-3.444L0,-3.444Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1086.95,733.166)">
                            <path
                                d="M0,18.686L-5.239,18.686C-5.386,17.544 -5.288,16.378 -5.305,15.22C-5.323,14.043 -5.309,12.865 -5.309,11.688L-5.309,8.157L-5.309,4.544L-10.81,4.544L-10.81,0L5.534,0L5.534,4.543L0,4.543L0,18.686Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1175.3,751.852)">
                            <path
                                d="M0,-18.732L15.015,-18.732L15.015,-14.331L5.217,-14.331L5.217,-11.553L14.083,-11.553L14.083,-7.397L5.227,-7.397L5.227,-4.483L15.135,-4.483L15.135,0L0,0L0,-18.732Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1152.82,733.067)">
                            <path
                                d="M0,18.796L-6.065,18.796C-7.306,16.944 -8.556,15.079 -9.823,13.186L-12.054,13.186L-12.054,18.792L-17.252,18.792L-17.252,0.007L-15.187,0.007C-12.831,0.007 -10.474,0 -8.118,0.01C-6.527,0.016 -4.99,0.28 -3.581,1.067C-1.79,2.068 -0.844,3.614 -0.673,5.647C-0.431,8.522 -1.424,10.748 -4.068,12.108C-4.16,12.155 -4.232,12.239 -4.379,12.361C-2.944,14.47 -1.511,16.575 0,18.796M-12.003,9.006C-10.586,8.965 -9.141,9.16 -7.714,8.88C-6.495,8.641 -5.866,7.814 -5.9,6.716C-5.935,5.593 -6.564,4.791 -7.827,4.668C-9.19,4.535 -10.577,4.638 -12.003,4.638L-12.003,9.006Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
