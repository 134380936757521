import React from "react";

export default class Converse extends React.Component {
    render() {
        return (
            <svg
                id="l6"
                width="100%"
                height="100%"
                viewBox="0 0 949 111"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                space="preserve"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 1.41421,
                }}
            >
                <g transform="matrix(1,0,0,1,-14747.5,-19234.2)">
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1584.68,296.207)">
                            <path
                                d="M0,26.534C0.049,26.315 0.236,26.197 0.372,26.047C4.097,21.955 7.826,17.866 11.552,13.774C11.69,13.622 11.856,13.489 11.947,13.292C11.875,13.086 11.702,12.959 11.565,12.809C7.827,8.703 4.085,4.6 0.349,0.492C0.215,0.346 0.008,0.24 0,0L10.363,0C10.402,0.225 10.594,0.34 10.73,0.489C14.454,4.582 18.183,8.671 21.908,12.763C22.057,12.927 22.251,13.062 22.32,13.292C22.241,13.499 22.069,13.628 21.931,13.78C18.205,17.872 14.477,21.961 10.752,26.053C10.613,26.205 10.44,26.332 10.363,26.534L0,26.534Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1430.59,298.8)">
                            <path
                                d="M0,21.086L0,0.038C0.204,-0.085 0.398,-0.04 0.584,-0.041C2.14,-0.044 3.697,-0.029 5.252,-0.052C5.642,-0.058 5.87,0.083 6.098,0.381C8.896,4.046 11.705,7.701 14.513,11.359C14.624,11.504 14.745,11.642 14.934,11.872L14.934,0.061C15.37,-0.063 19.752,-0.097 20.518,0.006C20.629,0.414 20.673,20.051 20.564,21.045C20.408,21.183 20.209,21.124 20.028,21.124C18.225,21.129 16.422,21.117 14.62,21.136C14.254,21.139 14.037,21.021 13.826,20.725C11.246,17.113 8.653,13.51 6.06,9.906C5.944,9.744 5.857,9.549 5.611,9.396L5.611,21.086L0,21.086Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1414.69,296.33)">
                            <path
                                d="M0,24.316C-5.435,24.305 -10.084,20.59 -11.22,15.353C-12.315,10.305 -9.686,5.137 -4.892,2.911C1.383,-0.003 8.821,3.183 10.939,9.692C12.812,15.449 9.631,21.679 3.798,23.679C2.589,24.093 1.246,24.319 0,24.316M5.365,13.05C5.353,10.08 2.972,7.715 -0.003,7.718C-2.99,7.722 -5.386,10.135 -5.367,13.122C-5.348,16.062 -2.92,18.447 0.04,18.434C2.994,18.421 5.377,16.011 5.365,13.05"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1519.94,307.366)">
                            <path
                                d="M0,3.95C1.445,6.8 2.873,9.619 4.298,12.432C4.153,12.609 3.992,12.558 3.853,12.558C2.05,12.562 0.247,12.55 -1.555,12.57C-1.922,12.574 -2.085,12.422 -2.233,12.117C-3.297,9.935 -4.387,7.767 -5.442,5.582C-5.662,5.125 -5.953,4.925 -6.45,4.937C-7.224,4.956 -7.998,4.942 -8.827,4.942L-8.827,12.524L-14.461,12.524L-14.461,-8.521C-14.274,-8.659 -14.077,-8.606 -13.893,-8.606C-10.535,-8.61 -7.176,-8.609 -3.817,-8.609C-1.984,-8.609 -0.345,-8.063 1.05,-6.856C4.277,-4.066 4.112,0.871 0.708,3.443C0.497,3.602 0.278,3.751 0,3.95M-8.812,-0.747C-8.708,-0.723 -8.654,-0.7 -8.601,-0.7C-7.026,-0.697 -5.452,-0.698 -3.878,-0.695C-3.665,-0.694 -3.462,-0.738 -3.27,-0.821C-2.845,-1.002 -2.568,-1.415 -2.592,-1.85C-2.617,-2.304 -2.842,-2.638 -3.259,-2.831C-3.506,-2.945 -3.77,-2.959 -4.038,-2.959C-5.479,-2.956 -6.921,-2.959 -8.362,-2.955C-8.507,-2.955 -8.667,-3.002 -8.812,-2.862L-8.812,-0.747Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1498.35,307.023)">
                            <path
                                d="M0,4.723L-5.058,4.723L-10.091,4.723L-10.091,7.259L1.135,7.259L1.135,12.813C0.726,12.926 -15.125,12.951 -15.727,12.84C-15.812,12.391 -15.822,-7.647 -15.731,-8.228L1.128,-8.228L1.128,-2.623L-10.091,-2.623C-10.153,-1.853 -10.12,-1.141 -10.118,-0.369L0,-0.369L0,4.723Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1551.27,298.802)">
                            <path
                                d="M0,21.071L0,0.055C0.411,-0.067 16.263,-0.095 16.907,0.026L16.907,5.607L11.294,5.607L5.679,5.607L5.679,7.859C6.331,7.881 7.006,7.866 7.679,7.869C8.344,7.871 9.008,7.869 9.672,7.869L11.721,7.869C12.385,7.869 13.049,7.862 13.713,7.871C14.391,7.881 15.07,7.832 15.776,7.904L15.776,12.94L5.669,12.94L5.669,15.48L16.825,15.48C16.959,15.914 16.992,20.326 16.88,21.053C16.449,21.141 0.722,21.166 0,21.071"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1527.27,301.721)">
                            <path
                                d="M0,15.365C0.058,15.284 0.108,15.204 0.168,15.133C1.223,13.89 2.279,12.648 3.33,11.412C3.576,11.453 3.71,11.625 3.875,11.742C5.947,13.21 8.227,14.025 10.788,13.973C11.247,13.963 11.694,13.898 12.129,13.747C12.382,13.659 12.619,13.541 12.82,13.362C13.399,12.847 13.388,12.018 12.789,11.525C12.401,11.206 11.934,11.04 11.466,10.882C10.332,10.499 9.155,10.286 8,9.983C7.138,9.756 6.283,9.508 5.454,9.178C5.19,9.073 4.932,8.952 4.671,8.84C-0.365,6.668 0.555,0.667 3.243,-1.552C4.497,-2.588 5.923,-3.207 7.507,-3.428C10.265,-3.814 12.956,-3.526 15.538,-2.438C16.398,-2.075 17.198,-1.605 17.95,-1.052C18.1,-0.942 18.276,-0.855 18.392,-0.63C17.292,0.668 16.18,1.979 15.046,3.316C14.749,3.154 14.487,3.01 14.224,2.866C12.986,2.186 11.701,1.64 10.278,1.489C9.691,1.427 9.106,1.396 8.523,1.531C8.318,1.579 8.122,1.646 7.942,1.754C7.13,2.238 7.07,3.341 7.831,3.901C8.249,4.209 8.739,4.365 9.229,4.519C10.406,4.887 11.624,5.093 12.813,5.416C13.711,5.659 14.595,5.939 15.448,6.313C15.953,6.534 16.439,6.788 16.901,7.088C18.849,8.351 19.623,10.175 19.439,12.443C19.231,15.024 17.868,16.81 15.586,17.94C14.398,18.529 13.126,18.842 11.814,18.941C8.651,19.179 5.613,18.688 2.751,17.27C1.915,16.855 1.133,16.357 0.407,15.77C0.263,15.653 0.101,15.55 0,15.365"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1395.9,305.055)">
                            <path
                                d="M0,7.239C1.66,8.379 3.314,9.515 4.977,10.657C4.861,10.935 4.665,11.119 4.496,11.316C1.913,14.323 -1.383,15.727 -5.321,15.549C-9.02,15.382 -12.054,13.822 -14.295,10.865C-17.917,6.086 -16.984,-0.562 -12.213,-4.327C-6.941,-8.488 0.818,-7.505 4.879,-2.162C4.924,-2.102 4.96,-2.035 5.031,-1.923C3.37,-0.781 1.716,0.355 0.043,1.505C-0.184,1.385 -0.298,1.16 -0.454,0.981C-1.412,-0.12 -2.592,-0.813 -4.048,-1.026C-6.818,-1.429 -9.648,0.581 -10.167,3.329C-10.799,6.673 -8.114,9.807 -4.66,9.752C-2.984,9.725 -1.63,9.011 -0.524,7.782C-0.384,7.627 -0.251,7.466 -0.114,7.309C-0.102,7.296 -0.082,7.289 0,7.239"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1471.61,298.671)">
                            <path
                                d="M0,21.228L-9.279,21.228C-11.571,14.21 -13.858,7.206 -16.145,0.203C-15.745,0.057 -11.051,0 -9.741,0.119C-9.564,0.263 -9.555,0.488 -9.495,0.681C-8.035,5.328 -6.583,9.977 -5.128,14.626C-5.1,14.716 -5.065,14.805 -5.042,14.896C-4.982,15.126 -4.914,15.342 -4.613,15.336C-4.335,15.331 -4.292,15.114 -4.229,14.916C-4.076,14.428 -3.923,13.939 -3.771,13.451C-2.439,9.181 -1.108,4.911 0.225,0.641C0.276,0.479 0.334,0.319 0.4,0.125L6.889,0.125C4.583,7.188 2.293,14.204 0,21.228"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1584.21,302.057)">
                            <path
                                d="M0,14.826C-0.534,14.068 -1.068,13.31 -1.603,12.552C-2.127,11.811 -2.651,11.071 -3.183,10.32C-4.958,10.904 -6.695,11.476 -8.432,12.048L-8.484,11.966C-7.401,10.464 -6.318,8.963 -5.211,7.428C-6.321,5.909 -7.425,4.399 -8.53,2.888C-8.514,2.86 -8.498,2.832 -8.482,2.804C-6.729,3.375 -4.976,3.946 -3.182,4.531C-2.111,3.015 -1.046,1.508 0.019,0C0.052,0.014 0.085,0.028 0.118,0.042L0.118,5.633C1.918,6.221 3.695,6.801 5.607,7.425C3.715,8.043 1.953,8.617 0.139,9.209L0.139,14.802C0.093,14.81 0.046,14.818 0,14.826"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
