import React, { useState, useEffect, useRef } from "react";
import gsap, { TweenMax, Back } from "gsap";
import useReelState from "../zustand/useReelState";
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock";
import YouTube from "react-youtube";
import { useMediaQuery } from "react-responsive";

/* Reel Launcher
============================================================================= */

const ReelLauncher = () => {
    // Config
    const ref = useRef();
    const [video, setVideo] = useState(null);
    const [mobileVideo, setMobileVideo] = useState(null);
    const [mounted, setMounted] = useState(false);
    const {
        reelOpen,
        setReelOpen,
        showVideo,
        setShowVideo,
        canOpenReel,
    } = useReelState();

    useEffect(() => {
        if (reelOpen) {
            gsap.to("#reel-divider", {
                height: "50%",
                duration: 1,
                ease: Back.easeOut.config(1),
            });
            gsap.to("#reel-thumb", {
                transform: "perspective(20cm) rotateY(0deg)",
                opacity: 1,
                duration: 1,
                ease: Back.easeOut.config(1),
            });
        } else {
            gsap.to("#reel-divider", {
                height: "0%",
            });
            gsap.to("#reel-thumb", {
                transform: "perspective(20cm) rotateY(-30deg)",
                opacity: 0,
            });
        }
    }, [reelOpen]);

    useEffect(() => {
        if (showVideo) {
            gsap.to("#reel-container", {
                backgroundColor: "rgba(0,0,0,0.9)",
                duration: 0.5,
            });
            gsap.to("#reel-divider", {
                height: "75vh",
                duration: 0.5,
            });
            gsap.to("#reel-divider", {
                width: "133.25vh",
                delay: 0.5,
                duration: 0.5,
            });
            if (window.innerWidth < 1224) {
                mobileVideo.seekTo(0);
                mobileVideo.playVideo();
            } else {
                video.seekTo(0);
                video.playVideo();
            }
        }
        if (!showVideo) {
            if (!canOpenReel) {
                return;
            }
            setTimeout(() => {
                if (window.innerWidth < 1224) {
                    mobileVideo.pauseVideo();
                } else {
                    video.pauseVideo();
                }
            }, 500);
            if (inReelSection()) {
                gsap.to("#reel-container", {
                    backgroundColor: "rgba(0,0,0,0)",
                    duration: 0.5,
                    delay: 1,
                });
                gsap.to("#reel-divider", {
                    height: "50vh",
                    duration: 0.5,
                    delay: 1.5,
                });
                gsap.to("#reel-divider", {
                    width: "3px",
                    delay: 0.5,
                    duration: 1,
                });
            } else {
                setReelOpen(false);
                gsap.to("#reel-container", {
                    backgroundColor: "rgba(0,0,0,0)",
                    duration: 0.5,
                    delay: 1,
                });
                gsap.to("#reel-divider", {
                    height: "0%",
                    duration: 0.5,
                    delay: 1.5,
                });
                gsap.to("#reel-divider", {
                    width: "3px",
                    delay: 0.5,
                    duration: 1,
                });
            }
        }
    }, [showVideo]);

    const inReelSection = () => {
        const screenHeight = window.innerHeight;
        if (window.scrollY > screenHeight * 3.5) {
            return false;
        }
        if (window.scrollY < screenHeight / 2) {
            return false;
        }
        return true;
    };

    // Render
    return (
        <>
            <div
                id="reel-launcher"
                className={`md:flex hidden relative ${
                    !reelOpen ? "pointer-events-none" : ""
                }`}
                style={{ zIndex: 100 }}
            >
                <div id="reel-thumb">
                    <video loop muted autoPlay id="reel-video-preview">
                        <source src="reel.mp4" />
                    </video>
                    <div
                        id="play-reel-circle"
                        onClick={() => setShowVideo(true)}
                        className={`absolute flex items-center justify-center h-48 w-48 ${
                            reelOpen ? "play-reel-circle-open" : ""
                        }`}
                    >
                        <h2
                            className="text-xl"
                            style={{
                                fontFamily: "Kritik-Bold",
                            }}
                        >
                            PLAY REEL
                        </h2>
                        <svg
                            className={`progress-ring absolute top-0 w-full h-full ${
                                reelOpen ? "progress-ring-active" : ""
                            }`}
                            width="100%"
                            height="100%"
                        >
                            <circle
                                className="progress-ring__circle"
                                stroke="white"
                                stroke-width="3"
                                fill="transparent"
                                r="92"
                                cx="96"
                                cy="96"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <div
                id="reel-container"
                className={`md:flex hidden relative ${
                    !showVideo ? "pointer-events-none" : ""
                }`}
                style={{ zIndex: 10000 }}
                onClick={() => {
                    setShowVideo(false);
                }}
            >
                <div
                    id="reel-divider"
                    className={`${showVideo ? "bg-hidden" : ""}`}
                >
                    <div id="video-container" className="shadow-lg">
                        <YouTube
                            id="reel-vid"
                            videoId="xMwv3RzcchI"
                            opts={{
                                playerVars: {
                                    loop: 1,
                                    rel: 0,
                                    playsinline: 0,
                                    controls: 1,
                                    modestbranding: 1,
                                },
                            }}
                            className={`yt-reel-vid transition-opacity duration-1000 ${
                                showVideo
                                    ? "opacity-100 delay-1000"
                                    : "opacity-0 delay-0 duration-100"
                            }`}
                            containerClassName="yt-reel-vid-container"
                            onReady={(e) => {
                                setVideo(e.target);
                            }}
                            onStateChange={({ data }) => {
                                if (data === 0) {
                                    setShowVideo(false);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div
                id="mobile-reel-container"
                className={`flex transition-opacity duration-500 md:hidden fixed ${
                    !showVideo ? "pointer-events-none opacity-0" : "opacity-100"
                }`}
                style={{ zIndex: 5000000 }}
            >
                <YouTube
                    id="mobile-reel-vid"
                    videoId="qhWao8l8wM0"
                    opts={{
                        playerVars: {
                            loop: 1,
                            rel: 0,
                            playsinline: 0,
                            controls: 0,
                            modestbranding: 1,
                        },
                    }}
                    className={`mobile-yt-reel-vid transition-opacity duration-1000 ${
                        showVideo
                            ? "opacity-100 delay-1000"
                            : "opacity-0 delay-0 duration-100"
                    }`}
                    containerClassName="mobile-yt-reel-vid-container"
                    onReady={(e) => {
                        setMobileVideo(e.target);
                    }}
                    onStateChange={({ data }) => {
                        if (data === 0) {
                            setShowVideo(false);
                        }
                        if (data === 2) {
                            setShowVideo(false);
                        }
                    }}
                />
            </div>
        </>
    );
};

/* Export
============================================================================= */

export default ReelLauncher;
