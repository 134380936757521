import React from "react";

export default class SevenEleven extends React.Component {
    render() {
        return (
            <svg
                id="l1"
                width="100%"
                height="100%"
                viewBox="0 0 786 849"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                space="preserve"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 1.41421,
                }}
            >
                <g transform="matrix(1,0,0,1,-2096.57,-3077.6)">
                    <g>
                        <path
                            d="M2641.4,3708C2641.4,3705 2638.8,3704.2 2636.1,3704.2L2630.8,3704.2L2630.8,3712.1L2635.3,3712.1C2638.8,3712.1 2641.4,3711.7 2641.4,3708Z"
                            style={{ fill: "white", fillRule: "nonzero" }}
                        />
                        <path
                            d="M2635.4,3695.2C2625.3,3695.2 2617,3703.5 2617,3713.6C2617,3723.4 2625.3,3731.6 2635.4,3731.6C2645.5,3731.6 2653.8,3723.3 2653.8,3713.6C2653.8,3703.5 2645.6,3695.2 2635.4,3695.2ZM2641.8,3726.3L2635.4,3715.4L2630.9,3715.4L2630.9,3726.3L2627.1,3726.3L2627.1,3700.8L2636.5,3700.8C2642.5,3700.8 2645.5,3703 2645.5,3708.3C2645.5,3712.8 2642.9,3714.7 2639.1,3715.1L2646.2,3726.4L2641.8,3726.4L2641.8,3726.3Z"
                            style={{ fill: "white", fillRule: "nonzero" }}
                        />
                        <path
                            d="M2853.8,3077.6L2124.8,3077.6C2106.8,3077.6 2095.2,3092.6 2096.7,3108.4L2166.9,3856.1C2168.4,3874.9 2183.8,3888.7 2202.5,3888.7L2365.7,3888.7L2365.7,3925.8L2603.9,3925.8L2603.9,3888.7L2775.4,3888.7C2794.2,3888.7 2809.5,3874.8 2811.4,3856.1L2881.6,3108.4C2883.4,3090.8 2869.5,3077.6 2853.8,3077.6ZM2421.2,3514C2472.2,3350.4 2605.8,3213.9 2771.6,3161.3L2771.6,3354.9C2695.8,3380 2646.7,3422.4 2623.4,3514L2421.2,3514ZM2567.9,3526.7L2540.9,3665.9L2485.4,3665.9L2465.1,3526.7L2506.4,3526.7L2520.7,3611.5L2538.3,3526.7L2567.9,3526.7ZM2217.8,3164.3L2719.8,3164.3C2629.8,3205.6 2552.1,3266 2489.1,3346.3L2217.8,3346.3L2217.8,3164.3ZM2283.5,3557.9L2250.1,3557.9L2250.1,3582.7L2283.5,3582.7L2283.5,3609.7L2250.1,3609.7L2250.1,3633.3L2283.5,3633.3L2283.5,3665.9L2206.2,3665.9L2206.2,3526.7L2283.5,3526.7L2283.5,3557.9ZM2370.9,3665.9L2300.4,3665.9L2300.4,3526.7L2344.3,3526.7L2344.3,3633.3L2370.9,3633.3L2370.9,3665.9ZM2379.5,3526.7L2456.8,3526.7L2456.8,3557.8L2423.4,3557.8L2423.4,3582.6L2456.8,3582.6L2456.8,3609.6L2423.4,3609.6L2423.4,3633.2L2456.8,3633.2L2456.8,3665.8L2379.5,3665.8L2379.5,3526.7ZM2591.9,3784.5C2591.1,3814.5 2591.9,3844.9 2591.9,3908.7L2381,3908.7C2379.9,3794.6 2383.6,3770.6 2398.3,3678.3L2600.2,3678.3C2594.9,3730.5 2592.7,3757.5 2591.9,3784.5ZM2576.1,3665.9L2576.1,3526.7L2653.4,3526.7L2653.4,3557.8L2620,3557.8L2620,3582.6L2653.4,3582.6L2653.4,3609.6L2620,3609.6L2620,3633.2L2653.4,3633.2L2653.4,3665.8L2576.1,3665.8L2576.1,3665.9ZM2635.4,3735.7C2623,3735.7 2613.3,3725.6 2613.3,3713.6C2613.3,3701.2 2623.1,3691.1 2635.4,3691.1C2647.7,3691.1 2657.5,3701.2 2657.5,3713.6C2657.6,3725.6 2647.8,3735.7 2635.4,3735.7ZM2775.4,3665.9L2731.5,3665.9L2731.5,3568C2731.5,3557.9 2712.7,3557.9 2712.7,3568L2712.7,3665.9L2669.2,3665.9L2669.2,3526.7L2712.7,3526.7C2709.3,3541 2717.2,3541.3 2721,3537.9C2730.4,3528.5 2740.5,3524.8 2749.1,3524.8C2764.1,3525.2 2775.4,3536.1 2775.4,3547.3L2775.4,3665.9Z"
                            style={{ fill: "white", fillRule: "nonzero" }}
                        />
                    </g>
                </g>
            </svg>
        );
    }
}
