import React from "react";

export default class Laneway extends React.Component {
    render() {
        return (
            <svg
                id="l17"
                width="100%"
                height="100%"
                viewBox="0 0 890 258"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                space="preserve"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 1.41421,
                }}
            >
                <g transform="matrix(1,0,0,1,-15359.8,-20339.8)">
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1552.56,575.539)">
                            <path
                                d="M0,25.713L0,31.621L-26.219,31.621L-26.219,-5.908L-18.793,-5.908L-18.793,25.713L0,25.713Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1557.39,569.63)">
                            <path
                                d="M0,37.529L-7.708,37.529L5.514,0L13.785,0L26.501,37.529L18.793,37.529L16.261,29.539L2.532,29.539L0,37.529ZM14.629,24.082L9.396,7.258L4.22,24.082L14.629,24.082Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1590.2,607.159)">
                            <path
                                d="M0,-37.529L15.811,-11.365L15.811,-37.529L23.238,-37.529L23.238,0L15.53,0L-0.506,-26.67L-0.506,0L-7.933,0L-7.933,-37.529L0,-37.529Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1615.74,607.159)">
                            <path
                                d="M0,-37.529L27.626,-37.529L27.626,-31.621L7.427,-31.621L7.427,-22.731L25.095,-22.731L25.095,-16.823L7.427,-16.823L7.427,-5.908L28.02,-5.908L28.02,0L0,0L0,-37.529Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1647.98,607.159)">
                            <path
                                d="M0,-37.529L7.427,-8.665L13.334,-37.529L20.931,-37.529L26.726,-8.665L33.759,-37.529L41.074,-37.529L30.214,0L23.181,0L17.049,-28.808L10.803,0L3.601,0L-7.596,-37.529L0,-37.529Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1689.84,569.63)">
                            <path
                                d="M0,37.529L-7.708,37.529L5.514,0L13.785,0L26.501,37.529L18.792,37.529L16.26,29.539L2.532,29.539L0,37.529ZM14.629,24.082L9.396,7.258L4.22,24.082L14.629,24.082Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1715.84,607.159)">
                            <path
                                d="M0,-37.529L8.046,-21.493L16.092,-37.529L24.025,-37.529L11.76,-14.348L11.76,0L4.332,0L4.332,-14.348L-7.933,-37.529L0,-37.529Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1596.05,617.514)">
                            <path
                                d="M0,-9.466L0,-7.092L-2.736,-7.092L-2.736,5.549L-6.486,5.549L-6.486,-7.092L-8.391,-7.092L-8.391,-9.466L-6.486,-9.466L-6.486,-10.36C-6.486,-12.272 -5.656,-13.813 -4.303,-14.43C-3.381,-14.861 -2.398,-15.015 -0.43,-15.015L0,-15.015L0,-12.087C-2.121,-12.087 -2.736,-11.655 -2.736,-10.175L-2.736,-9.466L0,-9.466Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1598.73,614.675)">
                            <path
                                d="M0,1.758C0.277,4.379 1.568,5.797 3.689,5.797C5.164,5.797 6.24,5.149 6.916,3.885L10.789,3.885C9.898,6.907 7.193,8.787 3.75,8.787C-0.707,8.787 -3.843,5.519 -3.843,0.895C-3.843,-3.853 -0.922,-7.029 3.443,-7.029C8.023,-7.029 10.85,-3.73 10.943,1.758L0,1.758ZM7.07,-0.708C6.793,-2.898 5.564,-4.13 3.566,-4.13C1.66,-4.13 0.4,-2.898 0.092,-0.708L7.07,-0.708Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1619.28,618.592)">
                            <path
                                d="M0,-6.074C-0.369,-7.493 -1.322,-8.171 -2.982,-8.171C-4.58,-8.171 -5.686,-7.462 -5.686,-6.444C-5.686,-5.982 -5.379,-5.581 -4.887,-5.365C-4.334,-5.119 -3.689,-4.995 -1.568,-4.595C0.584,-4.224 1.506,-3.916 2.336,-3.269C3.228,-2.529 3.75,-1.388 3.75,-0.032C3.75,2.928 1.199,4.871 -2.613,4.871C-6.639,4.871 -9.098,2.99 -9.437,-0.339L-5.994,-0.339C-5.687,1.325 -4.641,2.096 -2.644,2.096C-0.922,2.096 0.215,1.356 0.215,0.215C0.215,-0.71 -0.338,-1.049 -2.336,-1.388C-7.193,-2.22 -9.098,-3.546 -9.098,-6.105C-9.098,-8.942 -6.609,-10.945 -3.105,-10.945C0.553,-10.945 2.982,-9.096 3.289,-6.074L0,-6.074Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1630.81,618.068)">
                            <path
                                d="M0,-10.021L0,-7.647L-2.828,-7.647L-2.828,-0.032C-2.828,0.954 -2.797,1.201 -2.674,1.509C-2.459,2.003 -1.906,2.28 -1.168,2.28L-0.092,2.25L0.216,2.219L0.216,4.994L-2.459,4.994C-3.903,4.994 -4.18,4.963 -4.733,4.716C-6.209,4.038 -6.578,3.113 -6.578,-0.032L-6.578,-7.647L-8.668,-7.647L-8.668,-10.021L-6.578,-10.021L-6.578,-15.015L-2.828,-15.015L-2.828,-10.021L0,-10.021Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,0,145.467)">
                            <path
                                d="M1635.02,460.36L1631.27,460.36L1631.27,456.938L1635.02,456.938L1635.02,460.36ZM1635.02,477.595L1631.27,477.595L1631.27,462.58L1635.02,462.58L1635.02,477.595Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1639.27,623.063)">
                            <path
                                d="M0,-15.015L3.166,-4.409L6.209,-15.015L9.99,-15.015L5.041,0L1.26,0L-3.965,-15.015L0,-15.015Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1648.61,618.592)">
                            <path
                                d="M0,-6.074C0.184,-7.523 0.461,-8.263 1.076,-9.003C2.121,-10.267 4.027,-10.945 6.486,-10.945C9.652,-10.945 11.712,-9.99 12.542,-8.109C12.849,-7.369 13.002,-6.413 13.002,-4.995L13.002,1.294C13.002,3.083 13.064,3.545 13.494,4.47L9.867,4.47L9.437,3.083C7.838,4.316 6.363,4.871 4.641,4.871C1.599,4.871 -0.891,2.805 -0.891,0.277C-0.891,-1.419 0.154,-2.837 2.029,-3.731C3.105,-4.224 4.672,-4.594 6.855,-4.841C8.576,-5.026 9.191,-5.427 9.191,-6.352C9.191,-7.493 8.085,-8.294 6.455,-8.294C4.703,-8.294 3.627,-7.493 3.504,-6.074L0,-6.074ZM9.345,-3.423C8.638,-2.96 8.3,-2.837 6.978,-2.529C5.256,-2.097 4.55,-1.881 4.027,-1.542C3.289,-1.111 2.951,-0.586 2.951,0.092C2.951,1.14 3.997,1.88 5.564,1.88C6.67,1.88 7.684,1.572 8.422,0.986C9.099,0.462 9.345,-0.154 9.345,-1.357L9.345,-3.423Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,0,145.56)">
                            <rect
                                x="1662.43"
                                y="456.938"
                                width="3.75"
                                height="20.564"
                                style={{ fill: "white" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1670.22,607.616)">
                            <path
                                d="M0,-2.837C0,-0.864 -0.246,0 -1.107,0.894C-1.752,1.603 -2.521,2.004 -3.596,2.281L-3.596,1.325C-2.336,0.956 -1.721,0.123 -1.721,-1.203C-1.721,-1.326 -1.721,-1.573 -1.752,-1.788L-3.596,-1.788L-3.596,-5.118L0,-5.118L0,-2.837Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1610.54,565.942)">
                            <path
                                d="M0,-2.56C-0.108,-3.18 -0.494,-3.449 -1.248,-3.449C-1.922,-3.449 -2.353,-3.171 -2.353,-2.722C-2.353,-2.461 -2.209,-2.237 -1.967,-2.129C-1.769,-2.039 -1.724,-2.03 -0.727,-1.788C0.081,-1.599 0.458,-1.446 0.755,-1.186C1.069,-0.908 1.257,-0.477 1.257,-0.028C1.257,1.068 0.26,1.832 -1.186,1.832C-2.676,1.832 -3.611,1.095 -3.682,-0.135L-2.542,-0.135C-2.506,0.556 -2.065,0.889 -1.212,0.889C-0.413,0.889 0.081,0.583 0.081,0.089C0.081,-0.108 -0.009,-0.288 -0.153,-0.387C-0.332,-0.521 -0.638,-0.62 -1.455,-0.827C-2.335,-1.052 -2.649,-1.168 -2.928,-1.357C-3.314,-1.626 -3.53,-2.075 -3.53,-2.596C-3.53,-3.674 -2.623,-4.392 -1.249,-4.392C-0.413,-4.392 0.242,-4.15 0.637,-3.692C0.871,-3.413 1.006,-3.099 1.104,-2.56L0,-2.56Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1615.42,561.667)">
                            <path
                                d="M0,5.99L-1.186,5.99L-1.186,0.943L-3.08,0.943L-3.08,0L1.877,0L1.877,0.943L0,0.943L0,5.99Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1623.83,567.774)">
                            <path
                                d="M0,-6.108L0,-1.995C0,-1.321 -0.072,-1.006 -0.296,-0.701C-0.62,-0.243 -1.186,0 -1.931,0C-2.497,0 -2.964,-0.136 -3.287,-0.405C-3.718,-0.764 -3.853,-1.168 -3.853,-2.075L-3.853,-2.3L-2.766,-2.3C-2.775,-1.851 -2.766,-1.671 -2.748,-1.555C-2.694,-1.195 -2.407,-0.989 -1.958,-0.989C-1.536,-0.989 -1.293,-1.15 -1.221,-1.483C-1.194,-1.599 -1.185,-1.77 -1.185,-2.049L-1.185,-6.108L0,-6.108Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1624.6,567.656)">
                            <path
                                d="M0,-5.99L4.41,-5.99L4.41,-5.047L1.186,-5.047L1.186,-3.628L4.006,-3.628L4.006,-2.685L1.186,-2.685L1.186,-0.943L4.473,-0.943L4.473,0L0,0L0,-5.99Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1630.8,561.667)">
                            <path
                                d="M0,5.99L-1.185,5.99L-1.185,0L1.275,0C2.03,0 2.281,0.027 2.604,0.161C3.179,0.404 3.538,0.97 3.538,1.634C3.538,2.137 3.314,2.595 2.937,2.829C2.721,2.973 2.533,3.044 2.11,3.161C2.694,3.215 3.026,3.413 3.206,3.817C3.305,4.113 3.314,4.176 3.368,5.038C3.413,5.622 3.431,5.729 3.556,5.99L2.434,5.99C2.326,5.81 2.299,5.685 2.281,5.245C2.236,3.934 1.94,3.565 0.934,3.565L0,3.565L0,5.99ZM1.24,2.685C1.976,2.685 2.389,2.371 2.389,1.796C2.389,1.41 2.183,1.113 1.841,1.006C1.715,0.97 1.491,0.943 1.23,0.943L0,0.943L0,2.685L1.24,2.685Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1640.4,564.657)">
                            <path
                                d="M0,0.009C0,1.823 -1.185,3.117 -2.856,3.117C-4.526,3.117 -5.72,1.823 -5.72,0.018C-5.72,-1.832 -4.544,-3.108 -2.829,-3.108C-1.185,-3.107 0,-1.796 0,0.009M-4.536,0.009C-4.536,1.276 -3.861,2.129 -2.865,2.129C-1.859,2.129 -1.185,1.276 -1.185,0.009C-1.185,-1.284 -1.85,-2.12 -2.883,-2.12C-3.861,-2.12 -4.536,-1.257 -4.536,0.009"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1643.4,561.667)">
                            <path
                                d="M0,5.99L-1.338,1.437L-1.338,5.99L-2.416,5.99L-2.416,0L-0.701,0L0.584,4.446L1.877,0L3.592,0L3.592,5.99L2.515,5.99L2.515,1.437L1.186,5.99L0,5.99Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1647.81,567.656)">
                            <path
                                d="M0,-5.99L4.41,-5.99L4.41,-5.047L1.186,-5.047L1.186,-3.628L4.006,-3.628L4.006,-2.685L1.186,-2.685L1.186,-0.943L4.473,-0.943L4.473,0L0,0L0,-5.99Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1658.09,565.942)">
                            <path
                                d="M0,-2.56C-0.108,-3.18 -0.494,-3.449 -1.248,-3.449C-1.922,-3.449 -2.353,-3.171 -2.353,-2.722C-2.353,-2.461 -2.209,-2.237 -1.966,-2.129C-1.769,-2.039 -1.724,-2.03 -0.727,-1.788C0.081,-1.599 0.458,-1.446 0.755,-1.186C1.069,-0.908 1.258,-0.477 1.258,-0.028C1.258,1.068 0.261,1.832 -1.185,1.832C-2.676,1.832 -3.61,1.095 -3.682,-0.135L-2.541,-0.135C-2.505,0.556 -2.066,0.889 -1.213,0.889C-0.413,0.889 0.081,0.583 0.081,0.089C0.081,-0.108 -0.009,-0.288 -0.152,-0.387C-0.332,-0.521 -0.638,-0.62 -1.455,-0.827C-2.335,-1.052 -2.649,-1.168 -2.928,-1.357C-3.314,-1.626 -3.53,-2.075 -3.53,-2.596C-3.53,-3.674 -2.622,-4.392 -1.248,-4.392C-0.413,-4.392 0.243,-4.15 0.638,-3.692C0.871,-3.413 1.005,-3.099 1.105,-2.56L0,-2.56Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,0,54.238)">
                            <rect
                                y="512.319"
                                width="1.168"
                                height="1.124"
                                style={{ fill: "white" }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
