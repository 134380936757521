import React from "react";

export default class Oztix extends React.Component {
    render() {
        return (
            <svg
                id="l24"
                width="100%"
                height="100%"
                viewBox="0 0 798 222"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                space="preserve"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 1.41421,
                }}
            >
                <g transform="matrix(1,0,0,1,-12902.1,-21516.3)">
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,936.505,874.165)">
                            <path
                                d="M0,-1.84C0.138,-2.005 0.12,-2.208 0.131,-2.402C0.41,-7.232 2.072,-11.551 5.041,-15.346C8.667,-19.982 13.413,-22.86 19.167,-24.066C21.448,-24.544 23.769,-24.647 26.079,-24.424C36.239,-23.444 44.082,-16.453 46.703,-7.588C47.341,-5.428 47.644,-3.227 47.658,-0.976C47.682,2.908 46.785,6.563 44.999,10.004C44.885,10.224 44.818,10.484 44.557,10.637C40.457,8.37 36.35,8.196 32.426,10.967C28.493,13.745 27.446,17.705 28.225,22.312C27.854,22.458 27.464,22.483 27.083,22.531C26.499,22.604 25.915,22.686 25.324,22.679C25.168,22.678 25.007,22.687 24.883,22.807L22.878,22.807C22.676,22.644 22.43,22.675 22.2,22.674C21.354,22.669 20.517,22.544 19.693,22.389C13.751,21.273 8.846,18.391 5.095,13.636C2.086,9.822 0.413,5.475 0.13,0.609C0.119,0.415 0.134,0.213 0,0.047L0,-1.84Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1085.33,854.809)">
                            <path
                                d="M0,-10.905C0.105,-10.802 0.241,-10.783 0.379,-10.766C2.688,-10.478 4.645,-8.521 4.952,-6.127C5.38,-2.785 2.654,-0.092 -0.297,-0.042C-2.819,0 -5.378,-1.703 -5.82,-4.607C-6.315,-7.865 -4.027,-10.291 -1.434,-10.75C-1.263,-10.78 -1.076,-10.755 -0.943,-10.905L0,-10.905Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1011.31,889.494)">
                            <path
                                d="M0,-33.558C3.447,-33.601 6.493,-33.109 9.377,-31.85C14.525,-29.601 17.772,-25.78 18.82,-20.199C19.438,-16.908 19.229,-13.664 18.047,-10.526C16.592,-6.662 13.887,-3.92 10.206,-2.121C8.054,-1.068 5.772,-0.454 3.389,-0.199C2.217,-0.073 1.045,0.043 -0.134,0.003C-4.014,-0.126 -7.734,-0.879 -11.081,-2.96C-14.98,-5.384 -17.365,-8.859 -18.181,-13.388C-18.815,-16.908 -18.554,-20.355 -17.148,-23.66C-15.442,-27.67 -12.416,-30.342 -8.432,-31.978C-6.516,-32.765 -4.517,-33.223 -2.454,-33.439C-1.532,-33.535 -0.611,-33.521 0,-33.558M7.663,-16.945C7.719,-17.491 7.615,-18.173 7.532,-18.853C7.482,-19.261 7.399,-19.666 7.299,-20.065C6.63,-22.731 5.048,-25.428 1.245,-25.873C-0.333,-26.057 -1.85,-25.829 -3.248,-25.039C-4.916,-24.096 -5.877,-22.597 -6.434,-20.821C-7.158,-18.509 -7.218,-16.157 -6.714,-13.791C-6.398,-12.31 -5.842,-10.931 -4.796,-9.81C-2.874,-7.748 -0.455,-7.335 2.173,-7.904C4.616,-8.433 6.096,-10.085 6.942,-12.358C7.474,-13.788 7.692,-15.276 7.663,-16.945"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1109.22,866.558)">
                            <path
                                d="M0,12.27C-0.228,12.398 -0.286,12.619 -0.385,12.805C-1.945,15.712 -3.51,18.616 -5.047,21.534C-5.287,21.991 -5.551,22.178 -6.086,22.174C-9.781,22.148 -13.476,22.161 -17.17,22.158C-17.363,22.158 -17.563,22.198 -17.756,22.103C-17.761,21.841 -17.564,21.68 -17.438,21.492C-14.098,16.519 -10.764,11.541 -7.394,6.587C-7.01,6.022 -7.035,5.649 -7.397,5.092C-10.517,0.28 -13.604,-4.554 -16.698,-9.384C-16.789,-9.527 -16.939,-9.649 -16.917,-9.852C-16.726,-9.93 -16.528,-9.893 -16.336,-9.893C-12.405,-9.895 -8.475,-9.886 -4.544,-9.906C-4.062,-9.908 -3.82,-9.755 -3.609,-9.323C-2.248,-6.536 -0.856,-3.764 0.529,-0.988C0.632,-0.782 0.707,-0.553 0.908,-0.372C1.127,-0.483 1.179,-0.702 1.273,-0.882C2.739,-3.682 4.211,-6.48 5.656,-9.291C5.88,-9.727 6.126,-9.911 6.639,-9.907C10.235,-9.883 13.831,-9.895 17.427,-9.893C17.618,-9.893 17.813,-9.921 18.048,-9.83C17.926,-9.624 17.823,-9.441 17.71,-9.264C14.599,-4.446 11.492,0.375 8.367,5.184C8.107,5.584 8.083,5.859 8.356,6.273C11.702,11.336 15.028,16.414 18.355,21.49C18.48,21.681 18.67,21.845 18.677,22.103C18.486,22.2 18.286,22.158 18.094,22.158C14.085,22.161 10.076,22.153 6.067,22.17C5.592,22.172 5.331,22.025 5.105,21.601C3.535,18.655 1.937,15.724 0.347,12.789C0.255,12.619 0.192,12.428 0,12.27"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1058.08,888.635)">
                            <path
                                d="M0,-31.886L0,-31.335C0,-28.78 -0.006,-26.226 0.007,-23.671C0.009,-23.289 -0.107,-23.014 -0.376,-22.741C-5.031,-18.003 -9.677,-13.256 -14.322,-8.509C-14.455,-8.373 -14.634,-8.265 -14.681,-8.017C-14.492,-7.846 -14.248,-7.917 -14.031,-7.917C-9.531,-7.912 -5.031,-7.914 -0.531,-7.912C-0.299,-7.912 -0.063,-7.939 0.204,-7.851L0.204,-0.038C-0.235,0.114 -26.851,0.144 -27.47,-0.003C-27.581,-0.18 -27.532,-0.382 -27.532,-0.573C-27.536,-3.108 -27.528,-5.643 -27.542,-8.178C-27.544,-8.539 -27.444,-8.8 -27.188,-9.061C-22.575,-13.757 -17.971,-18.462 -13.367,-23.166C-13.177,-23.36 -12.996,-23.563 -12.798,-23.775C-12.998,-24.031 -13.248,-23.96 -13.464,-23.961C-15.154,-23.968 -16.844,-23.965 -18.534,-23.965C-21.168,-23.965 -23.801,-23.964 -26.434,-23.966C-26.665,-23.967 -26.903,-23.93 -27.158,-24.05L-27.158,-31.866C-26.739,-31.996 -0.773,-32.03 0,-31.886"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1078.55,878.941)">
                            <path
                                d="M0,-22.272L0,-14.645L-8.074,-14.645C-8.291,-14.373 -8.232,-14.149 -8.232,-13.94C-8.235,-10.049 -8.232,-6.157 -8.232,-2.266C-8.232,-1.339 -8.127,-0.425 -7.88,0.469C-7.476,1.935 -6.531,3.076 -4.346,3.209C-3.045,3.288 -1.76,3.13 -0.481,2.908C-0.328,2.882 -0.178,2.819 0,2.94L0,9.931C-1.002,10.064 -1.967,10.221 -2.939,10.314C-4.463,10.459 -5.99,10.613 -7.523,10.555C-9.288,10.488 -11.049,10.352 -12.756,9.847C-15.054,9.168 -16.916,7.923 -18.023,5.723C-18.647,4.482 -18.973,3.152 -19.131,1.779C-19.335,0.001 -19.348,-1.787 -19.348,-3.574C-19.347,-11.907 -19.347,-20.24 -19.347,-28.574C-19.347,-28.731 -19.33,-28.89 -19.35,-29.045C-19.401,-29.435 -19.214,-29.597 -18.854,-29.699C-17.629,-30.05 -16.412,-30.429 -15.193,-30.8C-13.073,-31.445 -10.955,-32.095 -8.833,-32.734C-8.671,-32.783 -8.511,-32.885 -8.245,-32.823L-8.245,-22.289C-5.466,-22.261 -2.765,-22.289 0,-22.272"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(-1,-0,-0,1,1079.66,856.59)">
                            <path
                                d="M-11.14,32.088L0,32.088L0,0.157C-0.458,0.02 -10.524,0 -11.14,0.139L-11.14,32.088Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
