import React from "react";

export default class LiquorBarons extends React.Component {
    render() {
        return (
            <svg
                id="l18"
                width="100%"
                height="100%"
                viewBox="0 0 784 222"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                space="preserve"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 1.41421,
                }}
            >
                <g transform="matrix(1,0,0,1,-12166.7,-20965.5)">
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,898.438,720.75)">
                            <path
                                d="M0,13.421C0.557,13.138 1.129,12.913 1.707,12.738L-5.58,-1.567L-8.581,2.592L-2.264,14.988C-1.598,14.381 -0.842,13.851 0,13.421"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,909.376,764.8)">
                            <path
                                d="M0,-13.198C-0.482,-12.832 -1,-12.503 -1.558,-12.22C-2.398,-11.79 -3.273,-11.49 -4.157,-11.307L1.605,0L6.723,0L0,-13.198Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,910.871,711.731)">
                            <path
                                d="M0,24.801L-12.638,0L-16.239,4.994L-7.923,21.314C-4.936,21.264 -2.018,22.516 0,24.801"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(-0.970191,0.242343,0.242343,0.970191,926.15,763.209)">
                            <path
                                d="M7.199,-24.55C7.277,-21.503 8.709,-18.669 11.032,-16.788L7.199,-0.158L1.237,1.331L7.199,-24.55Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,894.303,733.827)">
                            <path
                                d="M0,4.18L-6.221,-8.027L-9.294,-3.764L-1.157,12.207C-1.896,9.448 -1.436,6.547 0,4.18"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(-0.999285,0.0378088,0.0378088,0.999285,900.918,761.044)">
                            <path
                                d="M-1.644,-7.314C1.105,-7.648 3.669,-9.079 5.383,-11.364L-1.644,3.821L-6.889,4.019L-1.644,-7.314Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,0,399.477)">
                            <rect
                                x="760"
                                y="328.026"
                                width="4.867"
                                height="24.471"
                                style={{ fill: "white" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,0,399.475)">
                            <path
                                d="M772.44,332.037L767.574,332.037L767.574,328.027L772.44,328.027L772.44,332.037ZM767.574,334.779L772.44,334.779L772.44,352.498L767.574,352.498L767.574,334.779Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,792.283,733.774)">
                            <path
                                d="M0,24.437L-4.867,24.437L-4.867,15.971L-4.935,15.971C-6.066,17.891 -8.396,18.679 -10.521,18.679C-13.777,18.679 -18.027,16.314 -18.027,9.459C-18.027,4.593 -15.456,0 -10.076,0C-7.882,0 -5.792,0.788 -4.695,2.742L-4.626,2.742L-4.626,0.48L0,0.48L0,24.437ZM-8.945,15.011C-5.792,15.011 -4.695,12.338 -4.695,9.425C-4.695,6.648 -5.792,3.666 -8.842,3.666C-11.995,3.666 -13.161,6.478 -13.161,9.357C-13.161,12.202 -12.132,15.011 -8.945,15.011"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,810.962,734.734)">
                            <path
                                d="M0,17.239L-4.627,17.239L-4.627,14.771L-4.73,14.771C-5.964,16.76 -8.089,17.719 -10.145,17.719C-15.32,17.719 -16.622,14.806 -16.622,10.419L-16.622,-0.48L-11.755,-0.48L-11.755,9.528C-11.755,12.441 -10.899,13.881 -8.637,13.881C-5.998,13.881 -4.867,12.407 -4.867,8.808L-4.867,-0.48L0,-0.48L0,17.239Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,832.38,751.493)">
                            <path
                                d="M0,-17.239L4.626,-17.239L4.626,-13.949L4.695,-13.949C5.586,-16.176 7.985,-17.719 10.35,-17.719C10.693,-17.719 11.104,-17.65 11.413,-17.547L11.413,-13.023C10.967,-13.126 10.247,-13.195 9.665,-13.195C6.101,-13.195 4.867,-10.624 4.867,-7.505L4.867,0.48L0,0.48L0,-17.239Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,844.786,752.454)">
                            <path
                                d="M0,-24.951L4.867,-24.951L4.867,-16.04L4.935,-16.04C6.134,-17.857 8.328,-18.679 10.521,-18.679C14.051,-18.679 17.855,-15.835 17.855,-9.357C17.855,-2.845 14.051,0 10.521,0C7.916,0 5.757,-0.788 4.695,-2.742L4.626,-2.742L4.626,-0.48L0,-0.48L0,-24.951ZM8.842,-15.013C5.963,-15.013 4.695,-12.305 4.695,-9.322C4.695,-6.375 5.963,-3.668 8.842,-3.668C11.72,-3.668 12.988,-6.375 12.988,-9.322C12.988,-12.305 11.72,-15.013 8.842,-15.013"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,863.807,746.525)">
                            <path
                                d="M0,-6.821C0.274,-11.38 4.353,-12.75 8.328,-12.75C11.858,-12.75 16.109,-11.962 16.109,-7.712L16.109,1.507C16.109,3.118 16.279,4.73 16.725,5.449L11.79,5.449C11.618,4.901 11.482,4.318 11.448,3.735C9.905,5.346 7.643,5.929 5.484,5.929C2.125,5.929 -0.548,4.25 -0.548,0.617C-0.548,-3.393 2.467,-4.353 5.484,-4.765C8.465,-5.21 11.241,-5.108 11.241,-7.095C11.241,-9.186 9.802,-9.494 8.088,-9.494C6.237,-9.494 5.038,-8.741 4.867,-6.821L0,-6.821ZM11.241,-3.222C10.419,-2.503 8.705,-2.468 7.197,-2.194C5.689,-1.886 4.318,-1.371 4.318,0.411C4.318,2.227 5.723,2.673 7.3,2.673C11.104,2.673 11.241,-0.343 11.241,-1.406L11.241,-3.222Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,881.972,751.493)">
                            <path
                                d="M0,-17.239L4.627,-17.239L4.627,-13.949L4.695,-13.949C5.586,-16.176 7.985,-17.719 10.35,-17.719C10.693,-17.719 11.104,-17.65 11.413,-17.547L11.413,-13.023C10.967,-13.126 10.247,-13.195 9.665,-13.195C6.1,-13.195 4.867,-10.624 4.867,-7.505L4.867,0.48L0,0.48L0,-17.239Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,913.846,751.493)">
                            <path
                                d="M0,-17.239L4.626,-17.239L4.626,-14.771L4.729,-14.771C5.963,-16.759 8.088,-17.719 10.145,-17.719C15.32,-17.719 16.622,-14.806 16.622,-10.419L16.622,0.48L11.755,0.48L11.755,-9.528C11.755,-12.441 10.898,-13.88 8.637,-13.88C5.998,-13.88 4.867,-12.407 4.867,-8.808L4.867,0.48L0,0.48L0,-17.239Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,936.226,740.012)">
                            <path
                                d="M0,6.203C0.035,8.328 1.817,9.185 3.736,9.185C5.141,9.185 6.923,8.637 6.923,6.923C6.923,5.449 4.901,4.935 1.405,4.181C-1.405,3.564 -4.215,2.57 -4.215,-0.549C-4.215,-5.073 -0.308,-6.238 3.496,-6.238C7.369,-6.238 10.933,-4.936 11.31,-0.583L6.683,-0.583C6.546,-2.468 5.107,-2.982 3.359,-2.982C2.262,-2.982 0.652,-2.776 0.652,-1.337C0.652,0.411 3.393,0.651 6.169,1.302C9.014,1.953 11.79,2.981 11.79,6.272C11.79,10.933 7.746,12.441 3.702,12.441C-0.411,12.441 -4.421,10.899 -4.626,6.203L0,6.203Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,821.682,752.812)">
                            <path
                                d="M0,-19.041C-5.258,-19.041 -9.521,-14.779 -9.521,-9.52C-9.521,-4.263 -5.258,0 0,0C5.258,0 9.52,-4.263 9.52,-9.52C9.52,-14.779 5.258,-19.041 0,-19.041M0,-4.445C-2.803,-4.445 -5.076,-6.717 -5.076,-9.52C-5.076,-12.324 -2.803,-14.596 0,-14.596C2.803,-14.596 5.076,-12.324 5.076,-9.52C5.076,-6.717 2.803,-4.445 0,-4.445"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,903.08,752.968)">
                            <path
                                d="M0,-19.041C-5.258,-19.041 -9.521,-14.779 -9.521,-9.52C-9.521,-4.262 -5.258,0 0,0C5.258,0 9.52,-4.262 9.52,-9.52C9.52,-14.779 5.258,-19.041 0,-19.041M0,-4.445C-2.803,-4.445 -5.076,-6.717 -5.076,-9.52C-5.076,-12.324 -2.803,-14.596 0,-14.596C2.803,-14.596 5.076,-12.324 5.076,-9.52C5.076,-6.717 2.803,-4.445 0,-4.445"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
