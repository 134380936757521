import React from "react";

export default class Chevron extends React.Component {
    render() {
        return (
            <svg
                id="l5"
                width="100%"
                height="100%"
                viewBox="0 0 308 369"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                space="preserve"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 1.41421,
                }}
            >
                <g transform="matrix(1,0,0,1,-14350.5,-19105.2)">
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1285.16,323.178)">
                            <path
                                d="M0,-38.342L0,-13.278L36.402,0L72.656,-13.278L72.656,-38.342L36.402,-25.064L0,-38.342Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1285.16,303.591)">
                            <path
                                d="M0,11.765L0,36.829L36.402,50.106L72.656,36.829L72.656,11.765L36.402,25.043L0,11.765ZM31.554,-34.611L34.873,-34.611L37.223,-25.362L39.609,-34.611L42.891,-34.611L39.498,-21.334L34.984,-21.334L31.554,-34.611ZM50.351,-34.611L44.048,-34.611L44.048,-21.333L47.442,-21.333L47.442,-29.241C47.442,-30.994 48.561,-31.031 50.351,-31.031L50.351,-34.611ZM67.806,-31.031C68.925,-31.031 69.298,-30.658 69.298,-29.39L69.298,-21.334L72.656,-21.334L72.656,-31.031C72.656,-32.448 71.909,-34.761 69.149,-34.611L62.845,-34.611L62.845,-21.333L66.203,-21.333L66.203,-31.031L67.806,-31.031ZM56.095,-34.611C50.625,-34.611 51.171,-31.292 51.171,-28.159C51.171,-24.653 50.724,-21.333 56.17,-21.333C61.615,-21.333 61.018,-24.653 61.018,-28.233C61.018,-31.739 61.69,-34.611 56.095,-34.611M56.095,-24.765C54.566,-24.765 54.491,-25.922 54.491,-28.085C54.491,-30.173 54.677,-31.218 56.095,-31.218C57.513,-31.218 57.627,-30.247 57.662,-28.159C57.698,-25.884 57.662,-24.765 56.095,-24.765M25.064,-26.667L31.048,-26.667C31.043,-27.074 31.031,-27.364 31.031,-27.748C31.031,-31.255 31.553,-34.611 26.406,-34.611C20.935,-34.611 21.482,-30.882 21.482,-27.748C21.482,-24.914 21.408,-21.333 26.854,-21.333L31.03,-21.333L31.03,-24.615L26.629,-24.615C25.81,-24.611 25.139,-25.399 25.064,-26.667M25.064,-30.285C25.064,-31.851 27.451,-31.777 27.451,-30.509L27.451,-29.576L25.064,-29.576L25.064,-30.285ZM10.033,-38.341L10.033,-21.334L13.389,-21.334L13.389,-29.614C13.464,-31.478 16.635,-31.478 16.635,-29.688L16.635,-21.333L19.991,-21.333L19.991,-30.695C19.991,-32.859 18.574,-34.425 16.038,-34.425C14.546,-34.425 13.613,-34.238 13.389,-33.94L13.389,-38.341L10.033,-38.341ZM8.429,-38.341L4.625,-38.341C-0.224,-38.341 -1.045,-34.313 -1.045,-29.763C-1.045,-25.213 -0.224,-21.334 3.879,-21.334L8.429,-21.334L8.429,-24.914L5.147,-24.914C2.76,-24.914 2.909,-28.196 2.909,-29.838C2.909,-31.479 2.461,-34.538 5.744,-34.611L8.429,-34.611L8.429,-38.341Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
