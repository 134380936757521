import React from "react";

export default class Denada extends React.Component {
    render() {
        return (
            <svg width="100%" height="100%" viewBox="0 0 816 185" style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinejoin: "round",
                strokeMiterlimit: 1.41421,
            }}>
            <g transform="matrix(1,0,0,1,-5854.95,-1738.15)">
                <g transform="matrix(1,0,0,1,6032.16,1871.79)">
                    <path d="M0,-25.306C4.698,-36.872 15.638,-44.98 28.386,-44.98C41.132,-44.98 52.065,-36.872 56.764,-25.306L0,-25.306ZM89.68,-0.062C90.508,-4.132 90.946,-8.344 90.946,-12.65C90.946,-16.99 90.508,-21.221 89.668,-25.306C83.819,-53.793 58.602,-75.216 28.386,-75.216C-1.838,-75.216 -27.049,-53.793 -32.899,-25.306C-33.738,-21.221 -34.183,-16.99 -34.183,-12.65C-34.183,-8.344 -33.744,-4.132 -32.916,-0.062C-27.09,28.458 -1.861,49.91 28.386,49.91C54.169,49.91 76.313,34.304 85.888,12.033L48.289,12.033C42.918,16.795 35.973,19.669 28.386,19.669C15.614,19.669 4.657,11.531 -0.022,-0.062L89.68,-0.062Z" style={{ fill: "white", fillRule: "nonzero" }}/>
                </g>
                <g transform="matrix(1,0,0,1,6195.83,1921.58)">
                    <path d="M0,-124.428C-11.606,-124.428 -23.69,-119.344 -32.628,-111.633L-32.628,-124.527L-66.283,-124.527L-66.283,0.099L-32.628,0.099L-32.628,-61.649C-32.628,-78.65 -20.676,-93.719 -4.23,-93.719C12.218,-93.719 24.167,-78.65 24.167,-61.649L24.167,0.099L57.816,0.099L57.816,-61.649C57.816,-96.461 31.803,-124.428 0,-124.428" style={{ fill: "white", fillRule: "nonzero" }}/>
                </g>
                <g transform="matrix(1,0,0,1,6483.82,1778.18)">
                    <path d="M0,104.23C-5.761,110.261 -13.713,113.55 -21.932,113.55C-30.155,113.55 -37.836,110.261 -43.585,104.23C-49.345,98.198 -52.634,89.974 -52.634,81.473C-52.634,72.984 -49.345,65.031 -43.585,58.729C-37.836,52.697 -30.155,49.408 -21.932,49.408C-13.713,49.408 -5.761,52.697 0,58.729C5.759,65.031 9.044,72.984 9.044,81.473C9.044,89.974 5.759,98.198 0,104.23M19.079,36.521C5.412,25.556 -8.554,18.711 -25.555,18.711C-60.091,18.711 -88.428,46.944 -88.428,81.473C-88.428,116.291 -59.895,144.253 -25.359,144.253C-8.358,144.253 5.412,137.403 19.079,126.431L19.079,143.503L17.954,143.503L50.631,143.503L50.631,-40.023L19.079,-40.023L19.079,36.521Z" style={{ fill: "white", fillRule: "nonzero" }}/>
                </g>
                <g transform="matrix(1,0,0,1,6627.11,1836.62)">
                    <path d="M0,45.512C-5.76,51.542 -13.437,54.832 -21.659,54.832C-29.885,54.832 -37.56,51.542 -43.585,45.512C-49.345,39.205 -52.634,31.257 -52.634,22.762C-52.634,14.262 -49.345,6.043 -43.585,0.006C-37.56,-6.019 -29.885,-9.315 -21.659,-9.315C-13.437,-9.315 -5.76,-6.019 0,0.006C6.025,6.043 9.05,14.262 9.05,22.762C9.05,31.257 6.025,39.205 0,45.512M43.007,-39.568L13.563,-39.568L13.563,-24.949C4.629,-34.264 -11.173,-39.735 -26.514,-39.735C-61.326,-39.735 -89.056,-12.056 -89.056,22.762C-89.056,57.297 -61.44,85.247 -26.629,85.247C-11.289,85.247 4.629,79.783 13.563,70.466L13.563,85.057L43.007,85.057L43.007,-39.568Z" style={{ fill: "white", fillRule: "nonzero" }}/>
                </g>
                <g transform="matrix(1,0,0,1,5943.6,1778.18)">
                    <path d="M0,104.23C-5.756,110.261 -13.706,113.55 -21.934,113.55C-30.151,113.55 -37.83,110.261 -43.584,104.23C-49.342,98.198 -52.634,89.974 -52.634,81.473C-52.634,72.984 -49.342,65.031 -43.584,58.729C-37.83,52.697 -30.151,49.408 -21.934,49.408C-13.706,49.408 -5.756,52.697 0,58.729C5.754,65.031 9.047,72.984 9.047,81.473C9.047,89.974 5.754,98.198 0,104.23M18.199,36.521C4.531,25.556 -8.988,18.711 -25.984,18.711C-60.523,18.711 -88.646,46.944 -88.646,81.473C-88.646,116.291 -60.439,144.253 -25.904,144.253C-8.907,144.253 4.531,137.403 18.199,126.431L18.199,143.503L17.952,143.503L49.754,143.503L49.754,-40.023L18.199,-40.023L18.199,36.521Z" style={{ fill: "white", fillRule: "nonzero" }}/>
                </g>
                <g transform="matrix(1,0,0,1,6346.21,1836.62)">
                    <path d="M0,45.512C-5.761,51.542 -13.437,54.832 -21.653,54.832C-29.885,54.832 -37.555,51.542 -43.585,45.512C-49.345,39.205 -52.635,31.257 -52.635,22.762C-52.635,14.262 -49.345,6.043 -43.585,0.006C-37.555,-6.019 -29.885,-9.315 -21.653,-9.315C-13.437,-9.315 -5.761,-6.019 0,0.006C6.03,6.043 9.044,14.262 9.044,22.762C9.044,31.257 6.03,39.205 0,45.512M43.63,-39.568L14.18,-39.568L14.18,-24.967C2.614,-34.27 -10.862,-39.735 -26.195,-39.735C-61.002,-39.735 -88.893,-12.056 -88.893,22.762C-88.893,57.297 -61.049,85.247 -26.23,85.247C-10.902,85.247 2.614,79.787 14.18,70.484L14.18,85.057L43.63,85.057L43.63,-39.568Z" style={{ fill: "white", fillRule: "nonzero" }}/>
                </g>
            </g>
        </svg>
        );
    }
}
