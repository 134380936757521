import React from "react";

export default class EMI extends React.Component {
    render() {
        return (
            <svg
                id="l10"
                width="100%"
                height="100%"
                viewBox="0 0 533 258"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                space="preserve"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinejoin: "round",
                    strokeMiterlimit: 1.41421,
                }}
            >
                <g transform="matrix(1,0,0,1,-14214.7,-19713.3)">
                    <g transform="matrix(4.16667,0,0,4.16667,9000,18000)">
                        <g transform="matrix(1,0,0,1,1251.53,473.102)">
                            <path
                                d="M0,-61.913L0,0L127.868,0L127.868,-61.913L0,-61.913ZM16.731,-47.849L42.758,-47.849L42.758,-40.251L28.209,-40.251L28.209,-34.674L42.758,-34.674L42.758,-26.995L28.209,-26.995L28.209,-21.661L42.758,-21.661L42.758,-14.064L16.731,-14.064L16.731,-47.849ZM74.28,-14.064L66.44,-14.064L60.216,-32.492L60.216,-14.064L48.658,-14.064L48.658,-47.849L64.984,-47.849L70.562,-31.765L76.138,-47.849L91.981,-47.849L91.981,-14.064L80.422,-14.064L80.422,-32.492L74.28,-14.064ZM111.218,-14.064L98.367,-14.064L98.367,-47.849L111.218,-47.849L111.218,-14.064Z"
                                style={{ fill: "white", fillRule: "nonzero" }}
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
